import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "rootMessagePacksCtAll": {
      "id": "root_message_packs.ct_all",
      "defaultMessage": "All Clients"
    },
    "rootMessagePacksCtBoost": {
      "id": "root_message_packs.ct_boost",
      "defaultMessage": "Boost Exclusive"
    },
    "rootMessagePacksClientTypePlaceholder": {
      "id": "root_message_packs.client_type_placeholder",
      "defaultMessage": "Select a Client Type"
    },
    "rootMessagePacksClientTypeTitle": {
      "id": "root_message_packs.client_type_title",
      "defaultMessage": "Pack Availability"
    },
    "rootMessagePacksNamePlaceholder": {
      "id": "root_message_packs.name_placeholder",
      "defaultMessage": "Name Your Message Pack"
    },
    "rootMessagePacksNameTitle": {
      "id": "root_message_packs.name_title",
      "defaultMessage": "Message Pack Name"
    },
    "rootMessagePacksNameClient": {
      "id": "root_message_packs.client",
      "defaultMessage": "Client"
    },
    "rootMessagePacksNameAllCountries": {
      "id": "root_message_packs.all_countries",
      "defaultMessage": "All Countries"
    },
    "rootMessagePacksNameCountries": {
      "id": "root_message_packs.countries",
      "defaultMessage": "Countries"
    },
    "rootMessagePacksMessagePackTypePlaceholder": {
      "id": "root_message_packs.message_pack_type_placeholder",
      "defaultMessage": "Select a Pack Type"
    },
    "rootMessagePacksMessagePackTypeTitle": {
      "id": "root_message_packs.message_pack_type_title",
      "defaultMessage": "Pack Type"
    },
    "rootMessagePacksFrequencyTypePlaceholder": {
      "id": "root_message_packs.frequency_type_placeholder",
      "defaultMessage": "Select a Frequency"
    },
    "rootMessagePacksFrequencyTypeTitle": {
      "id": "root_message_packs.frequency_type_title",
      "defaultMessage": "Frequency"
    },
    "rootMessagePacksDay": {
      "id": "root_message_packs.day",
      "defaultMessage": "Day"
    },
    "rootMessagePacksWeekday": {
      "id": "root_message_packs.weekday",
      "defaultMessage": "Weekday"
    },
    "rootMessagePacksMonth": {
      "id": "root_message_packs.month",
      "defaultMessage": "Month"
    },
    "rootMessagePacksChooseHoliday": {
      "id": "root_message_packs.choose_holiday",
      "defaultMessage": "Choose Holiday"
    },
    "rootMessagePacksHolidayTitle": {
      "id": "root_message_packs.holiday_title",
      "defaultMessage": "Holiday"
    },
    "rootMessagePacksCustomDate": {
      "id": "root_message_packs.custom_date",
      "defaultMessage": "Custom Date"
    },
    "rootMessagePacksCountriesTitle": {
      "id": "root_message_packs.countries_title",
      "defaultMessage": "Countries"
    },
    "rootMessagePacksCountriesPlaceholder": {
      "id": "root_message_packs.countries_placeholder",
      "defaultMessage": "All countries"
    },
    "rootMessagePacksCategoryTitle": {
      "id": "root_message_packs.category_title",
      "defaultMessage": "Category"
    },
    "rootMessagePacksManage": {
      "id": "root_message_packs.manage",
      "defaultMessage": "Manage Message Packs"
    },
    "rootMessagePacksActive": {
      "id": "root_message_packs.active",
      "defaultMessage": "Active"
    },
    "rootMessagePacksCreate": {
      "id": "root_message_packs.create",
      "defaultMessage": "Create New Pack"
    },
    "rootMessagePacksLastUpdated": {
      "id": "root_message_packs.last_updated",
      "defaultMessage": "Last Updated"
    },
    "rootMessagePacksConfirmDelete": {
      "id": "root_message_packs.confirm_delete",
      "defaultMessage": "Are you sure you want to delete this pack?"
    },
    "rootMessagePacksDrafts": {
      "id": "root_message_packs.drafts",
      "defaultMessage": "Drafts"
    },
    "rootMessagePacksEmpty": {
      "id": "root_message_packs.empty",
      "defaultMessage": "Get Started by creating a New Message Pack"
    },
    "rootMessagePacksReports": {
      "id": "root_message_packs.reports",
      "defaultMessage": "Reports"
    },
    "rootMessagePacksNewPack": {
      "id": "root_message_packs.new_pack",
      "defaultMessage": "New Pack"
    },
    "rootMessagePacksPackName": {
      "id": "root_message_packs.pack_name",
      "defaultMessage": "{packName} Pack"
    },
    "rootMessagePacksPackCount": {
      "id": "root_message_packs.pack_count",
      "defaultMessage": "{packCount} Communications"
    },
    "rootMessagePacksDestroy": {
      "id": "root_message_packs.destroy",
      "defaultMessage": "Destroy Message Pack"
    },
    "rootMessagePacksEdit": {
      "id": "root_message_packs.edit",
      "defaultMessage": "Edit Message Pack"
    },
    "rootMessagePacksCountriesAll": {
      "id": "root_message_packs.countries.all",
      "defaultMessage": "All"
    },
    "rootMessagePacksDraft": {
      "id": "root_message_packs.draft",
      "defaultMessage": "Draft"
    },
    "rootMessagePacksAddMessages": {
      "id": "root_message_packs.add_messages",
      "defaultMessage": "Add Messages"
    },
    "rootMessagePacksPackMessage": {
      "id": "root_message_packs.pack_message",
      "defaultMessage": "Pack Message"
    },
    "rootMessagePacksPackSocialPost": {
      "id": "root_message_packs.pack_social_post",
      "defaultMessage": "Pack Social Post"
    },
    "rootMessagePacksDaysAfter": {
      "id": "root_message_packs.days_after",
      "defaultMessage": "Days After"
    },
    "rootMessagePacksDaysBefore": {
      "id": "root_message_packs.days_before",
      "defaultMessage": "Days Before"
    },
    "rootMessagePacksTypesSocialPost": {
      "id": "root_message_packs.types.social_post",
      "defaultMessage": "Social Post"
    },
    "rootMessagePacksTypesEmail": {
      "id": "root_message_packs.types.email",
      "defaultMessage": "Email"
    },
    "rootMessagePacksUseForAi": {
      "id": "root_message_packs.use_for_ai",
      "defaultMessage": "Use for AI Content"
    },
    "rootMessagePacksUpdateSuccessful": {
      "id": "root_message_packs.update_successful",
      "defaultMessage": "Message pack updated successfully"
    },
    "rootMessagePacksUseForAiTooltip": {
      "id": "root_message_packs.use_for_ai.tooltip",
      "defaultMessage": "When this box is checked, AI will use the date and image from this pack to create content for a holiday for all customers. Additionally, it will ensure this message pack doesn't appear for users, and instead, will allow just AI to create a post for the holiday. (This helps prevent duplicate content in the campaigns calendar)"
    },
    "rootMessagePacksAddPrompt": {
      "id": "root_message_packs.add_prompt",
      "defaultMessage": "What would you like to add?"
    },
    "rootMessagePacksBoostExclusive": {
      "id": "root_message_packs.boost_exclusive",
      "defaultMessage": "Boost-Exclusive"
    },
  }
);
export default defaultMessages;
