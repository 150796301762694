
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "modelsAnnouncement::DayOfWeekIsSunday": {
      "id": "models.announcement/day_of_week.is_sunday",
      "defaultMessage": "Sun"
    },
    "modelsAnnouncement::DayOfWeekIsMonday": {
      "id": "models.announcement/day_of_week.is_monday",
      "defaultMessage": "Mon"
    },
    "modelsAnnouncement::DayOfWeekIsTuesday": {
      "id": "models.announcement/day_of_week.is_tuesday",
      "defaultMessage": "Tue"
    },
    "modelsAnnouncement::DayOfWeekIsWednesday": {
      "id": "models.announcement/day_of_week.is_wednesday",
      "defaultMessage": "Wed"
    },
    "modelsAnnouncement::DayOfWeekIsThursday": {
      "id": "models.announcement/day_of_week.is_thursday",
      "defaultMessage": "Thu"
    },
    "modelsAnnouncement::DayOfWeekIsFriday": {
      "id": "models.announcement/day_of_week.is_friday",
      "defaultMessage": "Fri"
    },
    "modelsAnnouncement::DayOfWeekIsSaturday": {
      "id": "models.announcement/day_of_week.is_saturday",
      "defaultMessage": "Sat"
    },
    "modelsAnnouncementContent": {
      "id": "models.announcement.content",
      "defaultMessage": "Content"
    },
    "modelsAnnouncementCountry": {
      "id": "models.announcement.country",
      "defaultMessage": "Country"
    },
    "modelsAnnouncementDisplayType": {
      "id": "models.announcement.display_type",
      "defaultMessage": "Display"
    },
    "modelsAnnouncementDisplayTypesDisplayInline": {
      "id": "models.announcement.display_types.display_inline",
      "defaultMessage": "Page Section"
    },
    "modelsAnnouncementDisplayTypesDisplayBanner": {
      "id": "models.announcement.display_types.display_banner",
      "defaultMessage": "Banner"
    },
    "modelsAnnouncementDisplayTypesDisplayModal": {
      "id": "models.announcement.display_types.display_modal",
      "defaultMessage": "Pop-up"
    },
    "modelsAnnouncementDisplayTypesDisplayAboveMenu": {
      "id": "models.announcement.display_types.display_above_menu",
      "defaultMessage": "Above Menu"
    },
    "modelsAnnouncementDisplayTypesDisplayPageSection": {
      "id": "models.announcement.display_types.display_page_section",
      "defaultMessage": "Page Section"
    },
    "modelsAnnouncementFeatured": {
      "id": "models.announcement.featured",
      "defaultMessage": "Enabled"
    },
    "modelsAnnouncementHeading": {
      "id": "models.announcement.heading",
      "defaultMessage": "Heading"
    },
    "modelsAnnouncementIsMessageCampaignEnabledSmartMessage": {
      "id": "models.announcement.is_message_campaign_enabled.smart_message",
      "defaultMessage": "Send Smart Message"
    },
    "modelsAnnouncementIsMessageCampaignEnabledAutomatedEmail": {
      "id": "models.announcement.is_message_campaign_enabled.automated_email",
      "defaultMessage": "Send Automated Email"
    },
    "modelsAnnouncementIsMessageCampaignEnabledTooltip": {
      "id": "models.announcement.is_message_campaign_enabled.tool_tip",
      "defaultMessage": "Enable to automatically send a message to followers on the announcement start date"
    },
    "modelsAnnouncementIsRecurring": {
      "id": "models.announcement.is_recurring",
      "defaultMessage": "Weekly Recurring Announcement"
    },
    "modelsAnnouncementPhoto": {
      "id": "models.announcement.photo",
      "defaultMessage": "Photo"
    },
    "modelsAnnouncementPhotoAlt": {
      "id": "models.announcement.photo_alt",
      "defaultMessage": "Photo Description"
    },
    "modelsAnnouncementEndat": {
      "id": "models.announcement.endAt",
      "defaultMessage": "End At"
    },
    "modelsAnnouncementStartat": {
      "id": "models.announcement.startAt",
      "defaultMessage": "Start At"
    },
    "modelsAnnouncementLinkUrl": {
      "id": "models.announcement.link_url",
      "defaultMessage": "Link URL"
    },
    "modelsAnnouncementLinkName": {
      "id": "models.announcement.link_name",
      "defaultMessage": "Link Display Text"
    },
    "modelsAnnouncementModalDisplayType": {
      "id": "models.announcement.modal_display_type",
      "defaultMessage": "Content Style"
    },
    "modelsAnnouncementModalDisplayTypesMdtSide": {
      "id": "models.announcement.modal_display_types.mdt_side",
      "defaultMessage": "Side by side"
    },
    "modelsAnnouncementModalDisplayTypesMdtStacked": {
      "id": "models.announcement.modal_display_types.mdt_stacked",
      "defaultMessage": "Stacked"
    },
    "modelsAnnouncement::LocationSelves": {
      "id": "models.announcement/location.selves",
      "defaultMessage": "Locations"
    },
    "modelsAnnouncementSelf": {
      "id": "models.announcement.self",
      "defaultMessage": "Announcement"
    },
    "modelsAlohaAccountSelf": {
      "id": "models.aloha_account.self",
      "defaultMessage": "Aloha Account"
    },
    "modelsBillingBankAccountNickName": {
      "id": "models.billing_bank_account.nick_name",
      "defaultMessage": "Nickname"
    },
    "modelsBillingBankAccountStatusNew": {
      "id": "models.billing_bank_account.status.new",
      "defaultMessage": "New"
    },
    "modelsBillingBankAccountStatusInvalid": {
      "id": "models.billing_bank_account.status.invalid",
      "defaultMessage": "Invalid"
    },
    "modelsBillingBankAccountStatusValid": {
      "id": "models.billing_bank_account.status.valid",
      "defaultMessage": "Valid"
    },
    "modelsBillingBankAccountStatusVerificationFailed": {
      "id": "models.billing_bank_account.status.verification_failed",
      "defaultMessage": "Verification Failed"
    },
    "modelsBillingBankAccountStatusPendingVerification": {
      "id": "models.billing_bank_account.status.pending_verification",
      "defaultMessage": "Pending Verification"
    },
    "modelsBillingBankAccountStatusVerified": {
      "id": "models.billing_bank_account.status.verified",
      "defaultMessage": "Verified"
    },
    "modelsBillingCreditCardExpMonth": {
      "id": "models.billing_credit_card.exp_month",
      "defaultMessage": "Expiration Month"
    },
    "modelsBillingCreditCardExpYear": {
      "id": "models.billing_credit_card.exp_year",
      "defaultMessage": "Expiration Year"
    },
    "modelsBillingCreditCardNickName": {
      "id": "models.billing_credit_card.nick_name",
      "defaultMessage": "Nickname"
    },
    "modelsBillingCustomerBillingCountriesCa": {
      "id": "models.billing_customer.billing_countries.CA",
      "defaultMessage": "Canada"
    },
    "modelsBillingCustomerBillingCountriesFr": {
      "id": "models.billing_customer.billing_countries.FR",
      "defaultMessage": "France"
    },
    "modelsBillingCustomerBillingCountriesGb": {
      "id": "models.billing_customer.billing_countries.GB",
      "defaultMessage": "United Kingdom"
    },
    "modelsBillingCustomerBillingCountriesUs": {
      "id": "models.billing_customer.billing_countries.US",
      "defaultMessage": "United States"
    },
    "modelsBillingCustomerSelf": {
      "id": "models.billing_customer.self",
      "defaultMessage": "Account"
    },
    "modelsBillingCustomerBillingCity": {
      "id": "models.billing_customer.billing_city",
      "defaultMessage": "City"
    },
    "modelsBillingCustomerBillingCountry": {
      "id": "models.billing_customer.billing_country",
      "defaultMessage": "Country"
    },
    "modelsBillingCustomerBillingEmail": {
      "id": "models.billing_customer.billing_email",
      "defaultMessage": "Billing Email"
    },
    "modelsBillingCustomerBillingPhone": {
      "id": "models.billing_customer.billing_phone",
      "defaultMessage": "Billing Phone"
    },
    "modelsBillingCustomerBillingPostalCode": {
      "id": "models.billing_customer.billing_postal_code",
      "defaultMessage": "Postal Code"
    },
    "modelsBillingCustomerBillingState": {
      "id": "models.billing_customer.billing_state",
      "defaultMessage": "State/Province"
    },
    "modelsBillingCustomerBillingStreetAddress": {
      "id": "models.billing_customer.billing_street_address",
      "defaultMessage": "Street Address"
    },
    "modelsBillingCustomerCompanyName": {
      "id": "models.billing_customer.company_name",
      "defaultMessage": "Company Name"
    },
    "modelsBillingCustomerVatNumber": {
      "id": "models.billing_customer.vat_number",
      "defaultMessage": "VAT Number"
    },
    "modelsBillingCouponSelf": {
      "id": "models.billing_coupon.self",
      "defaultMessage": "Coupon"
    },
    "modelsBillingSubscriptionSelf": {
      "id": "models.billing_subscription.self",
      "defaultMessage": "Subscription"
    },
    "modelsBillingSubscriptionBillingCouponId": {
      "id": "models.billing_subscription.billing_coupon_id",
      "defaultMessage": "Coupon"
    },
    "modelsBillingSubscriptionTrialPeriodDays": {
      "id": "models.billing_subscription.trial_period_days",
      "defaultMessage": "Free Trial (days)"
    },
    "modelsBillingSubscriptionItemBillingPlanId": {
      "id": "models.billing_subscription_item.billing_plan_id",
      "defaultMessage": "Product"
    },
    "modelsBillingSubscriptionItemQuantity": {
      "id": "models.billing_subscription_item.quantity",
      "defaultMessage": "Quantity"
    },
    "modelsRestaurant::BlogTitle": {
      "id": "models.restaurant/blog.title",
      "defaultMessage": "Header On Blog Home Page"
    },
    "modelsMessageGroupSelves": {
      "id": "models.message_group.selves",
      "defaultMessage": "Mass Messages"
    },
    "modelsMessageCampaignSelves": {
      "id": "models.message_campaign.selves",
      "defaultMessage": "Smart Messages"
    },
    "modelsMessageCampaignSendOnWeekdaySunday": {
      "id": "models.message_campaign.send_on_weekday.sunday",
      "defaultMessage": "Sunday"
    },
    "modelsMessageCampaignSendOnWeekdayMonday": {
      "id": "models.message_campaign.send_on_weekday.monday",
      "defaultMessage": "Monday"
    },
    "modelsMessageCampaignSendOnWeekdayTuesday": {
      "id": "models.message_campaign.send_on_weekday.tuesday",
      "defaultMessage": "Tuesday"
    },
    "modelsMessageCampaignSendOnWeekdayWednesday": {
      "id": "models.message_campaign.send_on_weekday.wednesday",
      "defaultMessage": "Wednesday"
    },
    "modelsMessageCampaignSendOnWeekdayThursday": {
      "id": "models.message_campaign.send_on_weekday.thursday",
      "defaultMessage": "Thursday"
    },
    "modelsMessageCampaignSendOnWeekdayFriday": {
      "id": "models.message_campaign.send_on_weekday.friday",
      "defaultMessage": "Friday"
    },
    "modelsMessageCampaignSendOnWeekdaySaturday": {
      "id": "models.message_campaign.send_on_weekday.saturday",
      "defaultMessage": "Saturday"
    },
    "modelsMicrosOrgSelf": {
      "id": "models.micros_org.self",
      "defaultMessage": "Micros Org"
    },
    "modelsMicrosOrgOrgShortName": {
      "id": "models.micros_org.org_short_name",
      "defaultMessage": "Organization Short Name"
    },
    "modelsMicrosOrgRestaurants": {
      "id": "models.micros_org.restaurants",
      "defaultMessage": "Popmenu Restaurants"
    },
    "modelsGooglePostSelves": {
      "id": "models.google_post.selves",
      "defaultMessage": "Google Posts"
    },
    "modelsSocialPostSelves": {
      "id": "models.social_post.selves",
      "defaultMessage": "Social Posts"
    },
    "modelsRestaurantPriceRangesPriceRangeOne": {
      "id": "models.restaurant.price_ranges.price_range_one",
      "defaultMessage": "$ (low)"
    },
    "modelsRestaurantPriceRangesPriceRangeTwo": {
      "id": "models.restaurant.price_ranges.price_range_two",
      "defaultMessage": "$$"
    },
    "modelsRestaurantPriceRangesPriceRangeThree": {
      "id": "models.restaurant.price_ranges.price_range_three",
      "defaultMessage": "$$$"
    },
    "modelsRestaurantPriceRangesPriceRangeFour": {
      "id": "models.restaurant.price_ranges.price_range_four",
      "defaultMessage": "$$$$ (high)"
    },
    "modelsRestaurant::LocationCity": {
      "id": "models.restaurant/location.city",
      "defaultMessage": "City"
    },
    "modelsRestaurant::LocationCountry": {
      "id": "models.restaurant/location.country",
      "defaultMessage": "Country"
    },
    "modelsRestaurant::LocationEmail": {
      "id": "models.restaurant/location.email",
      "defaultMessage": "Contact Email"
    },
    "modelsRestaurant::LocationMenuLandingPageId": {
      "id": "models.restaurant/location.menu_landing_page_id",
      "defaultMessage": "Main Menu Landing Page"
    },
    "modelsRestaurant::LocationOpeningRanges": {
      "id": "models.restaurant/location.opening_ranges",
      "defaultMessage": "Open Hours"
    },
    "modelsRestaurant::LocationOrderingLandingPageId": {
      "id": "models.restaurant/location.ordering_landing_page_id",
      "defaultMessage": "Online Ordering Landing Page"
    },
    "modelsRestaurant::LocationPhone": {
      "id": "models.restaurant/location.phone",
      "defaultMessage": "Location Phone"
    },
    "modelsRestaurant::LocationPostalCode": {
      "id": "models.restaurant/location.postal_code",
      "defaultMessage": "Postal Code"
    },
    "modelsRestaurant::LocationReservationsLandingPageId": {
      "id": "models.restaurant/location.reservations_landing_page_id",
      "defaultMessage": "Reservation Landing Page"
    },
    "modelsRestaurant::LocationState": {
      "id": "models.restaurant/location.state",
      "defaultMessage": "State/Province"
    },
    "modelsRestaurant::LocationStreetAddress": {
      "id": "models.restaurant/location.street_address",
      "defaultMessage": "Street Address"
    },
    "modelsRestaurant::LocationWebsiteUri": {
      "id": "models.restaurant/location.website_uri",
      "defaultMessage": "Website URI"
    },
    "modelsRestaurantAttiresAttireCasual": {
      "id": "models.restaurant.attires.attire_casual",
      "defaultMessage": "Casual"
    },
    "modelsRestaurantAttiresAttireDressy": {
      "id": "models.restaurant.attires.attire_dressy",
      "defaultMessage": "Dressy"
    },
    "modelsRestaurantAttiresAttireFormal": {
      "id": "models.restaurant.attires.attire_formal",
      "defaultMessage": "Formal"
    },
    "modelsRestaurantAttire": {
      "id": "models.restaurant.attire",
      "defaultMessage": "Attire"
    },
    "modelsRestaurantCreatedByUserFullName": {
      "id": "models.restaurant.created_by_user.full_name",
      "defaultMessage": "Created By"
    },
    "modelsRestaurantDescription": {
      "id": "models.restaurant.description",
      "defaultMessage": "Restaurant Description"
    },
    "modelsRestaurantLogo": {
      "id": "models.restaurant.logo",
      "defaultMessage": "Restaurant Logo"
    },
    "modelsRestaurantName": {
      "id": "models.restaurant.name",
      "defaultMessage": "Restaurant Name"
    },
    "modelsRestaurantPriceRange": {
      "id": "models.restaurant.price_range",
      "defaultMessage": "Price Range"
    },
    "modelsRestaurantYearEstablished": {
      "id": "models.restaurant.year_established",
      "defaultMessage": "Est. Year"
    },
    "modelsRestaurant::SelectedPaymentMethodPaymentTypesPtAmericanexpress": {
      "id": "models.restaurant/selected_payment_method.payment_types.pt_americanexpress",
      "defaultMessage": "American Express"
    },
    "modelsRestaurant::SelectedPaymentMethodPaymentTypesPtAndroidpay": {
      "id": "models.restaurant/selected_payment_method.payment_types.pt_androidpay",
      "defaultMessage": "Google Pay"
    },
    "modelsRestaurant::SelectedPaymentMethodPaymentTypesPtApplepay": {
      "id": "models.restaurant/selected_payment_method.payment_types.pt_applepay",
      "defaultMessage": "Apple Pay"
    },
    "modelsRestaurant::SelectedPaymentMethodPaymentTypesPtBacs": {
      "id": "models.restaurant/selected_payment_method.payment_types.pt_bacs",
      "defaultMessage": "BACS"
    },
    "modelsRestaurant::SelectedPaymentMethodPaymentTypesPtBitcoin": {
      "id": "models.restaurant/selected_payment_method.payment_types.pt_bitcoin",
      "defaultMessage": "Bitcoin"
    },
    "modelsRestaurant::SelectedPaymentMethodPaymentTypesPtCash": {
      "id": "models.restaurant/selected_payment_method.payment_types.pt_cash",
      "defaultMessage": "Cash"
    },
    "modelsRestaurant::SelectedPaymentMethodPaymentTypesPtCheck": {
      "id": "models.restaurant/selected_payment_method.payment_types.pt_check",
      "defaultMessage": "Check"
    },
    "modelsRestaurant::SelectedPaymentMethodPaymentTypesPtDebitnote": {
      "id": "models.restaurant/selected_payment_method.payment_types.pt_debitnote",
      "defaultMessage": "Direct Debit"
    },
    "modelsRestaurant::SelectedPaymentMethodPaymentTypesPtDinersclub": {
      "id": "models.restaurant/selected_payment_method.payment_types.pt_dinersclub",
      "defaultMessage": "Diners Club"
    },
    "modelsRestaurant::SelectedPaymentMethodPaymentTypesPtDiscover": {
      "id": "models.restaurant/selected_payment_method.payment_types.pt_discover",
      "defaultMessage": "Discover"
    },
    "modelsRestaurant::SelectedPaymentMethodPaymentTypesPtFinancing": {
      "id": "models.restaurant/selected_payment_method.payment_types.pt_financing",
      "defaultMessage": "Financing"
    },
    "modelsRestaurant::SelectedPaymentMethodPaymentTypesPtInvoice": {
      "id": "models.restaurant/selected_payment_method.payment_types.pt_invoice",
      "defaultMessage": "Invoice"
    },
    "modelsRestaurant::SelectedPaymentMethodPaymentTypesPtMastercard": {
      "id": "models.restaurant/selected_payment_method.payment_types.pt_mastercard",
      "defaultMessage": "MasterCard"
    },
    "modelsRestaurant::SelectedPaymentMethodPaymentTypesPtPaypal": {
      "id": "models.restaurant/selected_payment_method.payment_types.pt_paypal",
      "defaultMessage": "PayPal"
    },
    "modelsRestaurant::SelectedPaymentMethodPaymentTypesPtTravelerscheck": {
      "id": "models.restaurant/selected_payment_method.payment_types.pt_travelerscheck",
      "defaultMessage": "Traveler's Check"
    },
    "modelsRestaurant::SelectedPaymentMethodPaymentTypesPtVisa": {
      "id": "models.restaurant/selected_payment_method.payment_types.pt_visa",
      "defaultMessage": "Visa"
    },
    "modelsRestaurant::SelectedPaymentMethodSelves": {
      "id": "models.restaurant/selected_payment_method.selves",
      "defaultMessage": "Payment Options"
    },
    "modelsCuisineTypeSelves": {
      "id": "models.cuisine_type.selves",
      "defaultMessage": "Restaurant Categories"
    },
    "modelsCalendarEvent::DayOfWeekIsSunday": {
      "id": "models.calendar_event/day_of_week.is_sunday",
      "defaultMessage": "Sun"
    },
    "modelsCalendarEvent::DayOfWeekIsMonday": {
      "id": "models.calendar_event/day_of_week.is_monday",
      "defaultMessage": "Mon"
    },
    "modelsCalendarEvent::DayOfWeekIsTuesday": {
      "id": "models.calendar_event/day_of_week.is_tuesday",
      "defaultMessage": "Tue"
    },
    "modelsCalendarEvent::DayOfWeekIsWednesday": {
      "id": "models.calendar_event/day_of_week.is_wednesday",
      "defaultMessage": "Wed"
    },
    "modelsCalendarEvent::DayOfWeekIsThursday": {
      "id": "models.calendar_event/day_of_week.is_thursday",
      "defaultMessage": "Thu"
    },
    "modelsCalendarEvent::DayOfWeekIsFriday": {
      "id": "models.calendar_event/day_of_week.is_friday",
      "defaultMessage": "Fri"
    },
    "modelsCalendarEvent::DayOfWeekIsSaturday": {
      "id": "models.calendar_event/day_of_week.is_saturday",
      "defaultMessage": "Sat"
    },
    "modelsCalendarEvent::RecurringTypeWeekdays": {
      "id": "models.calendar_event/recurring_type.weekdays",
      "defaultMessage": "Weekdays"
    },
    "modelsCalendarEvent::RecurringTypeEveryday": {
      "id": "models.calendar_event/recurring_type.everyday",
      "defaultMessage": "Every Day"
    },
    "modelsCalendarEvent::RecurringTypeCustom": {
      "id": "models.calendar_event/recurring_type.custom",
      "defaultMessage": "Custom"
    },
    "modelsCalendarEvent::RecurringTypeWeekends": {
      "id": "models.calendar_event/recurring_type.weekends",
      "defaultMessage": "Weekends"
    },
    "modelsCalendarEventDescription": {
      "id": "models.calendar_event.description",
      "defaultMessage": "Description"
    },
    "modelsCalendarEventEndAt": {
      "id": "models.calendar_event.end_at",
      "defaultMessage": "End Day"
    },
    "modelsCalendarEventEndTime": {
      "id": "models.calendar_event.end_time",
      "defaultMessage": "End Time"
    },
    "modelsCalendarEventExternalLinkUrl": {
      "id": "models.calendar_event.external_link_url",
      "defaultMessage": "Link URL"
    },
    "modelsCalendarEventExternalLinkText": {
      "id": "models.calendar_event.external_link_text",
      "defaultMessage": "Link Display Text"
    },
    "modelsCalendarEventIsAllDay": {
      "id": "models.calendar_event.is_all_day",
      "defaultMessage": "All-day Event"
    },
    "modelsCalendarEventIsRecurring": {
      "id": "models.calendar_event.is_recurring",
      "defaultMessage": "Weekly Recurring Event"
    },
    "modelsCalendarEventIsSecret": {
      "id": "models.calendar_event.is_secret",
      "defaultMessage": "Hide as secret event"
    },
    "modelsCalendarEventIsSecretTooltip": {
      "id": "models.calendar_event.is_secret_tooltip",
      "defaultMessage": "Secret events appear blurred until the guest signs up as a follower"
    },
    "modelsCalendarEventName": {
      "id": "models.calendar_event.name",
      "defaultMessage": "Event Name"
    },
    "modelsCalendarEventPhoto": {
      "id": "models.calendar_event.photo",
      "defaultMessage": "Event Photo"
    },
    "modelsCalendarEventPhotoAlt": {
      "id": "models.calendar_event.photo_alt",
      "defaultMessage": "Photo Alt Text"
    },
    "modelsCalendarEventStartAt": {
      "id": "models.calendar_event.start_at",
      "defaultMessage": "Start Day"
    },
    "modelsCalendarEventStartTime": {
      "id": "models.calendar_event.start_time",
      "defaultMessage": "Start Time"
    },
    "modelsCalendarEventLocations": {
      "id": "models.calendar_event.locations",
      "defaultMessage": "Choose Location(s):"
    },
    "modelsCalendarEventPublishGmb": {
      "id": "models.calendar_event.publish_gmb",
      "defaultMessage": "Publish to Google Business Profile"
    },
    "modelsCalendarEventStatus": {
      "id": "models.calendar_event.status",
      "defaultMessage": "Status"
    },
    "modelsCalendarEventStatusTypesActiveType": {
      "id": "models.calendar_event.status_types.active_type",
      "defaultMessage": "Active"
    },
    "modelsCalendarEventStatusTypesPostponedType": {
      "id": "models.calendar_event.status_types.postponed_type",
      "defaultMessage": "Postponed"
    },
    "modelsCalendarEventStatusTypesCanceledType": {
      "id": "models.calendar_event.status_types.canceled_type",
      "defaultMessage": "Canceled"
    },
    "modelsEventTagSelf": {
      "id": "models.event_tag.self",
      "defaultMessage": "Event Tag"
    },
    "modelsCalendarEventSelf": {
      "id": "models.calendar_event.self",
      "defaultMessage": "Event"
    },
    "modelsCalendarEventSelves": {
      "id": "models.calendar_event.selves",
      "defaultMessage": "Events"
    },
    "modelsCalendarEventPopsCount": {
      "id": "models.calendar_event.pops_count",
      "defaultMessage": "Pops"
    },
    "modelsCalendarEventTimeline": {
      "id": "models.calendar_event.timeline",
      "defaultMessage": "Timeline"
    },
    "modelsCalendarEventCalendar": {
      "id": "models.calendar_event.calendar",
      "defaultMessage": "Calendar"
    },
    "modelsCalendarEventTitle": {
      "id": "models.calendar_event.title",
      "defaultMessage": "Calendar Event View"
    },
    "modelsCalendarEventTags": {
      "id": "models.calendar_event.tags",
      "defaultMessage": "Display Event Tags"
    },
    "modelsAddEventTag": {
      "id": "models.calendar_event.add_tag",
      "defaultMessage": "Add Event Tag"
    },
    "modelsCalendarEventTagsList": {
      "id": "models.calendar_event.tags_list",
      "defaultMessage": "Event Tags"
    },
    "modelsCalendarEventTagsListEmpty": {
      "id": "models.calendar_event.tags_list_empty",
      "defaultMessage": "Displaying all event tags."
    },
    "modelsCalendarEventTagsManageAddEvents": {
      "id": "models.calendar_event.manage_add_events",
      "defaultMessage": "Manage or Add Events"
    },
    "modelsCalendarEventNoEventsPresent": {
      "id": "models.calendar_event.no_events_present",
      "defaultMessage": "No events present"
    },
    "modelsEventTagColor": {
      "id": "models.event_tag.color",
      "defaultMessage": "Event Tag Color"
    },
    "modelsEventTagName": {
      "id": "models.event_tag.name",
      "defaultMessage": "Tag Name"
    },
    "modelsFollowerEmail": {
      "id": "models.follower.email",
      "defaultMessage": "Email"
    },
    "modelsFollowerFirstName": {
      "id": "models.follower.first_name",
      "defaultMessage": "First Name"
    },
    "modelsFollowerLastName": {
      "id": "models.follower.last_name",
      "defaultMessage": "Last Name"
    },
    "modelsFollowerPhone": {
      "id": "models.follower.phone",
      "defaultMessage": "Phone"
    },
    "modelsFollowerImportSelf": {
      "id": "models.follower_import.self",
      "defaultMessage": "Follower Import"
    },
    "modelsFollowerImportBirthdayField": {
      "id": "models.follower_import.birthday_field",
      "defaultMessage": "Birthday"
    },
    "modelsFollowerImportEmailField": {
      "id": "models.follower_import.email_field",
      "defaultMessage": "Email Address"
    },
    "modelsFollowerImportFavoriteLocationField": {
      "id": "models.follower_import.favorite_location_field",
      "defaultMessage": "Favorite Location"
    },
    "modelsFollowerImportFirstNameField": {
      "id": "models.follower_import.first_name_field",
      "defaultMessage": "First Name"
    },
    "modelsFollowerImportFullNameField": {
      "id": "models.follower_import.full_name_field",
      "defaultMessage": "Full Name"
    },
    "modelsFollowerImportImportFile": {
      "id": "models.follower_import.import_file",
      "defaultMessage": "Import File (CSV)"
    },
    "modelsFollowerImportLastNameField": {
      "id": "models.follower_import.last_name_field",
      "defaultMessage": "Last Name"
    },
    "modelsFollowerImportPhoneField": {
      "id": "models.follower_import.phone_field",
      "defaultMessage": "Phone Number"
    },
    "modelsFollowerImportTagField": {
      "id": "models.follower_import.tag_field",
      "defaultMessage": "Follower Tag"
    },
    "modelsFollowerSelf": {
      "id": "models.follower.self",
      "defaultMessage": "Follower"
    },
    "modelsFollowerTagName": {
      "id": "models.follower_tag.name",
      "defaultMessage": "Tag Name"
    },
    "modelsFollowerTagSelf": {
      "id": "models.follower_tag.self",
      "defaultMessage": "Follower Tag"
    },
    "modelsFollowerTagAdd": {
      "id": "models.follower_tag.add",
      "defaultMessage": "Add Follower Tag"
    },
    "modelsDishPriceTypesDecreaseBy": {
      "id": "models.dish.price_types.decrease_by",
      "defaultMessage": "Decrease By"
    },
    "modelsDishPriceTypesIncreaseBy": {
      "id": "models.dish.price_types.increase_by",
      "defaultMessage": "Increase By"
    },
    "modelsDishPriceTypesAmountPriceType": {
      "id": "models.dish.price_types.amount_price_type",
      "defaultMessage": "Amount"
    },
    "modelsDishPriceTypesCustomPriceType": {
      "id": "models.dish.price_types.custom_price_type",
      "defaultMessage": "Custom"
    },
    "modelsDishPriceTypesSizesPriceType": {
      "id": "models.dish.price_types.sizes_price_type",
      "defaultMessage": "Multiple"
    },
    "modelsDishDescription": {
      "id": "models.dish.description",
      "defaultMessage": "Description"
    },
    "modelsDishFeatured": {
      "id": "models.dish.featured",
      "defaultMessage": "Featured"
    },
    "modelsDishIsOrderingEnabled": {
      "id": "models.dish.is_ordering_enabled",
      "defaultMessage": "Available for Online Ordering"
    },
    "modelsDishIsOrderingEnabledTooltip": {
      "id": "models.dish.is_ordering_enabled_tooltip",
      "defaultMessage": "\"Available for Online Ordering\" setting only applies to Popmenu Ordering, and does not affect any third-party ordering integrations"
    },
    "modelsDishIsPoppable": {
      "id": "models.dish.is_poppable",
      "defaultMessage": "Poppable"
    },
    "modelsDishContainsAlcohol": {
      "id": "models.dish.contains_alcohol",
      "defaultMessage": "This is an Alcoholic Beverage"
    },
    "modelsDishIsTaxable": {
      "id": "models.dish.is_taxable",
      "defaultMessage": "Taxable"
    },
    "modelsDishName": {
      "id": "models.dish.name",
      "defaultMessage": "Name"
    },
    "modelsDishPrice": {
      "id": "models.dish.price",
      "defaultMessage": "Price"
    },
    "modelsDishPriceCustomText": {
      "id": "models.dish.price_custom_text",
      "defaultMessage": "Custom Price Text"
    },
    "modelsDishPriceType": {
      "id": "models.dish.price_type",
      "defaultMessage": "Price Options"
    },
    "modelsDishShowFeaturedPhoto": {
      "id": "models.dish.show_featured_photo",
      "defaultMessage": "Display Photo"
    },
    "modelsMenu::ItemPriceCustomText": {
      "id": "models.menu/item.price_custom_text",
      "defaultMessage": "Custom Price Text"
    },
    "modelsMenu::ItemDescription": {
      "id": "models.menu/item.description",
      "defaultMessage": "Description"
    },
    "modelsMenu::ItemPrice": {
      "id": "models.menu/item.price",
      "defaultMessage": "Price"
    },
    "modelsDish::ExtraSelf": {
      "id": "models.dish/extra.self",
      "defaultMessage": "Modifier"
    },
    "modelsDish::ExtraGroupSelf": {
      "id": "models.dish/extra_group.self",
      "defaultMessage": "Modifier Group"
    },
    "modelsDish::ExtraGroupMaxSelectionsCount": {
      "id": "models.dish/extra_group.max_selections_count",
      "defaultMessage": "Max Selections"
    },
    "modelsDish::ExtraGroupMinSelectionsCount": {
      "id": "models.dish/extra_group.min_selections_count",
      "defaultMessage": "Min Selections"
    },
    "modelsDish::ExtraGroupName": {
      "id": "models.dish/extra_group.name",
      "defaultMessage": "Group Name"
    },
    "modelsDish::ExtraName": {
      "id": "models.dish/extra.name",
      "defaultMessage": "Modifier Name"
    },
    "modelsDish::ExtraPrice": {
      "id": "models.dish/extra.price",
      "defaultMessage": "Price"
    },
    "modelsDish::SizeSelf": {
      "id": "models.dish/size.self",
      "defaultMessage": "Size"
    },
    "modelsDish::SizeName": {
      "id": "models.dish/size.name",
      "defaultMessage": "Size Name"
    },
    "modelsDish::SizePrice": {
      "id": "models.dish/size.price",
      "defaultMessage": "Price"
    },
    "modelsDishCustomTaxRateId": {
      "id": "models.dish.custom_tax_rate_id",
      "defaultMessage": "Tax Rate"
    },
    "modelsDishDishId": {
      "id": "models.dish.dish_id",
      "defaultMessage": "Item"
    },
    "modelsDishIsEnabled": {
      "id": "models.dish.is_enabled",
      "defaultMessage": "Enabled"
    },
    "modelsDishPopsCount": {
      "id": "models.dish.pops_count",
      "defaultMessage": "Pops"
    },
    "modelsDishReviewsCount": {
      "id": "models.dish.reviews_count",
      "defaultMessage": "Reviews"
    },
    "modelsDishShowDisclaimer": {
      "id": "models.dish.show_disclaimer",
      "defaultMessage": "Disclaimer Icon"
    },
    "modelsDishIsOnlineOrderingEnabledStatusEnabled": {
      "id": "models.dish.is_online_ordering_enabled_status.enabled",
      "defaultMessage": "Enabled"
    },
    "modelsDishIsOnlineOrderingEnabledStatusDisabled": {
      "id": "models.dish.is_online_ordering_enabled_status.disabled",
      "defaultMessage": "Disabled"
    },
    "modelsPhotoSelves": {
      "id": "models.photo.selves",
      "defaultMessage": "Photos"
    },
    "modelsPhotoFeatured": {
      "id": "models.photo.featured",
      "defaultMessage": "Main Photo"
    },
    "modelsShowSyncedPhoto": {
      "id": "models.photo.show_pos_synced_photo",
      "defaultMessage": "Show Synced Photo"
    },
    "modelsHideSyncedPhoto": {
      "id": "models.photo.hide_pos_synced_photo",
      "defaultMessage": "Hide Synced Photo"
    },
    "modelsRestaurant::LocationAllowReservations": {
      "id": "models.restaurant/location.allow_reservations",
      "defaultMessage": "Reservations"
    },
    "modelsThemeDefaultMenuLayoutsCard": {
      "id": "models.theme.default_menu_layouts.card",
      "defaultMessage": "Card"
    },
    "modelsThemeDefaultMenuLayoutsNextLayout": {
      "id": "models.theme.default_menu_layouts.next_layout",
      "defaultMessage": "Next"
    },
    "modelsThemeDefaultMenuLayoutsSimple": {
      "id": "models.theme.default_menu_layouts.simple",
      "defaultMessage": "Simple"
    },
    "modelsThemeDefaultMenuLayoutsSimpleTwo": {
      "id": "models.theme.default_menu_layouts.simple_two",
      "defaultMessage": "Simple 2.0"
    },
    "modelsThemeDefaultMenuLayoutsListLayout": {
      "id": "models.theme.default_menu_layouts.list_layout",
      "defaultMessage": "List"
    },
    "modelsMenu::SectionMenuColumnCount": {
      "id": "models.menu/section.menu_column_count",
      "defaultMessage": "Section Columns"
    },
    "modelsMenu::SectionMenuColumnCountDefault": {
      "id": "models.menu/section.menu_column_count_default",
      "defaultMessage": "Theme Default"
    },
    "modelsMenuMenuLayoutsDefault": {
      "id": "models.menu.menu_layouts.default",
      "defaultMessage": "Theme Default"
    },
    "modelsMenuMenuLayoutsCard": {
      "id": "models.menu.menu_layouts.card",
      "defaultMessage": "Card"
    },
    "modelsMenuMenuLayoutsNextLayout": {
      "id": "models.menu.menu_layouts.next_layout",
      "defaultMessage": "Next"
    },
    "modelsMenuMenuLayoutsSimple": {
      "id": "models.menu.menu_layouts.simple",
      "defaultMessage": "Simple"
    },
    "modelsMenuMenuLayoutsSimpleTwo": {
      "id": "models.menu.menu_layouts.simple_two",
      "defaultMessage": "Simple 2.0"
    },
    "modelsMenuMenuLayoutsListLayout": {
      "id": "models.menu.menu_layouts.list_layout",
      "defaultMessage": "List"
    },
    "modelsMenuBackgroundEffectsBgDefault": {
      "id": "models.menu.background_effects.bg_default",
      "defaultMessage": "Default"
    },
    "modelsMenuBackgroundEffectsBgCover": {
      "id": "models.menu.background_effects.bg_cover",
      "defaultMessage": "Cover"
    },
    "modelsMenuBackgroundEffectsBgRepeat": {
      "id": "models.menu.background_effects.bg_repeat",
      "defaultMessage": "Tile"
    },
    "modelsMenuBackgroundEffectsBgRepeatY": {
      "id": "models.menu.background_effects.bg_repeat_y",
      "defaultMessage": "Tile Vertically"
    },
    "modelsMenuBackgroundEffectsBgStatic": {
      "id": "models.menu.background_effects.bg_static",
      "defaultMessage": "Center"
    },
    "modelsMenuBackgroundColor": {
      "id": "models.menu.background_color",
      "defaultMessage": "Background Color"
    },
    "modelsMenuBackgroundEffect": {
      "id": "models.menu.background_effect",
      "defaultMessage": "Background Image Effect"
    },
    "modelsMenuBackgroundImage": {
      "id": "models.menu.background_image",
      "defaultMessage": "Background Image"
    },
    "modelsMenuDisclaimer": {
      "id": "models.menu.disclaimer",
      "defaultMessage": "Disclaimer"
    },
    "modelsMenuDisplayExtraGroups": {
      "id": "models.menu.display_extra_groups",
      "defaultMessage": "Display Modifier Groups"
    },
    "modelsMenuDisplayPdfType": {
      "id": "models.menu.display_pdf_type",
      "defaultMessage": "PDF Download"
    },
    "modelsMenuIsEnabled": {
      "id": "models.menu.is_enabled",
      "defaultMessage": "Enabled"
    },
    "modelsMenuItemBackgroundColor": {
      "id": "models.menu.item_background_color",
      "defaultMessage": "Item Background Color"
    },
    "modelsMenuMenuLayout": {
      "id": "models.menu.menu_layout",
      "defaultMessage": "Menu Layout"
    },
    "modelsMenuName": {
      "id": "models.menu.name",
      "defaultMessage": "Menu Title"
    },
    "modelsMenuDisplayName": {
      "id": "models.menu.display_name",
      "defaultMessage": "Menu Display Title"
    },
    "modelsMenuPdfLinkUrl": {
      "id": "models.menu.pdf_link_url",
      "defaultMessage": "URL"
    },
    "modelsMenuPhoto": {
      "id": "models.menu.photo",
      "defaultMessage": "Photo"
    },
    "modelsMenuPhotoSocialMediaTooltip": {
      "id": "models.menu.photo.social_media_tooltip",
      "defaultMessage": "Optional photo will appear when sharing the menu to social media, rather than the restaurant logo"
    },
    "modelsMenuPhotoMobileMenuTooltip": {
      "id": "models.menu.photo.mobile_menu_tooltip",
      "defaultMessage": "This menu image will only show for your pickup and delivery menus when your guest is ordering on mobile"
    },
    "modelsMenuSelectedPrintLayoutId": {
      "id": "models.menu.selected_print_layout_id",
      "defaultMessage": "Print Menu"
    },
    "modelsMenuDisplayPdfTypesPdfNone": {
      "id": "models.menu.display_pdf_types.pdf_none",
      "defaultMessage": "None"
    },
    "modelsMenuDisplayPdfTypesPdfPrintCenter": {
      "id": "models.menu.display_pdf_types.pdf_print_center",
      "defaultMessage": "Print Center"
    },
    "modelsMenuDisplayPdfTypesPdfLink": {
      "id": "models.menu.display_pdf_types.pdf_link",
      "defaultMessage": "URL"
    },
    "modelsMenuNoMenusPresent": {
      "id": "models.menu.no_menus_present",
      "defaultMessage": "No menus present"
    },
    "modelsMenu::FeaturedSectionChangeGeneratedBy": {
      "id": "models.menu/featured_section.change_generated_by",
      "defaultMessage": "Change Generated by",
    },
    "modelsMenu::FeaturedSectionChangeGeneratedByMessage": {
      "id": "models.menu/featured_section.change_generated_by_message",
      "defaultMessage": "Changing the generation method will change all items in this section upon saving. Are you sure?",
    },
    "modelsMenu::FeaturedSectionIsAccented": {
      "id": "models.menu/featured_section.is_accented",
      "defaultMessage": "Shadow Accent",
    },
    "modelsMenu::FeaturedSectionCustom": {
      "id": "models.menu/featured_section.custom",
      "defaultMessage": "Custom",
    },
    "modelsMenu::FeaturedSectionGeneratedBy": {
      "id": "models.menu/featured_section.generated_by",
      "defaultMessage": "Generated by",
    },
    "modelsMenu::FeaturedSectionLayout": {
      "id": "models.menu/featured_section.layout",
      "defaultMessage": "Layout",
    },
    "modelsMenu::FeaturedSectionCarousel": {
      "id": "models.menu/featured_section.carousel",
      "defaultMessage": "Carousel",
    },
    "modelsMenu::FeaturedSectionList": {
      "id": "models.menu/featured_section.list",
      "defaultMessage": "List",
    },
    "modelsMenu::FeaturedSectionOrderVolume": {
      "id": "models.menu/featured_section.order_volume",
      "defaultMessage": "Order Volume",
    },
    "modelsMenu::FeaturedSectionLikesReviews": {
      "id": "models.menu/featured_section.likes_reviews",
      "defaultMessage": "Number of Likes and Reviews",
    },
    "modelsMenu::ItemIsEnabled": {
      "id": "models.menu/item.is_enabled",
      "defaultMessage": "Enabled"
    },
    "modelsMenu::ItemShowFeaturedPhoto": {
      "id": "models.menu/item.show_featured_photo",
      "defaultMessage": "Display Photo"
    },
    "modelsMenu::SectionBackgroundColor": {
      "id": "models.menu/section.background_color",
      "defaultMessage": "Section Background Color"
    },
    "modelsMenu::SectionDescription": {
      "id": "models.menu/section.description",
      "defaultMessage": "Section Description"
    },
    "modelsMenu::SectionIsEnabled": {
      "id": "models.menu/section.is_enabled",
      "defaultMessage": "Enabled"
    },
    "modelsMenu::SectionIsHeaderEnabled": {
      "id": "models.menu/section.is_header_enabled",
      "defaultMessage": "Show section header"
    },
    "modelsMenu::SectionIsFullWidth": {
      "id": "models.menu/section.is_full_width",
      "defaultMessage": "Always expand to full width"
    },
    "modelsMenu::SectionIsSecret": {
      "id": "models.menu/section.is_secret",
      "defaultMessage": "Hide as secret section"
    },
    "modelsMenu::SectionMenuLayout": {
      "id": "models.menu/section.menu_layout",
      "defaultMessage": "Section Layout"
    },
    "modelsMenu::SectionName": {
      "id": "models.menu/section.name",
      "defaultMessage": "Section Title"
    },

    "modelsMenu::SubsectionSubsectionTypesDefault": {
      "id": "models.menu/subsection.subsection_types.default",
      "defaultMessage": "Items"
    },
    "modelsMenu::SubsectionSubsectionTypesCustom": {
      "id": "models.menu/subsection.subsection_types.custom",
      "defaultMessage": "Custom"
    },
    "modelsMenu::SubsectionSubsectionType": {
      "id": "models.menu/subsection.subsection_type",
      "defaultMessage": "Section Type"
    },
    "modelsMenu::SubsectionSubsectionName": {
      "id": "models.menu/subsection.subsection_name",
      "defaultMessage": "Subsection Name"
    },
    "modelsMenu::SubsectionDescription": {
      "id": "models.menu/subsection.description",
      "defaultMessage": "Subsection Description"
    },
    "modelsMenu::MenuCartHoursModalTitle": {
      "id": "models.menu/menu_hours.modal.title",
      "defaultMessage": "Set Menu Hours"
    },
    "modelsMenu::MenuCartHoursModalSnackbarUpdated": {
      "id": "models.menu/menu_hours.modal.snackbar_updated",
      "defaultMessage": "Updated menu hours!"
    },
    "modelsMenu::MenuCartHoursModalDescription": {
      "id": "models.menu/menu_hours.modal.description",
      "defaultMessage": "Menu Hours let you control what menus are available to your customers."
    },
    "modelsMenu::UsingMenuHours": {
      "id": "models.menu/menu_hours",
      "defaultMessage": "Using Menu Hours"
    },
    "modelsMenu::UsingPickupHours": {
      "id": "models.menu/menu_hours.pickup_fulfillment_type",
      "defaultMessage": "Using Pickup Hours"
    },
    "modelsMenu::UsingDeliveryHours": {
      "id": "models.menu/menu_hours.delivery_fulfillment_type",
      "defaultMessage": "Using Delivery Hours"
    },
    "modelsMenu::UsingDineInHours": {
      "id": "models.menu/menu_hours.dine_in_fulfillment_type",
      "defaultMessage": "Using Dine-In Hours"
    },
    "modelsGoogleReviewAnonymous": {
      "id": "models.google_review.anonymous",
      "defaultMessage": "Anonymous"
    },
    "modelsGoogleReviewSelves": {
      "id": "models.google_review.selves",
      "defaultMessage": "Google Reviews"
    },
    "modelsMessageCampaignSelf": {
      "id": "models.message_campaign.self",
      "defaultMessage": "Smart Message"
    },
    "modelsMessageCampaignCustomMessageContent": {
      "id": "models.message_campaign.custom_message_content",
      "defaultMessage": "Message Content"
    },
    "modelsMessageCampaignCustomMessageSubject": {
      "id": "models.message_campaign.custom_message_subject",
      "defaultMessage": "Message Subject"
    },
    "modelsMessageCampaignCustomMessageTeaser": {
      "id": "models.message_campaign.custom_message_teaser",
      "defaultMessage": "Message Teaser (optional)"
    },
    "modelsMessageCampaignIsEnabled": {
      "id": "models.message_campaign.is_enabled",
      "defaultMessage": "Enabled"
    },
    "modelsMessageCampaignOfferId": {
      "id": "models.message_campaign.offer_id",
      "defaultMessage": "Offer"
    },
    "modelsMessageCampaignOfferValidation": {
      "id": "models.message_campaign.offer_validation",
      "defaultMessage": "needs to be attached as loyalty reward"
    },
    "modelsMessageCampaignTextAlignmentsLeft": {
      "id": "models.message_campaign.text_alignments.left",
      "defaultMessage": "Left"
    },
    "modelsMessageCampaignTextAlignmentsCenter": {
      "id": "models.message_campaign.text_alignments.center",
      "defaultMessage": "Center"
    },
    "modelsMessageCampaignTriggerAmountSpent": {
      "id": "models.message_campaign.trigger_amount_spent",
      "defaultMessage": "Amount spent"
    },
    "modelsMessageCampaignTriggerNumberOfDays": {
      "id": "models.message_campaign.trigger_number_of_days",
      "defaultMessage": "Number of Days"
    },
    "modelsMessageCampaignTriggerNumberOfOrders": {
      "id": "models.message_campaign.trigger_number_of_orders",
      "defaultMessage": "Number of Orders"
    },
    "modelsMasterMessageCampaignTriggerTypesAnnouncementTrigger": {
      "id": "models.master_message_campaign.trigger_types.announcement_trigger",
      "defaultMessage": "Restaurant added announcement"
    },
    "modelsMasterMessageCampaignTriggerTypesAnnouncementTriggerTriggerDescription": {
      "id": "models.master_message_campaign.trigger_types.announcement_trigger.trigger_description",
      "defaultMessage": "Sent after restaurant added announcement"
    },
    "modelsMasterMessageCampaignTriggerTypesCalendarEventPopWannaTryTrigger": {
      "id": "models.master_message_campaign.trigger_types.calendar_event_pop_wanna_try_trigger",
      "defaultMessage": "User popped an event as Remind Me To Attend"
    },
    "modelsMasterMessageCampaignTriggerTypesCalendarEventPopWannaTryTriggerTriggerDescription": {
      "id": "models.master_message_campaign.trigger_types.calendar_event_pop_wanna_try_trigger.trigger_description",
      "defaultMessage": "Sent after user popped an event as Remind Me To Attend"
    },
    "modelsMasterMessageCampaignTriggerTypesFollowTrigger": {
      "id": "models.master_message_campaign.trigger_types.follow_trigger",
      "defaultMessage": "User followed restaurant"
    },
    "modelsMasterMessageCampaignTriggerTypesFollowTriggerTriggerDescription": {
      "id": "models.master_message_campaign.trigger_types.follow_trigger.trigger_description",
      "defaultMessage": "Sent after user followed restaurant"
    },
    "modelsMasterMessageCampaignTriggerTypesFollowImportTrigger": {
      "id": "models.master_message_campaign.trigger_types.follow_import_trigger",
      "defaultMessage": "User imported as follower"
    },
    "modelsMasterMessageCampaignTriggerTypesFollowImportTriggerTriggerDescription": {
      "id": "models.master_message_campaign.trigger_types.follow_import_trigger.trigger_description",
      "defaultMessage": "Sent after user imported as follower"
    },
    "modelsMasterMessageCampaignTriggerTypesNoReviewTrigger": {
      "id": "models.master_message_campaign.trigger_types.no_review_trigger",
      "defaultMessage": "User followed restaurant and did not submit any reviews"
    },
    "modelsMasterMessageCampaignTriggerTypesNoReviewTriggerTriggerDescription": {
      "id": "models.master_message_campaign.trigger_types.no_review_trigger.trigger_description",
      "defaultMessage": "Sent after user followed restaurant and did not submit any reviews"
    },
    "modelsMasterMessageCampaignTriggerTypesMenuItemCartAcceptedTrigger": {
      "id": "models.master_message_campaign.trigger_types.menu_item_cart_accepted_trigger",
      "defaultMessage": "User submitted and received an online order based on due date"
    },
    "modelsMasterMessageCampaignTriggerTypesMenuItemCartAcceptedTriggerTriggerDescription": {
      "id": "models.master_message_campaign.trigger_types.menu_item_cart_accepted_trigger.trigger_description",
      "defaultMessage": "Sent after user submitted and received an online order based on due date"
    },
    "modelsMasterMessageCampaignTriggerTypesNumberOfOrdersReachedTrigger": {
      "id": "models.master_message_campaign.trigger_types.number_of_orders_reached_trigger",
      "defaultMessage": "User submitted x number of orders"
    },
    "modelsMasterMessageCampaignTriggerTypesNumberOfOrdersReachedTriggerTriggerDescription": {
      "id": "models.master_message_campaign.trigger_types.number_of_orders_reached_trigger.trigger_description",
      "defaultMessage": "Sent after user submitted x number of orders"
    },
    "modelsMasterMessageCampaignTriggerTypesNumberOfDaysPassedWithoutOrderTrigger": {
      "id": "models.master_message_campaign.trigger_types.number_of_days_passed_without_order_trigger",
      "defaultMessage": "User have not placed an order within x number of days"
    },
    "modelsMasterMessageCampaignTriggerTypesNumberOfDaysPassedWithoutOrderTriggerTriggerDescription": {
      "id": "models.master_message_campaign.trigger_types.number_of_days_passed_without_order_trigger.trigger_description",
      "defaultMessage": "Sent after user have not placed an order within x number of days"
    },
    "modelsMasterMessageCampaignTriggerTypesAmountSpentReachedTrigger": {
      "id": "models.master_message_campaign.trigger_types.amount_spent_reached_trigger",
      "defaultMessage": "User placed $x amount in orders"
    },
    "modelsMasterMessageCampaignTriggerTypesAmountSpentReachedTriggerTriggerDescription": {
      "id": "models.master_message_campaign.trigger_types.amount_spent_reached_trigger.trigger_description",
      "defaultMessage": "Sent after user placed $x amount in orders"
    },
    "modelsMasterMessageCampaignTriggerTypesPopLovedItNoReviewTrigger": {
      "id": "models.master_message_campaign.trigger_types.pop_loved_it_no_review_trigger",
      "defaultMessage": "User popped an item as Loved It and did not submit a review"
    },
    "modelsMasterMessageCampaignTriggerTypesPopLovedItNoReviewTriggerTriggerDescription": {
      "id": "models.master_message_campaign.trigger_types.pop_loved_it_no_review_trigger.trigger_description",
      "defaultMessage": "Sent after user popped an item as Loved It and did not submit a review"
    },
    "modelsMasterMessageCampaignTriggerTypesPopWannaTryTrigger": {
      "id": "models.master_message_campaign.trigger_types.pop_wanna_try_trigger",
      "defaultMessage": "User popped an item as Remind Me To Try"
    },
    "modelsMasterMessageCampaignTriggerTypesPopWannaTryTriggerTriggerDescription": {
      "id": "models.master_message_campaign.trigger_types.pop_wanna_try_trigger.trigger_description",
      "defaultMessage": "Sent after user popped an item as Remind Me To Try"
    },
    "modelsMasterMessageCampaignTriggerTypesReturningDishTrigger": {
      "id": "models.master_message_campaign.trigger_types.returning_dish_trigger",
      "defaultMessage": "Popular item added back to menu"
    },
    "modelsMasterMessageCampaignTriggerTypesReturningDishTriggerTriggerDescription": {
      "id": "models.master_message_campaign.trigger_types.returning_dish_trigger.trigger_description",
      "defaultMessage": "Sent after popular item added back to menu"
    },
    "modelsMasterMessageCampaignTriggerTypesReviewCreatedTrigger": {
      "id": "models.master_message_campaign.trigger_types.review_created_trigger",
      "defaultMessage": "User submitted a review"
    },
    "modelsMasterMessageCampaignTriggerTypesReviewCreatedTriggerTriggerDescription": {
      "id": "models.master_message_campaign.trigger_types.review_created_trigger.trigger_description",
      "defaultMessage": "Sent after user submitted a review"
    },
    "modelsMasterMessageCampaignTriggerTypesUserBirthdayTrigger": {
      "id": "models.master_message_campaign.trigger_types.user_birthday_trigger",
      "defaultMessage": "User's birthday"
    },
    "modelsMasterMessageCampaignTriggerTypesUserBirthdayTriggerTriggerDescription": {
      "id": "models.master_message_campaign.trigger_types.user_birthday_trigger.trigger_description",
      "defaultMessage": "Sent a week before user's Birthday"
    },
    "modelsMasterMessageCampaignTriggerTypesFollowerAnniversaryTrigger": {
      "id": "models.master_message_campaign.trigger_types.follower_anniversary_trigger",
      "defaultMessage": "Follower reaches their follow date anniversary"
    },
    "modelsMasterMessageCampaignTriggerTypesWeddingAnniversaryTrigger": {
      "id": "models.master_message_campaign.trigger_types.wedding_anniversary_trigger",
      "defaultMessage": "Follower reaches their wedding date anniversary"
    },
    "modelsMasterMessageCampaignTriggerTypesReservationConfirmedTrigger": {
      "id": "models.master_message_campaign.trigger_types.reservation_confirmed_trigger",
      "defaultMessage": "Reservation is confirmed"
    },
    "modelsMasterMessageCampaignTriggerTypesReservationCompletedTrigger": {
      "id": "models.master_message_campaign.trigger_types.reservation_completed_trigger",
      "defaultMessage": "Reservation is completed"
    },
    "modelsMasterMessageCampaignTriggerTypesFollowerAnniversaryTriggerTriggerDescription": {
      "id": "models.master_message_campaign.trigger_types.follower_anniversary_trigger.trigger_description",
      "defaultMessage": "Sent a week before follower's follow anniversary"
    },
    "modelsMasterMessageCampaignTriggerTypesWeddingAnniversaryTriggerTriggerDescription": {
      "id": "models.master_message_campaign.trigger_types.wedding_anniversary_trigger.trigger_description",
      "defaultMessage": "Sent a week before follower's wedding anniversary"
    },
    "modelsMasterMessageCampaignTriggerTypesReservationConfirmedTriggerTriggerDescription": {
      "id": "models.master_message_campaign.trigger_types.reservation_confirmed_trigger.trigger_description",
      "defaultMessage": "Sent immediately after a reservation is confirmed"
    },
    "modelsMasterMessageCampaignTriggerTypesReservationCompletedTriggerTriggerDescription": {
      "id": "models.master_message_campaign.trigger_types.reservation_completed_trigger.trigger_description",
      "defaultMessage": "Sent 12 hours after the reservation is completed"
    },
    "modelsMasterMessageCampaignTriggerTypesOfferReminderTrigger": {
      "id": "models.master_message_campaign.trigger_types.offer_reminder_trigger",
      "defaultMessage": "Remind Users they have unused offers"
    },
    "modelsMasterMessageCampaignTriggerTypesOfferReminderTriggerTriggerDescription": {
      "id": "models.master_message_campaign.trigger_types.offer_reminder_trigger.trigger_description",
      "defaultMessage": "Sent after user have not used online offer within x number of days"
    },
    "modelsMasterMessageCampaignTriggerTypesOrderOnlinePromptTrigger": {
      "id": "models.master_message_campaign.trigger_types.order_online_prompt_trigger",
      "defaultMessage": "Remind Users to order online"
    },
    "modelsMasterMessageCampaignTriggerTypesOrderOnlinePromptTriggerTriggerDescription": {
      "id": "models.master_message_campaign.trigger_types.order_online_prompt_trigger.trigger_description",
      "defaultMessage": "Sent on the first [chosen weekday] of each month"
    },
    "modelsMessageImageSize": {
      "id": "models.message.image_size",
      "defaultMessage": "Image Size"
    },
    "modelsMessageImageSizesLarge": {
      "id": "models.message.image_sizes.large",
      "defaultMessage": "Large"
    },
    "modelsMessageImageSizesSmall": {
      "id": "models.message.image_sizes.small",
      "defaultMessage": "Small"
    },
    "modelsMessageDescription": {
      "id": "models.message.description",
      "defaultMessage": "Image Description"
    },
    "modelsMessageImage": {
      "id": "models.message.image",
      "defaultMessage": "Image"
    },
    "modelsMessageLinkUrl": {
      "id": "models.message.link_url",
      "defaultMessage": "Image Link"
    },
    "modelsMessageGroupCalendarEventId": {
      "id": "models.message_group.calendar_event_id",
      "defaultMessage": "Calendar Event"
    },
    "modelsMessageGroupTextAlignmentsLeft": {
      "id": "models.message_group.text_alignments.left",
      "defaultMessage": "Left"
    },
    "modelsMessageGroupTextAlignmentsCenter": {
      "id": "models.message_group.text_alignments.center",
      "defaultMessage": "Center"
    },
    "modelsMessageGroupMessageContent": {
      "id": "models.message_group.message_content",
      "defaultMessage": "Message Content"
    },
    "modelsMessageGroupMessageSubject": {
      "id": "models.message_group.message_subject",
      "defaultMessage": "Message Subject"
    },
    "modelsMessageGroupMessageTeaser": {
      "id": "models.message_group.message_teaser",
      "defaultMessage": "Message Teaser (Optional)"
    },
    "modelsMessageGroupOfferId": {
      "id": "models.message_group.offer_id",
      "defaultMessage": "Offer"
    },
    "modelsMessageGroupOrderingEventId": {
      "id": "models.message_group.ordering_event_id",
      "defaultMessage": "Ordering Event"
    },
    "modelsMessageGroupOrderingOfferId": {
      "id": "models.message_group.ordering_offer_id",
      "defaultMessage": "Online Offer"
    },
    "modelsMessageGroupTextAlignment": {
      "id": "models.message_group.text_alignment",
      "defaultMessage": "Text Alignment"
    },
    "modelsMessageGroupTheme": {
      "id": "models.message_group.theme",
      "defaultMessage": "Theme"
    },
    "modelsMessageGroupThemesLight": {
      "id": "models.message_group.themes.light",
      "defaultMessage": "Light"
    },
    "modelsMessageGroupThemesDark": {
      "id": "models.message_group.themes.dark",
      "defaultMessage": "Dark"
    },
    "modelsRestaurantMailerFooterMessage": {
      "id": "models.restaurant.mailer_footer_message",
      "defaultMessage": "Email Footer Message"
    },
    "modelsRestaurantIncludeLocationContent": {
      "id": "models.restaurant.include_location_content",
      "defaultMessage": "Include main location content"
    },
    "modelsRestaurantMailerBrandColor": {
      "id": "models.restaurant.mailer_brand_color",
      "defaultMessage": "Email Brand Color"
    },
    "modelsRestaurantMailerLinkColor": {
      "id": "models.restaurant.mailer_link_color",
      "defaultMessage": "Email Link Color"
    },
    "modelsRestaurantMailerLogo": {
      "id": "models.restaurant.mailer_logo",
      "defaultMessage": "Email Logo"
    },
    "modelsRestaurantMailerDarkThemeLogo": {
      "id": "models.restaurant.mailer_dark_theme_logo",
      "defaultMessage": "Dark Theme Email Logo"
    },
    "modelsRestaurantMailerFooterImage": {
      "id": "models.restaurant.mailer_footer_image",
      "defaultMessage": "Footer Background Image"
    },
    "modelsRestaurantReplyToEmail": {
      "id": "models.restaurant.reply_to_email",
      "defaultMessage": "Reply-to Email"
    },
    "modelsRestaurantReplyToEmailSmartMessageToolTip": {
      "id": "models.restaurant.reply_to_email.smart_message.tooltip",
      "defaultMessage": "Smart messages will appear to be from this email address, and it will receive all direct replies"
    },
    "modelsRestaurantReplyToEmailAutomatedEmailToolTip": {
      "id": "models.restaurant.reply_to_email.automated_email.tooltip",
      "defaultMessage": "Automated emails will appear to be from this email address, and it will receive all direct replies"
    },
    "modelsRestaurantAddOfferToSmartMessagePlaceholder": {
      "id": "models.restaurant.add_offer_to_smart_message.placeholder",
      "defaultMessage": "Select a smart message"
    },
    "modelsRestaurantAddOfferToSmartMessageTitle": {
      "id": "models.restaurant.add_offer_to_smart_message.title",
      "defaultMessage": "Enabled Smart Messages"
    },
    "modelsRestaurantAddOfferToAutomatedEmailPlaceholder": {
      "id": "models.restaurant.add_offer_to_automated_email.placeholder",
      "defaultMessage": "Select an automated email"
    },
    "modelsRestaurantAddOfferToAutomatedEmailTitle": {
      "id": "models.restaurant.add_offer_to_automated_email.title",
      "defaultMessage": "Enabled automated emails"
    },
    "modelsRestaurantShowAllLocations": {
      "id": "models.restaurant.show_all_locations",
      "defaultMessage": "Show all locations in the footer"
    },
    "modelsReview::ReplyContent": {
      "id": "models.review/reply.content",
      "defaultMessage": "Reply content"
    },
    "modelsReview::ReplyIsPublic": {
      "id": "models.review/reply.is_public",
      "defaultMessage": "Post publicly on your website"
    },
    "modelsReview::ReplyNew": {
      "id": "models.review/reply.new",
      "defaultMessage": "Send Reply"
    },
    "modelsMessageGroupLocations": {
      "id": "models.message_group.locations",
      "defaultMessage": "Location(s)"
    },
    "modelsMessageGroupFollowerTags": {
      "id": "models.message_group.follower_tags",
      "defaultMessage": "Follower Tag(s)"
    },
    "modelsOfferCaption": {
      "id": "models.offer.caption",
      "defaultMessage": "Offer Image Caption"
    },
    "modelsOfferContent": {
      "id": "models.offer.content",
      "defaultMessage": "Offer Message"
    },
    "modelsOfferDisclaimer": {
      "id": "models.offer.disclaimer",
      "defaultMessage": "Disclaimer"
    },
    "modelsOfferImage": {
      "id": "models.offer.image",
      "defaultMessage": "Offer Image"
    },
    "modelsOfferIsEnabled": {
      "id": "models.offer.is_enabled",
      "defaultMessage": "Active"
    },
    "modelsOfferIsOnetimeUseTrue": {
      "id": "models.offer.is_onetime_use.true",
      "defaultMessage": "Single Use"
    },
    "modelsOfferSingleUse": {
      "id": "models.offer.single_use",
      "defaultMessage": "Single"
    },
    "modelsOfferMultipleUse": {
      "id": "models.offer.multiple_use",
      "defaultMessage": "Multiple"
    },
    "modelsOfferName": {
      "id": "models.offer.name",
      "defaultMessage": "Offer Name"
    },
    "modelsOfferSelf": {
      "id": "models.offer.self",
      "defaultMessage": "Offer"
    },
    "modelsOfferRedeemedOfferCodes": {
      "id": "models.offer.redeemed_offer_codes",
      "defaultMessage": "Redeemed Codes"
    },
    "modelsOfferTitle": {
      "id": "models.offer.title",
      "defaultMessage": "On Premise Dine-In Offers"
    },
    "modelsOrderingOfferDeliveryMethodSelf": {
      "id": "models.ordering_offer.delivery_method.self",
      "defaultMessage": "Delivery Method"
    },
    "modelsOrderingOfferDeliveryMethodSmartMessageMethod": {
      "id": "models.ordering_offer.delivery_method.smart_message_method",
      "defaultMessage": "Smart Message"
    },
    "modelsOrderingOfferDeliveryMethodAutomatedEmailMethod": {
      "id": "models.ordering_offer.delivery_method.automated_email_method",
      "defaultMessage": "Automated Email"
    },
    "modelsOrderingOfferDeliveryMethodMassMessageMethod": {
      "id": "models.ordering_offer.delivery_method.mass_message_method",
      "defaultMessage": "Dynamic Message"
    },
    "modelsOrderingOfferTitle": {
      "id": "models.ordering_offer.title",
      "defaultMessage": "Online Offers"
    },
    "modelsOrderingOfferTooltip": {
      "id": "models.ordering_offer.tooltip",
      "defaultMessage": "Offer codes are either generic (same code for each follower) or unique (different code for each follower)"
    },
    "modelsOrderingOfferTotalOrderValueTooltip": {
      "id": "models.ordering_offer.total_order_value_tooltip",
      "defaultMessage": "Excludes any tip, taxes and fees"
    },
    "modelsOrderingOfferAttachedTo": {
      "id": "models.ordering_offer.attached_to",
      "defaultMessage": "Attached to:"
    },
    "modelsOrderingActivateButton": {
      "id": "models.ordering_offer.activate_button",
      "defaultMessage": "Activate Offer"
    },
    "modelsOfferCodeSelf": {
      "id": "models.offer_code.self",
      "defaultMessage": "Offer Code"
    },
    "modelsOfferIsOnetimeUseSelf": {
      "id": "models.offer.is_onetime_use.self",
      "defaultMessage": "Redemption Type"
    },
    "modelsOfferIsOnetimeUsePerOrder": {
      "id": "models.offer.is_onetime_use_per_order",
      "defaultMessage": "Usage Per Order"
    },
    "modelsOfferIsOnetimeUseFalse": {
      "id": "models.offer.is_onetime_use.false",
      "defaultMessage": "Multiple Use"
    },
    "modelsOfferOfferType": {
      "id": "models.offer.offer_type",
      "defaultMessage": "Offer Type"
    },
    "modelsOfferAmount": {
      "id": "models.offer.amount",
      "defaultMessage": "Amount"
    },
    "modelsOfferAppliedTo": {
      "id": "models.offer.applied_to",
      "defaultMessage": "Applied to Subtotal"
    },
    "modelsOfferAppliedToTooltip": {
      "id": "models.offer.applied_to_tooltip",
      "defaultMessage": "Offer will be applied to subtotal amount at checkout"
    },
    "modelsOfferCodeTooltip": {
      "id": "models.offer.code_tooltip",
      "defaultMessage": "A code is recommended for dynamic messages."
    },
    "modelsOfferCodeSubheading": {
      "id": "models.offer_code_subheading",
      "defaultMessage": "Offer Code"
    },
    "modelsOfferExpirationDate": {
      "id": "models.offer.expiration_date",
      "defaultMessage": "Offer Expiration Date (Optional)"
    },
    "modelsOfferMinimumOrderAmount": {
      "id": "models.offer.minimum_order_amount",
      "defaultMessage": "Minimum Order Amount"
    },
    "modelsOfferCodeButton": {
      "id": "models.offer_code_btn",
      "defaultMessage": "{action} Offer Code"
    },
    "modelsOfferCannotCombine": {
      "id": "models.offer.cannot_combine",
      "defaultMessage": "Cannot Combine With Other Offers"
    },
    "modelsOfferRedemptionLimit": {
      "id": "models.offer.redemption_limit",
      "defaultMessage": "Redemption Limit (Optional)"
    },
    "modelsOfferRedemptionLimitTooltip": {
      "id": "models.offer.redemption_limit_tooltip",
      "defaultMessage": "Limits how many times this offer can be used in total by all your guests"
    },
    "modelsOfferRedemptionLimitSentence": {
      "id": "models.offer.redemption_limit_sentence",
      "defaultMessage": "This offer can be redeemed"
    },
    "modelsOfferRedemptionLimitSentenceEnd": {
      "id": "models.offer.redemption_limit_sentence_end",
      "defaultMessage": "times"
    },
    "modelsOfferUsageLimitNote": {
      "id": "models.offer.usage_limit_note",
      "defaultMessage": "Each follower will receive a unique code that they will only be able to use once."
    },
    "modelsOfferCodeUnique": {
      "id": "models.offer_code.unique",
      "defaultMessage": "Unique"
    },
    "modelsOfferMultipleUseNote": {
      "id": "models.offer.multiple_use_note",
      "defaultMessage": "Anyone who receives this offer code will be able to use it multiple times."
    },
    "modelsOfferLimit": {
      "id": "models.offer.limit",
      "defaultMessage": "Limit"
    },
    "modelsOfferOfferTypesDollarOffOfferType": {
      "id": "models.offer.offer_types.dollar_off_offer_type",
      "defaultMessage": "Dollar Off"
    },
    "modelsOfferOfferTypesPercentageOffOfferType": {
      "id": "models.offer.offer_types.percentage_off_offer_type",
      "defaultMessage": "Percentage Off"
    },
    "modelsOfferOfferTypesFreeType": {
      "id": "models.offer.offer_types.free_offer_type",
      "defaultMessage": "Free"
    },
    "modelsOfferOfferTypesPartialOrderDollarOffOfferType": {
      "id": "models.offer.offer_types.partial_order_dollar_off_offer_type",
      "defaultMessage": "Dollar Off"
    },
    "modelsOfferOfferTypesPartialOrderPercentageOffOfferType": {
      "id": "models.offer.offer_types.partial_order_percentage_off_offer_type",
      "defaultMessage": "Percentage Off"
    },
    "modelsMenuItemCartCartTypesDefaultCartType": {
      "id": "models.menu_item_cart.cart_types.default_cart_type",
      "defaultMessage": "Online"
    },
    "modelsMenuItemCartCartTypesPickupCurbsideType": {
      "id": "models.menu_item_cart.cart_types.pickup_curbside_type",
      "defaultMessage": "Curbside"
    },
    "modelsMenuItemCartCartTypesCateringCartType": {
      "id": "models.menu_item_cart.cart_types.catering_cart_type",
      "defaultMessage": "Catering"
    },
    "modelsMenuItemCartCartTypesDineInCartType": {
      "id": "models.menu_item_cart.cart_types.dine_in_cart_type",
      "defaultMessage": "Contactless Dine-In"
    },
    "modelsMenuItemCartCurbsideLabel": {
      "id": "models.menu_item_cart.curbside.label",
      "defaultMessage": "Curbside"
    },
    "modelsMenuItemCartCurbsideReplyText": {
      "id": "models.menu_item_cart.curbside.reply_text",
      "defaultMessage": "Great! Your order is on its way out."
    },
    "modelsMenuItemCartInStoreLabel": {
      "id": "models.menu_item_cart.in_store.label",
      "defaultMessage": "In-Store/Walk-In"
    },
    "modelsMenuItemCartPaymentMethodsEverywarePaymentMethod": {
      "id": "models.menu_item_cart.payment_methods.everyware_payment_method",
      "defaultMessage": "Everyware Payment"
    },
    "modelsMenuItemCartPaymentMethodsHeartlandPaymentMethod": {
      "id": "models.menu_item_cart.payment_methods.heartland_payment_method",
      "defaultMessage": "Heartland Payment"
    },
    "modelsMenuItemCartPaymentMethodsAuthorizePaymentMethod": {
      "id": "models.menu_item_cart.payment_methods.authorize_payment_method",
      "defaultMessage": "Authorize.net Payment"
    },
    "modelsMenuItemCartPaymentMethodsPickupPaymentMethod": {
      "id": "models.menu_item_cart.payment_methods.pickup_payment_method",
      "defaultMessage": "Pay on pickup"
    },
    "modelsMenuItemCartPaymentMethodsExternalPaymentMethod": {
      "id": "models.menu_item_cart.payment_methods.external_payment_method",
      "defaultMessage": "Paid"
    },
    "modelsMenuItemCartPaymentMethodsStripePaymentMethod": {
      "id": "models.menu_item_cart.payment_methods.stripe_payment_method",
      "defaultMessage": "Stripe Payment"
    },
    "modelsMenuItemCartPaymentMethodsStripePaymentMethodAmexExpressCheckout": {
      "id": "models.menu_item_cart.payment_methods.stripe_payment_method_amex_express_checkout",
      "defaultMessage": "Amex Express Checkout | Stripe Payment"
    },
    "modelsMenuItemCartPaymentMethodsStripePaymentMethodApplePay": {
      "id": "models.menu_item_cart.payment_methods.stripe_payment_method_apple_pay",
      "defaultMessage": "Apple Pay | Stripe Payment"
    },
    "modelsMenuItemCartPaymentMethodsStripePaymentMethodGooglePay": {
      "id": "models.menu_item_cart.payment_methods.stripe_payment_method_google_pay",
      "defaultMessage": "Google Pay | Stripe Payment"
    },
    "modelsMenuItemCartPaymentMethodsStripePaymentMethodMasterpass": {
      "id": "models.menu_item_cart.payment_methods.stripe_payment_method_masterpass",
      "defaultMessage": "Masterpass | Stripe Payment"
    },
    "modelsMenuItemCartPaymentMethodsStripePaymentMethodSamsungPay": {
      "id": "models.menu_item_cart.payment_methods.stripe_payment_method_samsung_pay",
      "defaultMessage": "Samsung Pay | Stripe Payment"
    },
    "modelsMenuItemCartPaymentMethodsStripePaymentMethodVisaCheckout": {
      "id": "models.menu_item_cart.payment_methods.stripe_payment_method_visa_checkout",
      "defaultMessage": "Visa Checkout | Stripe Payment"
    },
    "modelsMenuItemCartPaymentMethodsSquarePaymentMethod": {
      "id": "models.menu_item_cart.payment_methods.square_payment_method",
      "defaultMessage": "Square Payment"
    },
    "modelsMenuItemCartPaymentMethodsSquarePaymentMethodApplePay": {
      "id": "models.menu_item_cart.payment_methods.square_payment_method_apple_pay",
      "defaultMessage": "Apple Pay | Square Payment"
    },
    "modelsMenuItemCartPaymentMethodsSquarePaymentMethodApplePayLabel": {
      "id": "models.menu_item_cart.payment_methods.square_payment_method_apple_pay_label",
      "defaultMessage": "Pay with Apple Pay"
    },
    "modelsMenuItemCartPaymentMethodsSquarePaymentMethodGooglePay": {
      "id": "models.menu_item_cart.payment_methods.square_payment_method_google_pay",
      "defaultMessage": "Google Pay | Square Payment"
    },
    "modelsMenuItemCartPaymentMethodsSquarePaymentMethodGooglePayLabel": {
      "id": "models.menu_item_cart.payment_methods.square_payment_method_google_pay_label",
      "defaultMessage": "Pay with Google Pay"
    },
    "modelsMenuItemCartPaymentMethodsToastPaymentMethod": {
      "id": "models.menu_item_cart.payment_methods.toast_payment_method",
      "defaultMessage": "Toast Payment"
    },
    "modelsMenuItemCartPaymentMethodsCloverPaymentMethod": {
      "id": "models.menu_item_cart.payment_methods.clover_payment_method",
      "defaultMessage": "Clover Payment"
    },
    "modelsMenuItemCartPaymentMethodsNcrPaymentMethod": {
      "id": "models.menu_item_cart.payment_methods.ncr_payment_method",
      "defaultMessage": "NCR Payment"
    },
    "modelsSquareLocationPayOnPickupNotAvailableTooltip": {
      "id": "models.square_location.pay_on_pickup_not_available_tooltip",
      "defaultMessage": "Pay on pickup not available for Square POS integration"
    },
    "modelsCloverLocationPayOnPickupNotAvailableTooltip": {
      "id": "models.clover_location.pay_on_pickup_not_available_tooltip",
      "defaultMessage": "Pay on pickup not available for Clover POS integration"
    },
    "modelsNcrSilverLocationPayOnPickupNotAvailableTooltip": {
      "id": "models.ncr_silver_location.pay_on_pickup_not_available_tooltip",
      "defaultMessage": "Pay on pickup not available for NCR Silver POS integration"
    },
    "modelsDeliverectLocationPayOnPickupNotAvailableTooltip": {
      "id": "models.deliverect_location.pay_on_pickup_not_available_tooltip",
      "defaultMessage": "Pay on pickup not available for Deliverect POS integration"
    },
    "modelsAlohaLocationPayOnPickupNotAvailableTooltip": {
      "id": "models.aloha_location.pay_on_pickup_not_available_tooltip",
      "defaultMessage": "Pay on pickup not available for Aloha POS integration"
    },
    "modelsNcrPaymentAccountCompanyId": {
      "id": "models.ncr_payment_account.company_id",
      "defaultMessage": "NCR Company ID"
    },
    "modelsNcrPaymentAccountRestaurants": {
      "id": "models.ncr_payment_account.restaurant",
      "defaultMessage": "Popmenu Restaurants"
    },
    "modelsNcrPaymentAccountSelf": {
      "id": "models.ncr_payment_account.self",
      "defaultMessage": "NCR Payment Account"
    },
    "modelsNcrPaymentAccountNcrPaymentKey": {
      "id": "models.ncr_payment_account.ncr_payment_key",
      "defaultMessage": "NCR Payment Key"
    },
    "modelsAlohaAccountCompanyId": {
      "id": "models.aloha_account.organization_id",
      "defaultMessage": "Organization ID"
    },
    "modelsAlohaAccountRestaurants": {
      "id": "models.aloha_account.restaurants",
      "defaultMessage": "Popmenu Restaurants"
    },
    "modelsAlohaAccountConnected": {
      "id": "models.aloha_account.connected",
      "defaultMessage": "Connected"
    },
    "modelsMenuItemCartStatusesPendingStatus": {
      "id": "models.menu_item_cart.statuses.pending_status",
      "defaultMessage": "Unsubmitted"
    },
    "modelsMenuItemCartStatusesRefundedStatus": {
      "id": "models.menu_item_cart.statuses.refunded_status",
      "defaultMessage": "Refunded"
    },
    "modelsMenuItemCartStatusesSubmittedStatus": {
      "id": "models.menu_item_cart.statuses.submitted_status",
      "defaultMessage": "New"
    },
    "modelsMenuItemCartStatusesAcceptedStatus": {
      "id": "models.menu_item_cart.statuses.accepted_status",
      "defaultMessage": "Accepted"
    },
    "modelsMenuItemCartStatusesReminderStatus": {
      "id": "models.menu_item_cart.statuses.reminder_status",
      "defaultMessage": "Reminder"
    },
    "modelsMenuItemCartStatusesPreparingStatus": {
      "id": "models.menu_item_cart.statuses.preparing_status",
      "defaultMessage": "Working"
    },
    "modelsMenuItemCartStatusesReadyStatus": {
      "id": "models.menu_item_cart.statuses.ready_status",
      "defaultMessage": "Ready"
    },
    "modelsMenuItemCartStatusesDeliveringStatus": {
      "id": "models.menu_item_cart.statuses.delivering_status",
      "defaultMessage": "Delivering"
    },
    "modelsMenuItemCartStatusesCompletedStatus": {
      "id": "models.menu_item_cart.statuses.completed_status",
      "defaultMessage": "Completed"
    },
    "modelsMenuItemCartStatusesCancelledStatus": {
      "id": "models.menu_item_cart.statuses.cancelled_status",
      "defaultMessage": "Cancelled"
    },
    "modelsMenuItemCartStatusesRejectedStatus": {
      "id": "models.menu_item_cart.statuses.rejected_status",
      "defaultMessage": "Rejected"
    },
    "modelsMenuItemCartStatusesFutureStatus": {
      "id": "models.menu_item_cart.statuses.future_status",
      "defaultMessage": "Future"
    },
    "modelsMenuItemCartStatusesLateStatus": {
      "id": "models.menu_item_cart.statuses.late_status",
      "defaultMessage": "Late"
    },
    "modelsMenuItemCartSubcartSubmittedStatus": {
      "id": "models.menu_item_cart_subcart.statuses.submitted_status",
      "defaultMessage": "New"
    },
    "modelsMenuItemCartSubcartAcceptedStatus": {
      "id": "models.menu_item_cart_subcart.statuses.accepted_status",
      "defaultMessage": "Accepted"
    },
    "modelsMenuItemCartSubcartPreparingStatus": {
      "id": "models.menu_item_cart_subcart.statuses.preparing_status",
      "defaultMessage": "Working"
    },
    "modelsMenuItemCartSubcartReadyStatus": {
      "id": "models.menu_item_cart_subcart.statuses.ready_status",
      "defaultMessage": "Ready"
    },
    "modelsMenuItemCartSubcartRejectedStatus": {
      "id": "models.menu_item_cart_subcart.statuses.rejected_status",
      "defaultMessage": "Rejected"
    },
    "modelsShopItemCartNoPaymentAccountModalMessage": {
      "id": "models.shop_item_cart.no_payment_account_modal.message",
      "defaultMessage": "Please select an online payment account below before turning on Gift Card Ordering."
    },
    "modelsShopItemCartSelf": {
      "id": "models.shop_item_cart.self",
      "defaultMessage": "Digital Gift Card Order"
    },
    "modelsGiftCardSelf": {
      "id": "models.gift_card.self",
      "defaultMessage": "Digital Gift Card"
    },
    "modelsConnectedPaymentStatusesRequiresPaymentMethodStatus": {
      "id": "models.connected_payment.statuses.requires_payment_method_status",
      "defaultMessage": "Payment Not Ready"
    },
    "modelsConnectedPaymentStatusesRequiresActionStatus": {
      "id": "models.connected_payment.statuses.requires_action_status",
      "defaultMessage": "Payment Not Ready"
    },
    "modelsConnectedPaymentStatusesRequiresConfirmationStatus": {
      "id": "models.connected_payment.statuses.requires_confirmation_status",
      "defaultMessage": "Payment Not Ready"
    },
    "modelsConnectedPaymentStatusesDeniedStatus": {
      "id": "models.connected_payment.statuses.denied_status",
      "defaultMessage": "Payment Not Ready"
    },
    "modelsConnectedPaymentStatusesRequiresAuthorizedStatus": {
      "id": "models.connected_payment.statuses.requires_authorized_status",
      "defaultMessage": "Payment Ready"
    },
    "modelsConnectedPaymentStatusesRequiresCaptureStatus": {
      "id": "models.connected_payment.statuses.requires_capture_status",
      "defaultMessage": "Payment Ready"
    },
    "modelsConnectedPaymentStatusesApprovedStatus": {
      "id": "models.connected_payment.statuses.approved_status",
      "defaultMessage": "Payment Ready"
    },
    "modelsConnectedPaymentStatusesAuthorizedStatus": {
      "id": "models.connected_payment.statuses.authorized_status",
      "defaultMessage": "Payment Ready"
    },
    "modelsConnectedPaymentStatusesProcessingStatus": {
      "id": "models.connected_payment.statuses.processing_status",
      "defaultMessage": "Payment Processing"
    },
    "modelsConnectedPaymentStatusesSucceededStatus": {
      "id": "models.connected_payment.statuses.succeeded_status",
      "defaultMessage": "Payment Captured"
    },
    "modelsConnectedPaymentStatusesCapturedStatus": {
      "id": "models.connected_payment.statuses.captured_status",
      "defaultMessage": "Payment Captured"
    },
    "modelsConnectedPaymentStatusesCompletedStatus": {
      "id": "models.connected_payment.statuses.completed_status",
      "defaultMessage": "Payment Captured"
    },
    "modelsConnectedPaymentStatusesPaidStatus": {
      "id": "models.connected_payment.statuses.paid_status",
      "defaultMessage": "Payment Captured"
    },
    "modelsConnectedPaymentStatusesClosedStatus": {
      "id": "models.connected_payment.statuses.closed_status",
      "defaultMessage": "Payment Captured"
    },
    "modelsConnectedPaymentStatusesCanceledStatus": {
      "id": "models.connected_payment.statuses.canceled_status",
      "defaultMessage": "Payment Canceled"
    },
    "modelsConnectedPaymentStatusesRefundedStatus": {
      "id": "models.connected_payment.statuses.refunded_status",
      "defaultMessage": "Payment Refunded"
    },
    "modelsRestaurant::LocationDefaultPickupDueMinutes": {
      "id": "models.restaurant/location.default_pickup_due_minutes",
      "defaultMessage": "Default Time to Prepare (minutes)"
    },
    "modelsRestaurant::LocationOrderingDeliveryDriveTime": {
      "id": "models.restaurant/location.ordering_delivery_drive_time",
      "defaultMessage": "Default Delivery Time (minutes)"
    },
    "modelsRestaurant::LocationIsOrderingEnabled": {
      "id": "models.restaurant/location.is_ordering_enabled",
      "defaultMessage": "Ordering Enabled"
    },
    "modelsRestaurant::LocationIsOrderingScheduleEnabled": {
      "id": "models.restaurant/location.is_ordering_schedule_enabled",
      "defaultMessage": "Allow customers to place scheduled orders"
    },
    "modelsRestaurant::LocationIsOrderingSpecialInstructionsEnabled": {
      "id": "models.restaurant/location.is_ordering_special_instructions_enabled",
      "defaultMessage": "Allow customers to include special instructions on orders and items"
    },
    "modelsRestaurant::LocationIsOrderingMessagesEnabled": {
      "id": "models.restaurant/location.is_ordering_messages_enabled",
      "defaultMessage": "Send customers order status notifications by email"
    },
    "modelsRestaurant::LocationIsOrderingMessagesEnabledTooltip": {
      "id": "models.restaurant/location.is_ordering_messages_enabled_tooltip",
      "defaultMessage": "If opted-in, customers will automatically receive an email when their order is received, when an estimated pickup time is provided, and when their order is ready for pickup"
    },
    "modelsRestaurant::LocationIsOrderingMessagesForCateringEnabled": {
      "id": "models.restaurant/location.is_ordering_messages_for_catering_enabled",
      "defaultMessage": "Send customers catering order status notifications by email"
    },
    "modelsRestaurant::LocationIsOrderingMessagesForCateringEnabledEnabledTooltip": {
      "id": "models.restaurant/location.is_ordering_messages_for_catering_enabled_tooltip",
      "defaultMessage": "If opted-in, customers will automatically receive an email when their catering order is received, if the order is rejected or accepted, and a reminder when their order is ready for pickup"
    },
    "modelsRestaurant::LocationhideTaxesAndTipsDisclaimersOnPickupEnabled": {
      "id": "models.restaurant/location.hide_taxes_and_tips_disclaimers_on_pickup",
      "defaultMessage": "Hide Tax and Tip Disclaimers for Pay on Pickup orders"
    },
    "modelsRestaurant::LocationIshideTaxesAndTipsDisclaimersOnPickupEnabledTooltip": {
      "id": "models.restaurant/location.hide_taxes_and_tips_disclaimers_on_pickup_tooltip",
      "defaultMessage": "Different countries have different tax and tipping rules and etiquette. This toggle hides the tax, tips and the \"Notes\" text fields on pay on pickup orders for customers ordering at locations outside of the U.S.A"
    },
    "modelsRestaurant::LocationIsOrderingTextMessagesEnabled": {
      "id": "models.restaurant/location.is_ordering_text_messages_enabled",
      "defaultMessage": "Send customers order status notifications by text message"
    },
    "modelsRestaurant::LocationIsOrderingTextMessagesEnabledTooltip": {
      "id": "models.restaurant/location.is_ordering_text_messages_enabled_tooltip",
      "defaultMessage": "If opted-in, customers will automatically receive a text message when their order is received, when an estimated pickup time is provided, and when their order is ready for pickup"
    },
    "modelsRestaurant::LocationOrderingDeliveryMaxMenuItemCartSubtotal": {
      "id": "models.restaurant/location.ordering_delivery_max_menu_item_cart_subtotal",
      "defaultMessage": "Maximum Order Amount ({currencySymbol})"
    },
    "modelsRestaurant::LocationOrderingDeliveryMinMenuItemCartSubtotal": {
      "id": "models.restaurant/location.ordering_delivery_min_menu_item_cart_subtotal",
      "defaultMessage": "Minimum Order Amount ({currencySymbol})"
    },
    "modelsRestaurant::LocationBagFeeAmount": {
      "id": "models.restaurant/location.bag_fee_amount",
      "defaultMessage": "Bag Fee Amount ({currencySymbol})"
    },
    "modelsRestaurant::LocationOrderingPickupMaxMenuItemCartSubtotal": {
      "id": "models.restaurant/location.ordering_pickup_max_menu_item_cart_subtotal",
      "defaultMessage": "Maximum Order Amount ({currencySymbol})"
    },
    "modelsRestaurant::LocationOrderingPickupMinMenuItemCartSubtotal": {
      "id": "models.restaurant/location.ordering_pickup_min_menu_item_cart_subtotal",
      "defaultMessage": "Minimum Order Amount ({currencySymbol})"
    },
    "modelsStripeConnectAccountCardPaymentsStatusesInactiveCardPaymentsStatus": {
      "id": "models.stripe_connect_account.card_payments_statuses.inactive_card_payments_status",
      "defaultMessage": "Inactive"
    },
    "modelsStripeConnectAccountCardPaymentsStatusesPendingCardPaymentsStatus": {
      "id": "models.stripe_connect_account.card_payments_statuses.pending_card_payments_status",
      "defaultMessage": "Pending"
    },
    "modelsStripeConnectAccountCardPaymentsStatusesActiveCardPaymentsStatus": {
      "id": "models.stripe_connect_account.card_payments_statuses.active_card_payments_status",
      "defaultMessage": "Active"
    },
    "modelsCustomPageCustomCanonicalUrl": {
      "id": "models.custom_page.custom_canonical_url",
      "defaultMessage": "Custom Canonical URL"
    },
    "modelsCustomPageCustomMetaTitle": {
      "id": "models.custom_page.custom_meta_title",
      "defaultMessage": "Page Title"
    },
    "modelsRestaurantIsMemberOnly": {
      "id": "models.restaurant.is_member_only",
      "defaultMessage": "Team-only"
    },
    "modelsCustomPageHeaderFontCasesHfcDefault": {
      "id": "models.custom_page.header_font_cases.hfc_default",
      "defaultMessage": "Default"
    },
    "modelsCustomPageHeaderFontCasesHfcUppercase": {
      "id": "models.custom_page.header_font_cases.hfc_uppercase",
      "defaultMessage": "Uppercase"
    },
    "modelsCustomPageHeaderFontCasesHfcLowercase": {
      "id": "models.custom_page.header_font_cases.hfc_lowercase",
      "defaultMessage": "Lowercase"
    },
    "modelsCustomPageHeaderFontCasesHfcCapitalize": {
      "id": "models.custom_page.header_font_cases.hfc_capitalize",
      "defaultMessage": "Titleize"
    },
    "modelsCustomPageHeaderFontCasesHfcNone": {
      "id": "models.custom_page.header_font_cases.hfc_none",
      "defaultMessage": "Normal"
    },
    "modelsCustomPageCustomMetaDescription": {
      "id": "models.custom_page.custom_meta_description",
      "defaultMessage": "Meta Description"
    },
    "modelsCustomPageCustomMetaImage": {
      "id": "models.custom_page.custom_meta_image",
      "defaultMessage": "Custom Meta Image"
    },
    "modelsCustomPageCustomMetaKeywords": {
      "id": "models.custom_page.custom_meta_keywords",
      "defaultMessage": "Meta Keywords"
    },
    "modelsCustomPageDisableAjaxLoad": {
      "id": "models.custom_page.disable_ajax_load",
      "defaultMessage": "Disable AJAX loading"
    },
    "modelsCustomPageHeaderFontCase": {
      "id": "models.custom_page.header_font_case",
      "defaultMessage": "Heading Font Case"
    },
    "modelsCustomPageIsEnabled": {
      "id": "models.custom_page.is_enabled",
      "defaultMessage": "Enabled"
    },
    "modelsCustomPageIsHomePage": {
      "id": "models.custom_page.is_home_page",
      "defaultMessage": "Home Page"
    },
    "modelsCustomPageIsNavEnabled": {
      "id": "models.custom_page.is_nav_enabled",
      "defaultMessage": "Display navigation"
    },
    "modelsCustomPageMetaImageType": {
      "id": "models.custom_page.meta_image_type",
      "defaultMessage": "Meta Image"
    },
    "modelsCustomPageHeaderLinkText": {
      "id": "models.custom_page.header_link_text",
      "defaultMessage": "Link Display Text"
    },
    "modelsCustomPageHeaderLinkCustomPageId": {
      "id": "models.custom_page.header_link_custom_page_id",
      "defaultMessage": "Link URL"
    },
    "modelsCustomPageHeaderLinkLinkTypeCustomPageType": {
      "id": "models.custom_page.header_link_link_type_custom_page_type",
      "defaultMessage": "Custom Page"
    },
    "modelsCustomPageHeaderLinkLinkTypeCustomPage": {
      "id": "models.custom_page.header_link_link_type_custom_page",
      "defaultMessage": "URL"
    },
    "modelsCustomPageHeaderLinkLinkTypeCustomPageIsExternal": {
      "id": "models.custom_page.header_link_link_type_custom_page.is_external",
      "defaultMessage": "Open New Tab"
    },
    "modelsRestaurant::HeaderLinkLinkTypesCustomType": {
      "id": "models.custom_page.header_link.link_types.custom_type",
      "defaultMessage": "Custom URL"
    },
    "modelsRestaurant::HeaderLinkLinkTypesOnlineOrdering": {
      "id": "models.custom_page.header_link.link_types.ordering_app_type",
      "defaultMessage": "Order Online"
    },
    "modelsRestaurant::HeaderLinkLinkTypesCustomPageType": {
      "id": "models.custom_page.header_link.link_types.custom_page_type",
      "defaultMessage": "Page"
    },
    "modelsCustomPageName": {
      "id": "models.custom_page.name",
      "defaultMessage": "Heading"
    },
    "modelsCustomPageSlug": {
      "id": "models.custom_page.slug",
      "defaultMessage": "Page URL"
    },
    "modelsCustomPageHeading": {
      "id": "models.custom_page.heading",
      "defaultMessage": "Heading"
    },
    "modelsCustomPageSubheading": {
      "id": "models.custom_page.subheading",
      "defaultMessage": "Subheading"
    },
    "modelsCustomPageMetaImageTypesMitCustom": {
      "id": "models.custom_page.meta_image_types.mit_custom",
      "defaultMessage": "Custom Image"
    },
    "modelsCustomPageMeta": {
      "id": "models.custom_page.meta",
      "defaultMessage": "Meta"
    },
    "modelsCustomPageSettingsHeading": {
      "id": "models.custom_page.settings_heading",
      "defaultMessage": "Settings"
    },
    "modelsCustomPageMetaImageTypesMitHero": {
      "id": "models.custom_page.meta_image_types.mit_hero",
      "defaultMessage": "Header Image"
    },
    "modelsCustomPageMetaImageTypesMitLogo": {
      "id": "models.custom_page.meta_image_types.mit_logo",
      "defaultMessage": "Logo"
    },
    "modelsCustomPageSelf": {
      "id": "models.custom_page.self",
      "defaultMessage": "Page"
    },
    "modelsCustomPageCreatedAt": {
      "id": "models.custom_page.created_at",
      "defaultMessage": "Created"
    },
    "modelsCustomPageHeaderBgFullHeight": {
      "id": "models.custom_page.header_bg_full_height",
      "defaultMessage": "Fit To Screen"
    },
    "modelsCustomPageHeaderImageAlt": {
      "id": "models.custom_page.header_image_alt",
      "defaultMessage": "Header Image Description"
    },
    "modelsCustomPageHeaderImageSetting": {
      "id": "models.custom_page.header_image_setting",
      "defaultMessage": "Header Image Setting"
    },
    "modelsCustomPageHeroBackgroundColor": {
      "id": "models.custom_page.hero_background_color",
      "defaultMessage": "Header Background Color"
    },
    "modelsCustomPageHeroImage": {
      "id": "models.custom_page.hero_image",
      "defaultMessage": "Header Image"
    },
    "modelsCustomPageHeroVideo": {
      "id": "models.custom_page.hero_video",
      "defaultMessage": "Header Video"
    },
    "modelsCustomPageHeroLogo": {
      "id": "models.custom_page.hero_logo",
      "defaultMessage": "Header Logo Image"
    },
    "modelsCustomPageHeroLogoAlt": {
      "id": "models.custom_page.hero_logo_alt",
      "defaultMessage": "Header Logo Description"
    },
    "modelsCustomPageHeaderTransitionType": {
      "id": "models.custom_page.header_transition_type",
      "defaultMessage": "Header Transition"
    },
    "modelsCustomPageNavbarLogoToggle": {
      "id": "models.custom_page.navbar_logo_toggle",
      "defaultMessage": "Show Navbar Logo"
    },
    "modelsCustomPageTabName": {
      "id": "models.custom_page.tab_name",
      "defaultMessage": "Tab"
    },
    "modelsCustomPage::SectionSectionTypesAdvancedFormSection": {
      "id": "models.custom_page/section.section_types.advanced_forms_section",
      "defaultMessage": "Advanced Forms"
    },
    "modelsCustomPage::AdvancedFormsShortQuestionFieldType": {
      "id": "models.custom_page/section.advanced_forms.field_type.short_question",
      "defaultMessage": "Short Question"
    },
    "modelsCustomPage::SectionSectionTypesAnnouncementSection": {
      "id": "models.custom_page/section.section_types.announcement_section",
      "defaultMessage": "Latest Announcement"
    },
    "modelsCustomPage::SectionSectionTypesCalendarEventSection": {
      "id": "models.custom_page/section.section_types.calendar_event_section",
      "defaultMessage": "Events"
    },
    "modelsCustomPage::SectionSectionTypesCustomEmbedSection": {
      "id": "models.custom_page/section.section_types.custom_embed_section",
      "defaultMessage": "Embedded Script"
    },
    "modelsCustomPage::SectionSectionTypesCustomSection": {
      "id": "models.custom_page/section.section_types.custom_section",
      "defaultMessage": "Custom HTML"
    },
    "modelsCustomPage::SectionSectionTypesEcwidShopSection": {
      "id": "models.custom_page/section.section_types.ecwid_shop_section",
      "defaultMessage": "Third-party: Ecwid Shop"
    },
    "modelsCustomPage::SectionSectionTypesFacebookFeedSection": {
      "id": "models.custom_page/section.section_types.facebook_feed_section",
      "defaultMessage": "Third-party: Facebook Feed"
    },
    "modelsCustomPage::SectionSectionTypesPopmenuInstagramPhotoGridSection": {
      "id": "models.custom_page/section.section_types.popmenu_instagram_feed_section",
      "defaultMessage": "Popmenu Instagram Photo Grid"
    },
    "modelsCustomPage::SectionSectionTypesFoodtecSection": {
      "id": "models.custom_page/section.section_types.foodtec_section",
      "defaultMessage": "Third-party: Foodtec"
    },
    "modelsCustomPage::SectionSectionTypesFormSection": {
      "id": "models.custom_page/section.section_types.form_section",
      "defaultMessage": "Contact/Employment Form"
    },
    "modelsCustomPage::SectionSectionTypesFormsiteSection": {
      "id": "models.custom_page/section.section_types.formsite_section",
      "defaultMessage": "Third-party: Formsite"
    },
    "modelsCustomPage::SectionSectionTypesGiftCardSection": {
      "id": "models.custom_page/section.section_types.gift_card_section",
      "defaultMessage": "Gift Card"
    },
    "modelsCustomPage::SectionSectionTypesGallerySection": {
      "id": "models.custom_page/section.section_types.gallery_section",
      "defaultMessage": "Photo Gallery"
    },
    "modelsCustomPage::SectionSectionTypesLocation": {
      "id": "models.custom_page/section.section_types.location",
      "defaultMessage": "Location"
    },
    "modelsCustomPage::SectionSectionTypesLocationSearchSection": {
      "id": "models.custom_page/section.section_types.location_search_section",
      "defaultMessage": "Location Search"
    },
    "modelsCustomPage::SectionSectionTypesLocationSection": {
      "id": "models.custom_page/section.section_types.location_section",
      "defaultMessage": "Location Info"
    },
    "modelsCustomPage::SectionSectionTypesMapSection": {
      "id": "models.custom_page/section.section_types.map_section",
      "defaultMessage": "Location Map"
    },
    "modelsCustomPage::SectionSectionTypesAddLocation": {
      "id": "models.custom_page/section.section_types.add_location",
      "defaultMessage": "Add Location"
    },
    "modelsCustomPage::SectionSectionTypesMenuSection": {
      "id": "models.custom_page/section.section_types.menu_section",
      "defaultMessage": "Menu/Menus"
    },
    "modelsCustomPage::SectionSectionTypesMultiColumnSection": {
      "id": "models.custom_page/section.section_types.multi_column_section",
      "defaultMessage": "Multi-content"
    },
    "modelsCustomPage::SectionSectionTypesOnlineOrderingSection": {
      "id": "models.custom_page/section.section_types.online_ordering_section",
      "defaultMessage": "Online Ordering"
    },
    "modelsCustomPage::SectionSectionTypesOnlineOrderingManageMenus": {
      "id": "models.custom_page/section.section_types.online_ordering_manage_menus",
      "defaultMessage": "Manage Ordering Menus"
    },
    "modelsCustomPage::SectionSectionTypesReviewSectionField": {
      "id": "models.custom_page/section.section_types.review_section_field",
      "defaultMessage": "This section will dynamically display recently approved reviews alongside the review submission form.",
    },
    "modelsCustomPage::SectionSectionTypesPowrInstagramFeedSection": {
      "id": "models.custom_page/section.section_types.powr_instagram_feed_section",
      "defaultMessage": "Third-party: Instagram Feed"
    },
    "modelsCustomPage::SectionSectionTypesPressSection": {
      "id": "models.custom_page/section.section_types.press_section",
      "defaultMessage": "Press"
    },
    "modelsCustomPage::SectionSectionTypesReviewSection": {
      "id": "models.custom_page/section.section_types.review_section",
      "defaultMessage": "Recent Reviews"
    },
    "modelsCustomPage::SectionSectionTypesTextBoxSection": {
      "id": "models.custom_page/section.section_types.text_box_section",
      "defaultMessage": "Text Box"
    },
    "modelsCustomPage::SectionSectionTypesTripleseatFormSection": {
      "id": "models.custom_page/section.section_types.tripleseat_form_section",
      "defaultMessage": "Tripleseat Form"
    },
    "modelsCustomPage::SectionSectionTypesVideoSection": {
      "id": "models.custom_page/section.section_types.video_section",
      "defaultMessage": "Video"
    },
    "modelsCustomPage::SectionSectionTypesYoutubeVideoSection": {
      "id": "models.custom_page/section.section_types.youtube_video_section",
      "defaultMessage": "Third-party: YouTube Video"
    },
    "modelsCustomPage::SectionBackgroundImageSettingsStatic": {
      "id": "models.custom_page/section.background_image_settings.static",
      "defaultMessage": "Static"
    },
    "modelsCustomPage::SectionBackgroundImageSettingsFixed": {
      "id": "models.custom_page/section.background_image_settings.fixed",
      "defaultMessage": "Fixed"
    },
    "modelsCustomPage::SectionBackgroundImageSettingsParallax": {
      "id": "models.custom_page/section.background_image_settings.parallax",
      "defaultMessage": "Parallax"
    },
    "modelsCustomPage::SectionBackgroundEffectsBgCover": {
      "id": "models.custom_page/section.background_effects.bg_cover",
      "defaultMessage": "Cover"
    },
    "modelsCustomPage::SectionBackgroundEffectsBgRepeat": {
      "id": "models.custom_page/section.background_effects.bg_repeat",
      "defaultMessage": "Tile"
    },
    "modelsCustomPage::SectionBackgroundEffectsBgRepeatY": {
      "id": "models.custom_page/section.background_effects.bg_repeat_y",
      "defaultMessage": "Tile Vertically"
    },
    "modelsCustomPage::SectionBackgroundEffectsBgStatic": {
      "id": "models.custom_page/section.background_effects.bg_static",
      "defaultMessage": "Center"
    },
    "modelsCustomPage::SectionAccessibleAltContent": {
      "id": "models.custom_page/section.accessible_alt_content",
      "defaultMessage": "Accessible Text-only Alternative"
    },
    "modelsCustomPage::SectionBackgroundColor": {
      "id": "models.custom_page/section.background_color",
      "defaultMessage": "Background Color"
    },
    "modelsCustomPage::SectionBackgroundEffect": {
      "id": "models.custom_page/section.background_effect",
      "defaultMessage": "Background Effect"
    },
    "modelsCustomPage::SectionBackgroundImage": {
      "id": "models.custom_page/section.background_image",
      "defaultMessage": "Background Image"
    },
    "modelsCustomPage::SectionBackgroundImageSetting": {
      "id": "models.custom_page/section.background_image_setting",
      "defaultMessage": "Background Image Setting"
    },
    "modelsCustomPage::SectionCustomHeading": {
      "id": "models.custom_page/section.custom_heading",
      "defaultMessage": "Section Heading"
    },
    "modelsCustomPage::SectionCustomSubheading": {
      "id": "models.custom_page/section.custom_subheading",
      "defaultMessage": "Section Subheading"
    },
    "modelsCustomPage::SectionAdvancedFormsName": {
      "id": "models.custom_page/section.advanced_form_name",
      "defaultMessage": "Form Name"
    },
    "modelsCustomPage::SectionIsEnabled": {
      "id": "models.custom_page/section.is_enabled",
      "defaultMessage": "Enabled"
    },
    "modelsCustomPage::SectionIsFullWidth": {
      "id": "models.custom_page/section.is_full_width",
      "defaultMessage": "Full Page Width"
    },
    "modelsCustomPage::BackgroundColor": {
      "id": "models.custom_page/section.background_color",
      "defaultMessage": "Background Color"
    },
    "modelsCustomPage::FontColor": {
      "id": "models.custom_page/section.font_color",
      "defaultMessage": "Font Color"
    },
    "modelsCustomPage::SectionSlug": {
      "id": "models.custom_page/section.slug",
      "defaultMessage": "Element ID"
    },
    "modelsCustomPage::SectionTransitionType": {
      "id": "models.custom_page/section.transition_type",
      "defaultMessage": "Transition Type"
    },
    "modelsCustomPage::SectionTransitionTypesTtDefault": {
      "id": "models.custom_page/section.transition_types.tt_default",
      "defaultMessage": "Default"
    },
    "modelsCustomPage::SectionTransitionTypesTtNone": {
      "id": "models.custom_page/section.transition_types.tt_none",
      "defaultMessage": "None"
    },
    "modelsCustomPage::SectionTransitionTypesTtFade": {
      "id": "models.custom_page/section.transition_types.tt_fade",
      "defaultMessage": "Fade"
    },
    "modelsCustomPage::SectionTransitionTypesTtGrow": {
      "id": "models.custom_page/section.transition_types.tt_grow",
      "defaultMessage": "Grow"
    },
    "modelsCustomPage::SectionTransitionTypesTtSlide": {
      "id": "models.custom_page/section.transition_types.tt_slide",
      "defaultMessage": "Slide"
    },
    "modelsCustomPage::SectionName": {
      "id": "models.custom_page/section.name",
      "defaultMessage": "Section Name"
    },
    "modelsRootNoteContent": {
      "id": "models.root_note.content",
      "defaultMessage": "Note"
    },
    "modelsRootNoteNoteType": {
      "id": "models.root_note.note_type",
      "defaultMessage": "Note Category"
    },
    "modelsSharedExtraGroupSelf": {
      "id": "models.shared_extra_group.self",
      "defaultMessage": "Modifier Group"
    },
    "modelsModifierGroupSelf": {
      "id": "models.modifier_group.self",
      "defaultMessage": "Modifier Group"
    },
    "modelsSharedExtraGroupName": {
      "id": "models.shared_extra_group.name",
      "defaultMessage": "Group Name"
    },
    "modelsSharedExtraGroupOutOfStock": {
      "id": "models.shared_extra_group.out_of_stock",
      "defaultMessage": "Out of Stock"
    },
    "modelsModifierGroupName": {
      "id": "models.modifier_group.name",
      "defaultMessage": "Group Name"
    },
    "modelsModifierGroupOutOfStock": {
      "id": "models.modifier_group.out_of_stock",
      "defaultMessage": "Out of Stock"
    },
    "modelsModifierGroupItemsAttachedCount": {
      "id": "models.modifier_group.parent_dishes_count",
      "defaultMessage": "Items Attached To"
    },
    "modelsModifierGroupUpdatedAt": {
      "id": "models.modifier_group.updated_at",
      "defaultMessage": "Last Updated"
    },
    "modelsModifierGroupOutOfStockModifiers": {
      "id": "models.modifier_group.out_of_stock_modifiers",
      "defaultMessage": "Out of Stock"
    },
    "modelsSharedExtraGroupMinSelectionsCount": {
      "id": "models.shared_extra_group.min_selections_count",
      "defaultMessage": "Min Selections"
    },
    "modelsSharedExtraGroupMaxSelectionsCount": {
      "id": "models.shared_extra_group.max_selections_count",
      "defaultMessage": "Max Selections"
    },
    "modelsBillingProduct::PriceCreatedAt": {
      "id": "models.billing_product/price.created_at",
      "defaultMessage": "Created"
    },
    "modelsBillingProduct::PriceCurrency": {
      "id": "models.billing_product/price.currency",
      "defaultMessage": "Currency"
    },
    "modelsBillingProduct::PriceNickname": {
      "id": "models.billing_product/price.nickname",
      "defaultMessage": "Description"
    },
    "modelsBillingProduct::PriceIsActive": {
      "id": "models.billing_product/price.is_active",
      "defaultMessage": "Active"
    },
    "modelsBillingProduct::PriceIsRestricted": {
      "id": "models.billing_product/price.is_restricted",
      "defaultMessage": "Restricted"
    },
    "modelsBillingProduct::PriceSelfs": {
      "id": "models.billing_product/price.selfs",
      "defaultMessage": "Prices"
    },
    "modelsBillingProduct::PriceUnitAmount": {
      "id": "models.billing_product/price.unit_amount",
      "defaultMessage": "Amount"
    },
    "modelsBillingProductSelf": {
      "id": "models.billing_product.self",
      "defaultMessage": "One-Time Product"
    },
    "modelsBillingProductCreatedAt": {
      "id": "models.billing_product.created_at",
      "defaultMessage": "Created"
    },
    "modelsBillingProductDescription": {
      "id": "models.billing_product.description",
      "defaultMessage": "Description"
    },
    "modelsBillingProductIsActive": {
      "id": "models.billing_product.is_active",
      "defaultMessage": "Active"
    },
    "modelsBillingProductIsRestricted": {
      "id": "models.billing_product.is_restricted",
      "defaultMessage": "Restricted"
    },
    "modelsBillingProductName": {
      "id": "models.billing_product.name",
      "defaultMessage": "Product Name"
    },
    "modelsBillingProductStatementDescriptor": {
      "id": "models.billing_product.statement_descriptor",
      "defaultMessage": "Statement Descriptor"
    },
    "modelsBillingProductStripeId": {
      "id": "models.billing_product.stripe_id",
      "defaultMessage": "Stripe ID"
    },
    "modelsOfferCreatedAt": {
      "id": "models.offer.created_at",
      "defaultMessage": "Created"
    },
    "modelsOfferOfferCodesCount": {
      "id": "models.offer.offer_codes_count",
      "defaultMessage": "Followers"
    },
    "modelsThemeFontSelf": {
      "id": "models.theme_font.self",
      "defaultMessage": "Custom Font"
    },
    "modelsThemeFontSelves": {
      "id": "models.theme_font.selves",
      "defaultMessage": "Custom Fonts"
    },
    "modelsThemeFontCreatedAt": {
      "id": "models.theme_font.created_at",
      "defaultMessage": "Added"
    },
    "modelsThemeFontName": {
      "id": "models.theme_font.name",
      "defaultMessage": "Font Family"
    },
    "modelsMessageGroupSelf": {
      "id": "models.message_group.self",
      "defaultMessage": "Mass Message"
    },
    "modelsMessageGroupClickRate": {
      "id": "models.message_group.click_rate",
      "defaultMessage": "Click Rate"
    },
    "modelsMessageGroupCreatedAt": {
      "id": "models.message_group.created_at",
      "defaultMessage": "Created At"
    },
    "modelsMessageGroupFullDisplayName": {
      "id": "models.message_group.user.full_display_name",
      "defaultMessage": "User"
    },
    "modelsMessageGroupMessagesCount": {
      "id": "models.message_group.messages_count",
      "defaultMessage": "Messages"
    },
    "modelsMessageGroupOpenRate": {
      "id": "models.message_group.open_rate",
      "defaultMessage": "Open Rate"
    },
    "modelsMessageGroupProcessedAt": {
      "id": "models.message_group.processed_at",
      "defaultMessage": "Sent"
    },
    "modelsMessageGroupScheduledAt": {
      "id": "models.message_group.scheduled_at",
      "defaultMessage": "Scheduled"
    },
    "modelsMessageGroupSending": {
      "id": "models.message_group.sending",
      "defaultMessage": "Sending..."
    },
    "modelsMessageGroupDeliveredRatio": {
      "id": "models.message_group.delivered_ratio",
      "defaultMessage": "{deliveredCount} ({deliveredRatio}%) out of {totalCount} delivered"
    },
    "modelsMessageGroupRestaurantName": {
      "id": "models.message_group.restaurant_name",
      "defaultMessage": "Restaurant"
    },
    "modelsMessageGroupUpdatedAt": {
      "id": "models.message_group.updated_at",
      "defaultMessage": "Updated At"
    },
    "modelsRestaurant::Location::OpeningRangeOpenTime": {
      "id": "models.restaurant/location/opening_range.open_time",
      "defaultMessage": "Open"
    },
    "modelsRestaurant::Location::OpeningRangeCloseTime": {
      "id": "models.restaurant/location/opening_range.close_time",
      "defaultMessage": "Close"
    },
    "modelsRestaurant::Location::HolidayOpeningRangeName": {
      "id": "models.restaurant/location/holiday_opening_range.name",
      "defaultMessage": "Holiday Name"
    },
    "modelsRestaurant::Location::HolidayOpeningRangeDate": {
      "id": "models.restaurant/location/holiday_opening_range.date",
      "defaultMessage": "Holiday Date"
    },
    "modelsRestaurant::Location::HolidayOpeningRangeOpenTime": {
      "id": "models.restaurant/location/holiday_opening_range.open_time",
      "defaultMessage": "Open Time"
    },
    "modelsRestaurant::Location::HolidayOpeningRangeCloseTime": {
      "id": "models.restaurant/location/holiday_opening_range.close_time",
      "defaultMessage": "Close Time"
    },
    "modelsRestaurant::LocationOpenAllDay": {
      "id": "models.restaurant/location.open_all_day",
      "defaultMessage": "Open 24 Hours"
    },
    "modelsRestaurant::LocationUntilClose": {
      "id": "models.restaurant/location.until_close",
      "defaultMessage": "Until Close"
    },
    "modelsRestaurant::LocationAdditionalHoursContent": {
      "id": "models.restaurant/location.additional_hours_content",
      "defaultMessage": "Additional Notes"
    },
    "modelsRestaurant::LocationAdditionalHoursContentTooltip": {
      "id": "models.restaurant/location.additional_hours_content_tooltip",
      "defaultMessage": "e.g., Kitchen closes at 10 pm"
    },
    "modelsRestaurant::LocationAddAdditionalHours": {
      "id": "models.restaurant/location.add_additional_hours",
      "defaultMessage": "Add Additional Hours"
    },
    "modelsRestaurant::LocationCustomFormEmailCsv": {
      "id": "models.restaurant/location.custom_form_email_csv",
      "defaultMessage": "Form Notification Emails (CSV)"
    },
    "modelsRestaurant::LocationCustomContentLinks": {
      "id": "models.restaurant/location.custom_content_links",
      "defaultMessage": "Location Links"
    },
    "modelsRestaurant::LocationCustomContentLinksSubheader": {
      "id": "models.restaurant/location.custom_content_links_subheader",
      "defaultMessage": "Add up to four links. This will show on the location search section in the location information."
    },
    "modelsRestaurant::LocationCustomContentAddLink": {
      "id": "models.restaurant/location.custom_content_add_link",
      "defaultMessage": "Add Link"
    },
    "modelsRestaurant::LocationCustomContentLinkDisplayText": {
      "id": "models.restaurant/location.custom_content_link_display_text",
      "defaultMessage": "Link Display Text"
    },
    "modelsRestaurant::LocationCustomContentLinkType": {
      "id": "models.restaurant/location.custom_content_link_type",
      "defaultMessage": "Link Type"
    },
    "modelsRestaurant::LocationCustomContentLinkCustomPageType": {
      "id": "models.restaurant/location.custom_content_link.custom_page_type",
      "defaultMessage": "Page"
    },
    "modelsRestaurant::LocationCustomContentLinkMenuType": {
      "id": "models.restaurant/location.custom_content_link.menu_type",
      "defaultMessage": "Menus"
    },
    "modelsRestaurant::LocationCustomContentLinkCustomType": {
      "id": "models.restaurant/location.custom_content_link.custom_type",
      "defaultMessage": "Custom URL"
    },
    "modelsRestaurant::LocationCustomContentLinkMenuSingular": {
      "id": "models.restaurant/location.custom_content_link.menu_singular",
      "defaultMessage": "Menu"
    },
    "modelsRestaurant::LocationGooglePlaceId": {
      "id": "models.restaurant/location.google_place_id",
      "defaultMessage": "Google Place ID"
    },
    "modelsRestaurant::LocationName": {
      "id": "models.restaurant/location.name",
      "defaultMessage": "Location Name (e.g., city or plaza name)"
    },
    "modelsRestaurant::LocationOpenTableId": {
      "id": "models.restaurant/location.open_table_id",
      "defaultMessage": "Open Table ID"
    },
    "modelsRestaurant::LocationOpenTableLastSync": {
      "id": "models.restaurant/location.open_table_last_sync",
      "defaultMessage": "Last synced on"
    },
    "modelsRestaurant::LocationOpenTableNeverSynced": {
      "id": "models.restaurant/location.open_table_never_synced",
      "defaultMessage": "Has never synced OpenTable Guest data"
    },
    "modelsRestaurant::LocationOpenTablePreview": {
      "id": "models.restaurant/location.open_table_preview",
      "defaultMessage": "OpenTable Preview"
    },
    "modelsRestaurant::LocationPhoto": {
      "id": "models.restaurant/location.photo",
      "defaultMessage": "Location Photo"
    },
    "modelsRestaurant::LocationPublishingName": {
      "id": "models.restaurant/location.publishing_name",
      "defaultMessage": "Publishing Name"
    },
    "modelsRestaurant::LocationResyApiKey": {
      "id": "models.restaurant/location.resy_api_key",
      "defaultMessage": "Resy API Key"
    },
    "modelsRestaurant::LocationResyLinkUrl": {
      "id": "models.restaurant/location.resy_link_url",
      "defaultMessage": "Resy Link URL"
    },
    "modelsRestaurant::LocationPickupCurbsideAutoTextReply": {
      "id": "models.restaurant/location.pickup_curbside_auto_text_reply",
      "defaultMessage": "Auto Text Reply"
    },
    "modelsRestaurant::LocationPickupCurbsideAskVehicleInfo": {
      "id": "models.restaurant/location.pickup_curbside_ask_vehicle_info",
      "defaultMessage": "Ask Vehicle Info (Make, Model, and Color)"
    },
    "modelsRestaurant::LocationPickupCurbsideCallTo": {
      "id": "models.restaurant/location.pickup_curbside_call_to",
      "defaultMessage": "Number to Call"
    },
    "modelsRestaurant::LocationPickupCurbsideDescription": {
      "id": "models.restaurant/location.pickup_curbside_description",
      "defaultMessage": "Add message or indication to park here (Optional)"
    },
    "modelsRestaurant::LocationPickupCurbsideCustomLabels": {
      "id": "models.restaurant/location.pickup_curbside_custom_label",
      "defaultMessage": "Curbside Label"
    },
    "modelsRestaurant::LocationPickupCurbsidePhone": {
      "id": "models.restaurant/location.pickup_curbside_phone",
      "defaultMessage": "Restaurant Phone"
    },
    "modelsRestaurant::LocationPickupCurbsideReplyText": {
      "id": "models.restaurant/location.pickup_curbside_reply_text",
      "defaultMessage": "Text Message"
    },
    "modelsRestaurant::LocationPickupInStoreDescription": {
      "id": "models.restaurant/location.pickup_in_store_description",
      "defaultMessage": "Add message or indication here (Optional)"
    },
    "modelsRestaurant::LocationPickupInStoreCustomLabels": {
      "id": "models.restaurant/location.pickup_in_store_custom_label",
      "defaultMessage": "In-Store Label"
    },
    "modelsRestaurant::LocationResyVenueId": {
      "id": "models.restaurant/location.resy_venue_id",
      "defaultMessage": "Resy Venue ID"
    },
    "modelsRestaurant::LocationUseLocationOpeningHours": {
      "id": "models.restaurant/location.use_location_opening_hours",
      "defaultMessage": "Match Online Ordering Hours to Location Hours"
    },
    "modelsRestaurant::LocationYelpFriendlyId": {
      "id": "models.restaurant/location.yelp_friendly_id",
      "defaultMessage": "Yelp ID"
    },
    "modelsRestaurant::Location::FirstDayOfWeekFdowSunday": {
      "id": "models.restaurant/location/first_day_of_week.fdow_sunday",
      "defaultMessage": "Sunday"
    },
    "modelsRestaurant::Location::FirstDayOfWeekFdowMonday": {
      "id": "models.restaurant/location/first_day_of_week.fdow_monday",
      "defaultMessage": "Monday"
    },
    "modelsRestaurant::Location::FirstDayOfWeekFdowTuesday": {
      "id": "models.restaurant/location/first_day_of_week.fdow_tuesday",
      "defaultMessage": "Tuesday"
    },
    "modelsRestaurant::Location::FirstDayOfWeekFdowWednesday": {
      "id": "models.restaurant/location/first_day_of_week.fdow_wednesday",
      "defaultMessage": "Wednesday"
    },
    "modelsRestaurant::Location::FirstDayOfWeekFdowThursday": {
      "id": "models.restaurant/location/first_day_of_week.fdow_thursday",
      "defaultMessage": "Thursday"
    },
    "modelsRestaurant::Location::FirstDayOfWeekFdowFriday": {
      "id": "models.restaurant/location/first_day_of_week.fdow_friday",
      "defaultMessage": "Friday"
    },
    "modelsRestaurant::Location::FirstDayOfWeekFdowSaturday": {
      "id": "models.restaurant/location/first_day_of_week.fdow_saturday",
      "defaultMessage": "Saturday"
    },
    "modelsRestaurant::SelectedCuisineTypeCuisineTypeId": {
      "id": "models.restaurant/selected_cuisine_type.cuisine_type_id",
      "defaultMessage": "Category"
    },
    "modelsRestaurant::MemberSelf": {
      "id": "models.restaurant/member.self",
      "defaultMessage": "Team Member"
    },
    "modelsRestaurant::MemberMemberLevel": {
      "id": "models.restaurant/member.member_level",
      "defaultMessage": "Access Level"
    },
    "modelsRestaurant::MemberMemberLevelTooltip": {
      "id": "models.restaurant/member.member_level_tooltip",
      "defaultMessage": "Limited team members cannot access most settings, and must be granted access to specific features or locations"
    },
    "modelsRestaurant::MemberCanManageAnnouncements": {
      "id": "models.restaurant/member.can_manage_announcements",
      "defaultMessage": "Manage Announcements"
    },
    "modelsRestaurant::MembercanManageAnswering": {
      "id": "models.restaurant/member.can_manage_answering",
      "defaultMessage": "Manage AI Answering"
    },
    "modelsRestaurant::MemberCanManageCustomFiles": {
      "id": "models.restaurant/member.can_manage_custom_files",
      "defaultMessage": "Manage Media"
    },
    "modelsRestaurant::MemberCanManageGiftCards": {
      "id": "models.restaurant/member.can_manage_gift_cards",
      "defaultMessage": "Manage Gift Cards"
    },
    "modelsRestaurant::MemberCanManageItemList": {
      "id": "models.restaurant/member.can_manage_item_list",
      "defaultMessage": "Manage Item List"
    },
    "modelsRestaurant::MemberCanManageOrders": {
      "id": "models.restaurant/member.can_manage_orders",
      "defaultMessage": "Manage All Ordering Locations"
    },
    "modelsRestaurant::MemberCanManageDishes": {
      "id": "models.restaurant/member.can_manage_dishes",
      "defaultMessage": "Manage All Menus"
    },
    "modelsRestaurant::MemberCanManageMessages": {
      "id": "models.restaurant/member.can_manage_messages",
      "defaultMessage": "Manage Messages for All Locations"
    },
    "modelsRestaurant::MemberCanManageOffers": {
      "id": "models.restaurant/member.can_manage_offers",
      "defaultMessage": "Manage Offers"
    },
    "modelsRestaurant::MemberCanManageReviews": {
      "id": "models.restaurant/member.can_manage_reviews",
      "defaultMessage": "Manage Reviews"
    },
    "modelsRestaurant::MemberCanManageSocialPosts": {
      "id": "models.restaurant/member.can_manage_social_posts",
      "defaultMessage": "Manage Social Posts"
    },
    "modelsRestaurant::MemberCanManageThemes": {
      "id": "models.restaurant/member.can_manage_themes",
      "defaultMessage": "Manage Themes"
    },
    "modelsRestaurant::MemberCanManageWaitlists": {
      "id": "models.restaurant/member.can_manage_waitlists",
      "defaultMessage": "Manage Waitlists"
    },
    "modelsRestaurant::MemberCreatedAt": {
      "id": "models.restaurant/member.created_at",
      "defaultMessage": "Member Since"
    },
    "modelsRestaurant::MemberFormEmailNotifications": {
      "id": "models.restaurant/member.form_email_notifications",
      "defaultMessage": "Receive All Form Notifications"
    },
    "modelsRestaurant::MemberHistoryTitle": {
      "id": "models.restaurant/member.history_title",
      "defaultMessage": "Team Member History"
    },
    "modelsRestaurant::MemberReceiveReviewNotifications": {
      "id": "models.restaurant/member.receive_review_notifications",
      "defaultMessage": "Receive New Review Notifications"
    },
    "modelsRestaurant::MemberReceivePerformanceSnapshotNotifications": {
      "id": "models.restaurant/member.receive_performance_snapshot_notifications",
      "defaultMessage": "Receive Weekly Performance Snapshot Notifications"
    },
    "modelsRestaurant::MemberReceiveAiEmailNotifications": {
      "id": "models.restaurant/member.receive_aim_email_notifications",
      "defaultMessage": "Receive AI Marketing Email Notifications"
    },
    "modelsRestaurant::MemberReceiveFormNotifications": {
      "id": "models.restaurant/member.receive_form_notifications",
      "defaultMessage": "Receive All Form Notifications"
    },
    "modelsRestaurant::MemberTasksNotificationFrequency": {
      "id": "models.restaurant/member.tasks_notification_frequency",
      "defaultMessage": "Dashboard Reminder Notifications"
    },
    "modelsRestaurant::Member::VersionCanManageDishes": {
      "id": "models.restaurant/member/version.can_manage_dishes",
      "defaultMessage": "Manage All Menus"
    },
    "modelsRestaurant::Member::VersionCanManageAnnouncements": {
      "id": "models.restaurant/member/version.can_manage_announcements",
      "defaultMessage": "Manage Announcements"
    },
    "modelsRestaurant::Member::VersionCanManageMessages": {
      "id": "models.restaurant/member/version.can_manage_messages",
      "defaultMessage": "Manage Messages"
    },
    "modelsRestaurant::Member::VersionCanManageOrders": {
      "id": "models.restaurant/member/version.can_manage_orders",
      "defaultMessage": "Manage All Orders"
    },
    "modelsRestaurant::Member::VersionCanManageSocialPosts": {
      "id": "models.restaurant/member/version.can_manage_social_posts",
      "defaultMessage": "Manage Social Posts"
    },
    "modelsRestaurant::Member::VersionCreatedAt": {
      "id": "models.restaurant/member/version.created_at",
      "defaultMessage": "Change Date"
    },
    "modelsRestaurant::Member::VersionEvent": {
      "id": "models.restaurant/member/version.event",
      "defaultMessage": "Event"
    },
    "modelsRestaurant::Member::VersionMemberLevel": {
      "id": "models.restaurant/member/version.member_level",
      "defaultMessage": "Access Level"
    },
    "modelsRestaurant::Member::VersionObjectChanges": {
      "id": "models.restaurant/member/version.object_changes",
      "defaultMessage": "Changes"
    },
    "modelsRestaurant::Member::VersionSelf": {
      "id": "models.restaurant/member/version.self",
      "defaultMessage": "Team Member Change"
    },
    "modelsRestaurant::Member::VersionUserDisplayName": {
      "id": "models.restaurant/member/version.user_display_name",
      "defaultMessage": "User"
    },
    "modelsRestaurant::Location::VersionCreatedAt": {
      "id": "models.restaurant/location/version.created_at",
      "defaultMessage": "Created At"
    },
    "modelsRestaurant::Location::VersionUserDisplayName": {
      "id": "models.restaurant/location/version.user_display_name",
      "defaultMessage": "User"
    },
    "modelsRestaurant::Location::VersionEvent": {
      "id": "models.restaurant/location/version.event",
      "defaultMessage": "Event"
    },
    "modelsRestaurant::Location::VersionObjectChanges": {
      "id": "models.restaurant/location/version.object_changes",
      "defaultMessage": "Changes"
    },
    "modelsRestaurant::SocialHandleSelf": {
      "id": "models.restaurant/social_handle.self",
      "defaultMessage": "Social Handle"
    },
    "modelsRestaurant::SocialHandleUrl": {
      "id": "models.restaurant/social_handle.url",
      "defaultMessage": "URL"
    },
    "modelsRestaurant::MemberInviteSelf": {
      "id": "models.restaurant/member_invite.self",
      "defaultMessage": "Invite"
    },
    "modelsRestaurant::MemberInviteEmail": {
      "id": "models.restaurant/member_invite.email",
      "defaultMessage": "Email"
    },
    "modelsRestaurant::MemberInviteMemberLevel": {
      "id": "models.restaurant/member_invite.member_level",
      "defaultMessage": "Access Level"
    },
    "modelsRestaurant::MemberInviteMemberLevelTooltip": {
      "id": "models.restaurant/member_invite.member_level_tooltip",
      "defaultMessage": "Limited team members cannot access most settings, and must be granted access to specific features or locations"
    },
    "modelsRestaurant::MemberInviteCanManageAnnouncements": {
      "id": "models.restaurant/member_invite.can_manage_announcements",
      "defaultMessage": "Manage Announcements"
    },
    "modelsRestaurant::MemberInviteCanManageAnswering": {
      "id": "models.restaurant/member_invite.can_manage_answering",
      "defaultMessage": "Manage AI Answering"
    },
    "modelsRestaurant::MemberInviteCanManageCustomFiles": {
      "id": "models.restaurant/member_invite.can_manage_custom_files",
      "defaultMessage": "Manage Media"
    },
    "modelsRestaurant::MemberInviteCanManageDishes": {
      "id": "models.restaurant/member_invite.can_manage_dishes",
      "defaultMessage": "Manage All Menus"
    },
    "modelsRestaurant::MemberInviteCanManageGiftCards": {
      "id": "models.restaurant/member_invite.can_manage_gift_cards",
      "defaultMessage": "Manage Gift Cards"
    },
    "modelsRestaurant::MemberInviteCanManageItemList": {
      "id": "models.restaurant/member_invite.can_manage_item_list",
      "defaultMessage": "Manage Item List"
    },
    "modelsRestaurant::MemberInviteCanManageMessages": {
      "id": "models.restaurant/member_invite.can_manage_messages",
      "defaultMessage": "Manage Messages"
    },
    "modelsRestaurant::MemberInviteCanManageOffers": {
      "id": "models.restaurant/member_invite.can_manage_offers",
      "defaultMessage": "Manage Offers"
    },
    "modelsRestaurant::MemberInviteCanManageOrders": {
      "id": "models.restaurant/member_invite.can_manage_orders",
      "defaultMessage": "Manage All Orders"
    },
    "modelsRestaurant::MemberInviteCanManageReviews": {
      "id": "models.restaurant/member_invite.can_manage_reviews",
      "defaultMessage": "Manage All Reviews"
    },
    "modelsRestaurant::MemberInviteCanManageSocialPosts": {
      "id": "models.restaurant/member_invite.can_manage_social_posts",
      "defaultMessage": "Manage Social Posts"
    },
    "modelsRestaurant::MemberInviteCanManageThemes": {
      "id": "models.restaurant/member_invite.can_manage_themes",
      "defaultMessage": "Manage Themes"
    },
    "modelsRestaurant::MemberInviteCanManageWaitlists": {
      "id": "models.restaurant/member_invite.can_manage_waitlists",
      "defaultMessage": "Manage All Waitlists"
    },
    "modelsRestaurant::MemberInviteCanManageReservations": {
      "id": "models.restaurant/member_invite.can_manage_reservations",
      "defaultMessage": "Manage All Reservations"
    },
    "modelsRestaurant::MemberInviteExpiresAt": {
      "id": "models.restaurant/member_invite.expires_at",
      "defaultMessage": "Expires"
    },
    "modelsRestaurant::MemberInviteReceivePerformanceSnapshotNotifications": {
      "id": "models.restaurant/member_invite.receive_performance_snapshot_notifications",
      "defaultMessage": "Receive Weekly Performance Snapshot Notifications"
    },
    "modelsRestaurant::MemberInviteReceiveReviewNotifications": {
      "id": "models.restaurant/member_invite.receive_review_notifications",
      "defaultMessage": "Receive New Review Notifications"
    },
    "modelsRestaurant::MemberInviteReceiveAiEmailNotifications": {
      "id": "models.restaurant/member_invite.receive_aim_email_notifications",
      "defaultMessage": "Receive AI Marketing Email Notifications"
    },
    "modelsRestaurant::MemberInviteReceiveFormNotifications": {
      "id": "models.restaurant/member_invite.receive_form_notifications",
      "defaultMessage": "Receive All Form Notifications"
    },
    "modelsRestaurant::MemberInviteStatus": {
      "id": "models.restaurant/member_invite.status",
      "defaultMessage": "Status"
    },
    "modelsRestaurant::NotFoundSelf": {
      "id": "models.restaurant/not_found.self",
      "defaultMessage": "404"
    },
    "modelsRestaurant::NotFoundCreatedAt": {
      "id": "models.restaurant/not_found.created_at",
      "defaultMessage": "First Seen"
    },
    "modelsRestaurant::NotFoundLastOccurredAt": {
      "id": "models.restaurant/not_found.last_occurred_at",
      "defaultMessage": "Last Seen"
    },
    "modelsRestaurant::NotFoundOccurrenceCount": {
      "id": "models.restaurant/not_found.occurrence_count",
      "defaultMessage": "Count"
    },
    "modelsRestaurant::NotFoundPath": {
      "id": "models.restaurant/not_found.path",
      "defaultMessage": "Path"
    },
    "modelsRestaurant::OpenTableSyncClientId": {
      "id": "models.restaurant/open_table_sync_client.client_id",
      "defaultMessage": "Open Table Sync Client Id"
    },
    "modelsRestaurant::OpenTableSyncClientSecret": {
      "id": "models.restaurant/open_table_sync_client.client_secret",
      "defaultMessage": "Open Table Sync Client Secret"
    },
    "modelsRestaurant::SecondaryDomainDomain": {
      "id": "models.restaurant/secondary_domain.domain",
      "defaultMessage": "Secondary Domain"
    },
    "modelsRestaurant::ShowNavLocation": {
      "id": "models.restaurant/show_nav_location",
      "defaultMessage": "Show location address & phone"
    },
    "modelsRestaurant::NavLinkSelf": {
      "id": "models.restaurant/nav_link.self",
      "defaultMessage": "Link"
    },
    "modelsRestaurant::NavLinkCustomHref": {
      "id": "models.restaurant/nav_link.custom_href",
      "defaultMessage": "URL"
    },
    "modelsRestaurant::NavLinkIsExternal": {
      "id": "models.restaurant/nav_link.is_external",
      "defaultMessage": "Open New Tab"
    },
    "modelsRestaurant::NavLinkIsFeatured": {
      "id": "models.restaurant/nav_link.is_featured",
      "defaultMessage": "Featured"
    },
    "modelsRestaurant::NavLinkLinkType": {
      "id": "models.restaurant/nav_link.link_type",
      "defaultMessage": "Link URL"
    },
    "modelsRestaurant::NavLinkName": {
      "id": "models.restaurant/nav_link.name",
      "defaultMessage": "Link Display Text"
    },
    "modelsRestaurant::NavLinkPhoto": {
      "id": "models.restaurant/nav_link.photo",
      "defaultMessage": "Icon"
    },
    "modelsRestaurant::NavLinkPosition": {
      "id": "models.restaurant/nav_link.position",
      "defaultMessage": "Order"
    },
    "modelsRestaurant::CustomRedirectSelf": {
      "id": "models.restaurant/custom_redirect.self",
      "defaultMessage": "Page Redirect"
    },
    "modelsRestaurant::CustomRedirectFromPath": {
      "id": "models.restaurant/custom_redirect.from_path",
      "defaultMessage": "Redirect From"
    },
    "modelsRestaurant::CustomRedirectIsPermanent": {
      "id": "models.restaurant/custom_redirect.is_permanent",
      "defaultMessage": "Permanent (301)"
    },
    "modelsRestaurant::CustomRedirectToPath": {
      "id": "models.restaurant/custom_redirect.to_path",
      "defaultMessage": "To"
    },
    "modelsRestaurant::CustomRedirectToExternal": {
      "id": "models.restaurant/custom_redirect.to_external_url",
      "defaultMessage": "To"
    },
    "modelsRestaurantSelf": {
      "id": "models.restaurant.self",
      "defaultMessage": "Restaurant"
    },
    "modelsRestaurantSelves": {
      "id": "models.restaurant.selves",
      "defaultMessage": "Restaurants"
    },
    "modelsRestaurantAppleMerchantVerificationKey": {
      "id": "models.restaurant.apple_merchant_verification_key",
      "defaultMessage": "Apple Merchant Verification Key"
    },
    "modelsRestaurantAllowAnonymousPops": {
      "id": "models.restaurant.allow_anonymous_pops",
      "defaultMessage": "Anonymous Pops"
    },
    "modelsRestaurantAllowAnonymousReviews": {
      "id": "models.restaurant.allow_anonymous_reviews",
      "defaultMessage": "Anonymous Reviews"
    },
    "modelsRestaurantCompletedOnlineOrders": {
      "id": "models.restaurant.completed_online_orders",
      "defaultMessage": "Completed Orders"
    },
    "modelsRestaurantCompletedOnlineOrdersDollarAmount": {
      "id": "models.restaurant.completed_online_orders_dollar_amount",
      "defaultMessage": "Completed Orders Amount"
    },
    "modelsRestaurantCompletedOnlineOrdersWithStripe": {
      "id": "models.restaurant.completed_online_orders_with_stripe",
      "defaultMessage": "Completed Orders via Stripe"
    },
    "modelsRestaurantCompletedOnlineOrdersWithStripeDollarAmount": {
      "id": "models.restaurant.completed_online_orders_with_stripe_dollar_amount",
      "defaultMessage": "Completed Orders via Stripe Amount"
    },
    "modelsRestaurantCreatedAt": {
      "id": "models.restaurant.created_at",
      "defaultMessage": "Created Date"
    },
    "modelsRestaurantCuisineTypeId": {
      "id": "models.restaurant.cuisine_type_id",
      "defaultMessage": "Main Category"
    },
    "modelsRestaurantCustomDishTabContent": {
      "id": "models.restaurant.custom_dish_tab_content",
      "defaultMessage": "Custom Item Tab Content"
    },
    "modelsRestaurantCustomLocationContent": {
      "id": "models.restaurant.custom_location_content",
      "defaultMessage": "Custom Location Content"
    },
    "modelsRestaurantCustomItemTabHeading": {
      "id": "models.restaurant.custom_item_tab_heading",
      "defaultMessage": "Custom Item Tab"
    },
    "modelsRestaurantCustomDishTabName": {
      "id": "models.restaurant.custom_dish_tab_name",
      "defaultMessage": "Custom Item Tab Name"
    },
    "modelsRestaurantCustomFollowButton": {
      "id": "models.restaurant.custom_follow_button",
      "defaultMessage": "Follow CTA (button)"
    },
    "modelsRestaurantCustomFollowHeading": {
      "id": "models.restaurant.custom_follow_heading",
      "defaultMessage": "Follow CTA (heading)"
    },
    "modelsRestaurantCustomFollowPath": {
      "id": "models.restaurant.custom_follow_path",
      "defaultMessage": "Follow URL"
    },
    "modelsRestaurantCustomFollowSubheading": {
      "id": "models.restaurant.custom_follow_subheading",
      "defaultMessage": "Follow CTA (subheading)"
    },
    "modelsRestaurantCustomFollowCollapseSubheading": {
      "id": "models.restaurant.custom_follow_collapse_subheading",
      "defaultMessage": "Follow CTA (subheading while collapsed)"
    },
    "modelsRestaurantCustomSignUpButton": {
      "id": "models.restaurant.custom_sign_up_button",
      "defaultMessage": "Sign Up Form CTA (button)"
    },
    "modelsRestaurantCustomSignUpHeading": {
      "id": "models.restaurant.custom_sign_up_heading",
      "defaultMessage": "Sign Up Form CTA (heading)"
    },
    "modelsRestaurantCustomSignUpSubheading": {
      "id": "models.restaurant.custom_sign_up_subheading",
      "defaultMessage": "Sign Up Form CTA (subheading)"
    },
    "modelsRestaurantCustomSiteTitle": {
      "id": "models.restaurant.custom_site_title",
      "defaultMessage": "Site Title"
    },
    "modelsRestaurantDefaultMenuLayout": {
      "id": "models.restaurant.default_menu_layout",
      "defaultMessage": "Menu Layout"
    },
    "modelsRestaurantDisplayRootAnnouncements": {
      "id": "models.restaurant.display_root_announcements",
      "defaultMessage": "Display Root Announcements"
    },
    "modelsRestaurantDisplayVipPopup": {
      "id": "models.restaurant.display_vip_popup",
      "defaultMessage": "Display VIP Popup"
    },
    "modelsRestaurantDomain": {
      "id": "models.restaurant.domain",
      "defaultMessage": "Website Domain"
    },
    "modelsRestaurantEnabledMenuItemsCount": {
      "id": "models.restaurant.enabled_menu_items_count",
      "defaultMessage": "Active Menu Items"
    },
    "modelsRestaurantEnabledWaitlist": {
      "id": "models.restaurant.enabled_waitlist",
      "defaultMessage": "Waitlist"
    },
    "modelsRestaurantFavicon": {
      "id": "models.restaurant.favicon",
      "defaultMessage": "Favicon"
    },
    "modelsRestaurantFeaturedPhotosCount": {
      "id": "models.restaurant.featured_photos_count",
      "defaultMessage": "Featured Photos"
    },
    "modelsRestaurantFollowersCount": {
      "id": "models.restaurant.followers_count",
      "defaultMessage": "Followers"
    },
    "modelsRestaurantFooterLogo": {
      "id": "models.restaurant.footer_logo",
      "defaultMessage": "Footer Logo"
    },
    "modelsRestaurantGoogleAnalyticsId": {
      "id": "models.restaurant.google_analytics_id",
      "defaultMessage": "Google Analytics ID"
    },
    "modelsRestaurantFacebookPixelId": {
      "id": "models.restaurant.facebook_pixel_id",
      "defaultMessage": "Facebook Pixel ID"
    },
    "modelsRestaurantHostingType": {
      "id": "models.restaurant.hosting_type",
      "defaultMessage": "Hosting"
    },
    "modelsRestaurantAdvancedSettingsHeading": {
      "id": "models.restaurant.advanced_settings_heading",
      "defaultMessage": "Advanced"
    },
    "modelsRestaurantFeaturesOoHeading": {
      "id": "models.restaurant.features_oo_heading",
      "defaultMessage": "Online Ordering"
    },
    "modelsRestaurantTestFeaturesHeading": {
      "id": "models.restaurant.test_features_heading",
      "defaultMessage": "Test Features"
    },
    "modelsRestaurantIsFullstoryEnabled": {
      "id": "models.restaurant.is_fullstory_enabled",
      "defaultMessage": "FullStory Script"
    },
    "modelsRestaurantIsIndieEnabled": {
      "id": "models.restaurant.is_indie_enabled",
      "defaultMessage": "Use Limited Indie Features"
    },
    "modelsRestaurantIsStandaloneAnswering": {
      "id": "models.restaurant.is_standalone_answering",
      "defaultMessage": "Standalone AI Answering"
    },
    "modelsRestaurantIsErrorTrackingEnabled": {
      "id": "models.restaurant.is_error_tracking_enabled",
      "defaultMessage": "Sentry Error Reporting"
    },
    "modelsRestaurantDraftsSettings": {
      "id": "models.restaurant.drafts_settings",
      "defaultMessage": "Web Builder settings"
    },
    "modelsRestaurantDraftsToggleMessage": {
      "id": "models.restaurant.drafts_toggle_message",
      "defaultMessage": "Turning on custom page drafts will also enable the new publish flow. Meaning, changes to custom pages will require those changes to be published before they become live."
    },
    "modelsRestaurantIsSingleLocation": {
      "id": "models.restaurant.is_single_location",
      "defaultMessage": "Single Location Management"
    },
    "modelsRestaurantIsSingleLocationInfo": {
      "id": "models.restaurant.is_single_location_info",
      "defaultMessage": "Enable this setting for restaurants with only one location or those with the same menu for all locations."
    },
    "modelsRestaurantIsSslOnly": {
      "id": "models.restaurant.is_ssl_only",
      "defaultMessage": "Enforce SSL"
    },
    "modelsRestaurantLastClientLogin": {
      "id": "models.restaurant.last_client_login",
      "defaultMessage": "Last Client Login"
    },
    "modelsRestaurantMondayId": {
      "id": "models.restaurant.monday_id",
      "defaultMessage": "Monday ID"
    },
    "modelsRestaurantPhoto": {
      "id": "models.restaurant.photo",
      "defaultMessage": "Default Header Image"
    },
    "modelsRestaurantQrCardLogo": {
      "id": "models.restaurant.qr_card_logo",
      "defaultMessage": "QR Card Logo"
    },
    "modelsRestaurantShowNavSignUp": {
      "id": "models.restaurant.show_nav_sign_up",
      "defaultMessage": "Nav Sign Up Link"
    },
    "modelsRestaurantSignUpBackgroundImage": {
      "id": "models.restaurant.sign_up_background_image",
      "defaultMessage": "Sign Up Background"
    },
    "modelsRestaurantStatus": {
      "id": "models.restaurant.status",
      "defaultMessage": "Status"
    },
    "modelsRestaurantSubdomain": {
      "id": "models.restaurant.subdomain",
      "defaultMessage": "Popmenu Subdomain"
    },
    "modelsRestaurantSubmittedOnlineOrders": {
      "id": "models.restaurant.submitted_online_orders",
      "defaultMessage": "Submitted Orders"
    },
    "modelsRestaurantSubmittedOnlineOrdersDollarAmount": {
      "id": "models.restaurant.submitted_online_orders_dollar_amount",
      "defaultMessage": "Submitted Orders Amount"
    },
    "modelsRestaurantSubscribedAt": {
      "id": "models.restaurant.subscribed_at",
      "defaultMessage": "Subscribed At"
    },
    "modelsRestaurantTagline": {
      "id": "models.restaurant.tagline",
      "defaultMessage": "Restaurant Tagline"
    },
    "modelsRestaurantUseLastLocation": {
      "id": "models.restaurant.use_last_location",
      "defaultMessage": "Use Follower Last Location"
    },
    "modelsRestaurant::ServedMealSelf": {
      "id": "models.restaurant/served_meal.self",
      "defaultMessage": "Meal Option"
    },
    "modelsRestaurant::ServedMealSelves": {
      "id": "models.restaurant/served_meal.selves",
      "defaultMessage": "Meal Options"
    },
    "modelsRestaurant::ServedMealMealType": {
      "id": "models.restaurant/served_meal.meal_type",
      "defaultMessage": "Meal Option"
    },
    "modelsRestaurantDiscount": {
      "id": "models.restaurant.discount",
      "defaultMessage": "Discount"
    },
    "modelsRestaurantHasPhotography": {
      "id": "models.restaurant.has_photography",
      "defaultMessage": "Photography"
    },
    "modelsRestaurantIsBilledUpFront": {
      "id": "models.restaurant.is_billed_up_front",
      "defaultMessage": "Upfront"
    },
    "modelsRestaurantLocationCount": {
      "id": "models.restaurant.location_count",
      "defaultMessage": "Locations"
    },
    "modelsRestaurantPayment": {
      "id": "models.restaurant.payment",
      "defaultMessage": "Payment"
    },
    "modelsRestaurantProductType": {
      "id": "models.restaurant.product_type",
      "defaultMessage": "Plan"
    },
    "modelsRestaurantRecurringMonths": {
      "id": "models.restaurant.recurring_months",
      "defaultMessage": "Minimum Duration"
    },
    "modelsRestaurantSubTotal": {
      "id": "models.restaurant.sub_total",
      "defaultMessage": "Subtotal"
    },
    "modelsRestaurantTotalPrice": {
      "id": "models.restaurant.total_price",
      "defaultMessage": "Total Price"
    },
    "modelsRestaurant::DishTagSelf": {
      "id": "models.restaurant/dish_tag.self",
      "defaultMessage": "Item Tag"
    },
    "modelsRestaurant::DishTagAbbr": {
      "id": "models.restaurant/dish_tag.abbr",
      "defaultMessage": "Abbreviation"
    },
    "modelsRestaurant::DishTagColor": {
      "id": "models.restaurant/dish_tag.color",
      "defaultMessage": "Color"
    },
    "modelsRestaurant::DishTagCreatedAt": {
      "id": "models.restaurant/dish_tag.created_at",
      "defaultMessage": "Created"
    },
    "modelsRestaurant::DishTagImage": {
      "id": "models.restaurant/dish_tag.image",
      "defaultMessage": "Image"
    },
    "modelsRestaurant::DishTagImageTooltip": {
      "id": "models.restaurant/dish_tag.image_tooltip",
      "defaultMessage": "This image replaces the abbreviation and color"
    },
    "modelsRestaurant::DishTagDishesTagCount": {
      "id": "models.restaurant/dish_tag.dishes_tag_count",
      "defaultMessage": "Total Items"
    },
    "modelsRestaurant::DishTagName": {
      "id": "models.restaurant/dish_tag.name",
      "defaultMessage": "Name"
    },
    "modelsRestaurant::CustomScriptSelf": {
      "id": "models.restaurant/custom_script.self",
      "defaultMessage": "Custom Script"
    },
    "modelsRestaurant::SignUpSettingSelf": {
      "id": "models.restaurant/sign_up_setting.self",
      "defaultMessage": "Sign Up Settings"
    },
    "modelsRestaurant::SignUpSettingEnabledAnniversary": {
      "id": "models.restaurant/sign_up_setting.enabled_anniversary",
      "defaultMessage": "Wedding Anniversary Enabled"
    },
    "modelsRestaurant::SignUpSettingRequiredAnniversary": {
      "id": "models.restaurant/sign_up_setting.required_anniversary",
      "defaultMessage": "Wedding Anniversary Required"
    },
    "modelsRestaurant::SignUpSettingEnabledBirthday": {
      "id": "models.restaurant/sign_up_setting.enabled_birthday",
      "defaultMessage": "Birthday Enabled"
    },
    "modelsRestaurant::SignUpSettingRequiredBirthday": {
      "id": "models.restaurant/sign_up_setting.required_birthday",
      "defaultMessage": "Birthday Required"
    },
    "modelsRestaurant::SignUpSettingEnabledLocation": {
      "id": "models.restaurant/sign_up_setting.enabled_location",
      "defaultMessage": "Location Enabled"
    },
    "modelsRestaurant::SignUpSettingRequiredLocation": {
      "id": "models.restaurant/sign_up_setting.required_location",
      "defaultMessage": "Location Required"
    },
    "modelsRestaurant::SignUpSettingEnabledName": {
      "id": "models.restaurant/sign_up_setting.enabled_name",
      "defaultMessage": "Name Enabled"
    },
    "modelsRestaurant::SignUpSettingRequiredName": {
      "id": "models.restaurant/sign_up_setting.required_name",
      "defaultMessage": "Name Required"
    },
    "modelsRestaurant::SignUpSettingEnabledPhone": {
      "id": "models.restaurant/sign_up_setting.enabled_phone",
      "defaultMessage": "Phone Enabled"
    },
    "modelsRestaurant::SignUpSettingRequiredPhone": {
      "id": "models.restaurant/sign_up_setting.required_phone",
      "defaultMessage": "Phone Required"
    },
    "modelsRestaurant::SignUpSettingSignUpLogoEffect": {
      "id": "models.restaurant/sign_up_setting.sign_up_logo_effect",
      "defaultMessage": "Sign Up Logo Effect"
    },
    "modelsRestaurant::SignUpSettingSuleNone": {
      "id": "models.restaurant/sign_up_setting.sule_none",
      "defaultMessage": "None"
    },
    "modelsRestaurant::SignUpSettingSuleLighten": {
      "id": "models.restaurant/sign_up_setting.sule_lighten",
      "defaultMessage": "Lighten"
    },
    "modelsRestaurant::SignUpSettingSuleDarken": {
      "id": "models.restaurant/sign_up_setting.sule_darken",
      "defaultMessage": "Darken"
    },
    "modelsRestaurant::SignUpSettingUpdated": {
      "id": "models.restaurant/sign_up_setting.updated",
      "defaultMessage": "Settings Updated!"
    },
    "modelsRestaurant::NavDropdownSelf": {
      "id": "models.restaurant/nav_dropdown.self",
      "defaultMessage": "Dropdown"
    },
    "modelsRestaurant::NavDropdownName": {
      "id": "models.restaurant/nav_dropdown.name",
      "defaultMessage": "Name"
    },
    "modelsRestaurant::NavDropdownIsFeatured": {
      "id": "models.restaurant/nav_dropdown.is_featured",
      "defaultMessage": "Featured"
    },
    "modelsRestaurant::SelectedPaymentMethodSelf": {
      "id": "models.restaurant/selected_payment_method.self",
      "defaultMessage": "Payment Option"
    },
    "modelsRestaurant::SelectedPaymentMethodPaymentType": {
      "id": "models.restaurant/selected_payment_method.payment_type",
      "defaultMessage": "Payment Option"
    },
    "modelsBillingCouponCreatedAt": {
      "id": "models.billing_coupon.created_at",
      "defaultMessage": "Created"
    },
    "modelsBillingCouponDiscountType": {
      "id": "models.billing_coupon.discount_type",
      "defaultMessage": "Discount"
    },
    "modelsBillingCouponDurationType": {
      "id": "models.billing_coupon.duration_type",
      "defaultMessage": "Duration"
    },
    "modelsBillingCouponIsOnetimeCharge": {
      "id": "models.billing_coupon.is_onetime_charge",
      "defaultMessage": "Use for one-time charges"
    },
    "modelsBillingCouponIsRestricted": {
      "id": "models.billing_coupon.is_restricted",
      "defaultMessage": "Restricted"
    },
    "modelsBillingCouponIsValid": {
      "id": "models.billing_coupon.is_valid",
      "defaultMessage": "Enabled"
    },
    "modelsBillingCouponStripeId": {
      "id": "models.billing_coupon.stripe_id",
      "defaultMessage": "Stripe ID"
    },
    "modelsBillingPlanSelf": {
      "id": "models.billing_plan.self",
      "defaultMessage": "Product"
    },
    "modelsBillingPlanBillingType": {
      "id": "models.billing_plan.billing_type",
      "defaultMessage": "Billing Type"
    },
    "modelsBillingPlanCreatedAt": {
      "id": "models.billing_plan.created_at",
      "defaultMessage": "Created"
    },
    "modelsBillingPlanCurrency": {
      "id": "models.billing_plan.currency",
      "defaultMessage": "Currency"
    },
    "modelsBillingPlanDescription": {
      "id": "models.billing_plan.description",
      "defaultMessage": "Description"
    },
    "modelsBillingPlanIntervalType": {
      "id": "models.billing_plan.interval_type",
      "defaultMessage": "Billing Interval"
    },
    "modelsBillingPlanIsActive": {
      "id": "models.billing_plan.is_active",
      "defaultMessage": "Active"
    },
    "modelsBillingPlanIsRestricted": {
      "id": "models.billing_plan.is_restricted",
      "defaultMessage": "Restricted"
    },
    "modelsBillingPlanIsIndie": {
      "id": "models.billing_plan.is_indie",
      "defaultMessage": "Selected Plan for Indie"
    },
    "modelsBillingPlanName": {
      "id": "models.billing_plan.name",
      "defaultMessage": "Product Name"
    },
    "modelsBillingPlanPrice": {
      "id": "models.billing_plan.price",
      "defaultMessage": "Price"
    },
    "modelsBillingPlanStatementDescriptor": {
      "id": "models.billing_plan.statement_descriptor",
      "defaultMessage": "Statement Descriptor"
    },
    "modelsBillingPlanStripeId": {
      "id": "models.billing_plan.stripe_id",
      "defaultMessage": "Stripe ID"
    },
    "modelsNersId": {
      "id": "models.ners.id",
      "defaultMessage": "NER ID"
    },
    "modelsNersUpdatedAt": {
      "id": "models.ners.updated_at",
      "defaultMessage": "Updated DT"
    },
    "modelsNersCreatedAt": {
      "id": "models.ners.created_at",
      "defaultMessage": "Created DT"
    },
    "modelsNersSiteUrl": {
      "id": "models.ners.site_url",
      "defaultMessage": "Site Url"
    },
    "modelsNersContentUrl": {
      "id": "models.ners.content_url",
      "defaultMessage": "Content Url"
    },
    "modelsNersNerResults": {
      "id": "models.ners.ner_results",
      "defaultMessage": "NER Results"
    },
    "modelsNersOcrId": {
      "id": "models.ners.ocr_id",
      "defaultMessage": "OCR ID"
    },
    "modelsPopSelf": {
      "id": "models.pop.self",
      "defaultMessage": "Pop"
    },
    "modelsPopDishableName": {
      "id": "models.pop.dishable.name",
      "defaultMessage": "Item"
    },
    "modelsPopPopType": {
      "id": "models.pop.pop_type",
      "defaultMessage": "Pop"
    },
    "modelsPopCreatedAt": {
      "id": "models.pop.created_at",
      "defaultMessage": "Popped"
    },
    "modelsRootAnnouncementSelf": {
      "id": "models.root_announcement.self",
      "defaultMessage": "Root Announcement"
    },
    "modelsRootAnnouncementContent": {
      "id": "models.root_announcement.content",
      "defaultMessage": "Banner Content"
    },
    "modelsRootAnnouncementCountry": {
      "id": "models.root_announcement.country",
      "defaultMessage": "Country"
    },
    "modelsRootAnnouncementCreatedAt": {
      "id": "models.root_announcement.created_at",
      "defaultMessage": "Created"
    },
    "modelsRootAnnouncementIsEnabled": {
      "id": "models.root_announcement.is_enabled",
      "defaultMessage": "Enabled"
    },
    "modelsRootAnnouncementLinkUrl": {
      "id": "models.root_announcement.link_url",
      "defaultMessage": "Banner Link URL"
    },
    "modelsRootAnnouncementReviewContent": {
      "id": "models.root_announcement.review_content",
      "defaultMessage": "Reviews Content"
    },
    "modelsGooglePostIsProcessed": {
      "id": "models.google_post.is_processed",
      "defaultMessage": "Processed"
    },
    "modelsGooglePostScheduledAt": {
      "id": "models.google_post.scheduled_at",
      "defaultMessage": "Scheduled At"
    },
    "modelsGooglePostSelf": {
      "id": "models.google_post.self",
      "defaultMessage": "Google Post"
    },
    "modelsGooglePostSummary": {
      "id": "models.google_post.summary",
      "defaultMessage": "Description"
    },
    "modelsGooglePostTitle": {
      "id": "models.google_post.title",
      "defaultMessage": "Title"
    },
    "modelsGooglePostTopicType": {
      "id": "models.google_post.topic_type",
      "defaultMessage": "Post Type"
    },
    "modelsBlogPostSelf": {
      "id": "models.blog_post.self",
      "defaultMessage": "Blog Post"
    },
    "modelsRootGroup::MemberSelf": {
      "id": "models.root_group/member.self",
      "defaultMessage": "Restaurant"
    },
    "modelsRootGroup::MemberFirstGoogleAnalyticsReportDate": {
      "id": "models.root_group/member.first_google_analytics_report_date",
      "defaultMessage": "Status"
    },
    "modelsRootGroup::MemberGoals": {
      "id": "models.root_group/member.goals",
      "defaultMessage": "Goals"
    },
    "modelsRootGroup::MemberLastGooglePostAt": {
      "id": "models.root_group/member.last_google_post_at",
      "defaultMessage": "Last Google Post"
    },
    "modelsRootGroup::MemberLastMessageAt": {
      "id": "models.root_group/member.last_message_at",
      "defaultMessage": "Last Message"
    },
    "modelsRootGroup::MemberLastSocialPostAt": {
      "id": "models.root_group/member.last_social_post_at",
      "defaultMessage": "Last Social Post"
    },
    "modelsRootGroup::MemberName": {
      "id": "models.root_group/member.name",
      "defaultMessage": "Restaurant"
    },
    "modelsRootGroup::MemberRestaurantId": {
      "id": "models.root_group/member.restaurant_id",
      "defaultMessage": "Restaurant"
    },
    "modelsRootGroup::MemberTotalClicks": {
      "id": "models.root_group/member.total_clicks",
      "defaultMessage": "Ad Clicks"
    },
    "modelsRootGroup::MemberTotalGooglePosts": {
      "id": "models.root_group/member.total_google_posts",
      "defaultMessage": "Google Posts"
    },
    "modelsRootGroup::MemberTotalMessages": {
      "id": "models.root_group/member.total_messages",
      "defaultMessage": "Messages"
    },
    "modelsRootGroup::MemberTotalSocialPosts": {
      "id": "models.root_group/member.total_social_posts",
      "defaultMessage": "Social Posts"
    },
    "modelsRootGroup::AggregateReportEndAt": {
      "id": "models.root_group/aggregate_report.end_at",
      "defaultMessage": "End Date"
    },
    "modelsRootGroup::AggregateReportGroupBy": {
      "id": "models.root_group/aggregate_report.group_by",
      "defaultMessage": "Grouped By"
    },
    "modelsRootGroup::AggregateReportStartAt": {
      "id": "models.root_group/aggregate_report.start_at",
      "defaultMessage": "Start Date"
    },
    "modelsRootGroupSelf": {
      "id": "models.root_group.self",
      "defaultMessage": "Root Segment"
    },
    "modelsRootGroupCreatedAt": {
      "id": "models.root_group.created_at",
      "defaultMessage": "Created"
    },
    "modelsRootGroupFollowersCount": {
      "id": "models.root_group.followers_count",
      "defaultMessage": "Followers"
    },
    "modelsRootGroupGroupType": {
      "id": "models.root_group.group_type",
      "defaultMessage": "Segment Type"
    },
    "modelsRootGroupMembersCount": {
      "id": "models.root_group.members_count",
      "defaultMessage": "Restaurants"
    },
    "modelsRootGroupName": {
      "id": "models.root_group.name",
      "defaultMessage": "Name"
    },
    "modelsRootExtraGroupSelf": {
      "id": "models.root_extra_group.self",
      "defaultMessage": "Modifier Group Template"
    },
    "modelsRootExtraGroupName": {
      "id": "models.root_extra_group.name",
      "defaultMessage": "Name"
    },
    "modelsMessageCampaignClickRate": {
      "id": "models.message_campaign.click_rate",
      "defaultMessage": "Click Rate"
    },
    "modelsMessageCampaignMessagesCount": {
      "id": "models.message_campaign.messages_count",
      "defaultMessage": "Messages"
    },
    "modelsMessageCampaignName": {
      "id": "models.message_campaign.name",
      "defaultMessage": "Smart Message"
    },
    "modelsMessageCampaignOpenRate": {
      "id": "models.message_campaign.open_rate",
      "defaultMessage": "Open Rate"
    },
    "modelsCustomPage::SectionArticleTitle": {
      "id": "models.custom_page/section.article_title",
      "defaultMessage": "Title"
    },
    "modelsCustomPage::SectionArticleUrl": {
      "id": "models.custom_page/section.article_url",
      "defaultMessage": "URL"
    },
    "modelsCustomPage::SectionArticlePublishedAt": {
      "id": "models.custom_page/section.article_published_at",
      "defaultMessage": "Published Date"
    },
    "modelsCustomPage::SectionArticlePublisherLogo": {
      "id": "models.custom_page/section.article_publisher_logo",
      "defaultMessage": "Logo"
    },
    "modelsCustomPage::SectionArticleAddArticle": {
      "id": "models.custom_page/section.article_add_article",
      "defaultMessage": "Add Article"
    },
    "modelsCustomPage::SectionArticleEditArticle": {
      "id": "models.custom_page/section.article_edit_article",
      "defaultMessage": "Edit Article"
    },
    "modelsCustomPage::SectionAboutContent": {
      "id": "models.custom_page/section.about_content",
      "defaultMessage": "Content"
    },
    "modelsCustomPage::SectionAboutContentMaxWidth": {
      "id": "models.custom_page/section.about_content_max_width",
      "defaultMessage": "Content Max Width"
    },
    "modelsCustomPage::SectionAboutHeading": {
      "id": "models.custom_page/section.about_heading",
      "defaultMessage": "Heading"
    },
    "modelsCustomPage::SectionAboutHeadingParagraph": {
      "id": "models.custom_page/section.about_heading_paragraph",
      "defaultMessage": "Paragraph"
    },
    "modelsCustomPage::SectionAboutPadding": {
      "id": "models.custom_page/section.about_padding",
      "defaultMessage": "Padding"
    },
    "modelsCustomPage::SectionAboutPhoto": {
      "id": "models.custom_page/section.about_photo",
      "defaultMessage": "Photo (optional)"
    },
    "modelsCustomPage::SectionAboutPhotoAlt": {
      "id": "models.custom_page/section.about_photo_alt",
      "defaultMessage": "Photo Description"
    },
    "modelsCustomPage::SectionTextBoxLinkToggle": {
      "id": "models.custom_page/section.text_box_link_toggle",
      "defaultMessage": "Display button"
    },
    "modelsCustomPage::SectionAboutFontAlignment": {
      "id": "models.custom_page/section.about_font_alignment",
      "defaultMessage": "Font Alignment"
    },
    "modelsCustomPage::SectionAboutPhotoPosition": {
      "id": "models.custom_page/section.about_photo_position",
      "defaultMessage": "Photo Position"
    },
    "modelsCustomPage::SectionAboutFontColor": {
      "id": "models.custom_page/section.about_font_color",
      "defaultMessage": "Font Color"
    },
    "modelsCustomPage::SectionAboutLinkText": {
      "id": "models.custom_page/section.about_link_text",
      "defaultMessage": "Link Display Text"
    },
    "modelsCustomPage::SectionAboutLinkTargetType": {
      "id": "models.custom_page/section.about_link_target_type",
      "defaultMessage": "Link Target Type"
    },
    "modelsCustomPage::SectionAboutLinkTarget": {
      "id": "models.custom_page/section.about_link_target",
      "defaultMessage": "Link Target"
    },
    "modelsCustomPage::SectionAboutLinkAlignment": {
      "id": "models.custom_page/section.about_link_alignment",
      "defaultMessage": "Link Alignment"
    },
    "modelsCustomPage::SectionAboutLinkUrl": {
      "id": "models.custom_page/section.about_link_url",
      "defaultMessage": "Link URL"
    },
    "modelsCustomPage::SectionAnnouncementFontAlignment": {
      "id": "models.custom_page/section.announcement_font_alignment",
      "defaultMessage": "Announcement Font Alignment"
    },
    "modelsCustomPage::SectionCartType": {
      "id": "models.custom_page/section.cart_type",
      "defaultMessage": "Online Ordering Menus"
    },
    "modelsCustomPage::SectionCustomEmbedHtml": {
      "id": "models.custom_page/section.custom_embed_html",
      "defaultMessage": "Embed HTML"
    },
    "modelsCustomPage::SectionCustomSectionAdvancedFormName": {
      "id": "models.custom_page/section.advanced_form_name",
      "defaultMessage": "Form name"
    },
    "modelsCustomPage::SectionCustomHtml": {
      "id": "models.custom_page/section.custom_html",
      "defaultMessage": "Custom HTML"
    },
    "modelsCustomPage::SectionCustomSectionContent": {
      "id": "models.custom_page/section.custom_section_content",
      "defaultMessage": "Content"
    },
    "modelsCustomPage::SectionCustomSectionHeading": {
      "id": "models.custom_page/section.custom_section_heading",
      "defaultMessage": "Heading"
    },
    "modelsCustomPage::SectionCustomSectionLinkUrl": {
      "id": "models.custom_page/section.custom_section_link_url",
      "defaultMessage": "Button URL"
    },
    "modelsCustomPage::SectionCustomSectionLinkTargetId": {
      "id": "models.custom_page/section.custom_section_link_target_id",
      "defaultMessage": "Link Target ID"
    },
    "modelsCustomPage::SectionCustomSectionElementId": {
      "id": "models.custom_page/section.element_id",
      "defaultMessage": "Element ID"
    },
    "modelsCustomPage::SectionCustomSectionLinkText": {
      "id": "models.custom_page/section.custom_section_link_text",
      "defaultMessage": "Button Display Text"
    },
    "modelsCustomPage::SectionCustomSectionPosition": {
      "id": "models.custom_page/section.custom_section_position",
      "defaultMessage": "Position"
    },
    "modelsCustomPage::SectionCustomSectionPhoto": {
      "id": "models.custom_page/section.custom_section_photo",
      "defaultMessage": "Photo"
    },
    "modelsCustomPage::SectionCustomSectionPhotoAlt": {
      "id": "models.custom_page/section.custom_section_photo_alt",
      "defaultMessage": "Photo Alt Text"
    },
    "modelsCustomPage::SectionCustomSectionPhotoOverlayText": {
      "id": "models.custom_page/section.custom_section_photo_overlay_text",
      "defaultMessage": "Photo/Video Overlay Text"
    },
    "modelsCustomPage::SectionCustomSectionPhotoOverlayTextAlt": {
      "id": "models.custom_page/section.custom_section_photo_overlay_text_alt",
      "defaultMessage": "Photo/Video Overlay Text Alt"
    },
    "modelsCustomPage::SectionCustomSectionVideo": {
      "id": "models.custom_page/section.custom_section_video",
      "defaultMessage": "Video"
    },
    "modelsCustomPage::SectionCustomVideo": {
      "id": "models.custom_page/section.custom_video",
      "defaultMessage": "Video (Max: 300MB)"
    },
    "modelsCustomPage::SectionCustomVideoContent": {
      "id": "models.custom_page/section.custom_video_content",
      "defaultMessage": "Content (optional)"
    },
    "modelsCustomPage::SectionCustomVideoHeading": {
      "id": "models.custom_page/section.custom_video_heading",
      "defaultMessage": "Heading (optional)"
    },
    "modelsCustomPage::SectionDefaultZoomLevel": {
      "id": "models.custom_page/section.default_zoom_level",
      "defaultMessage": "Zoom Level"
    },
    "modelsCustomPage::SectionEcwidShopId": {
      "id": "models.custom_page/section.ecwid_shop_id",
      "defaultMessage": "Ecwid Shop ID"
    },
    "modelsCustomPage::SectionFacebookPageId": {
      "id": "models.custom_page/section.facebook_page_id",
      "defaultMessage": "Facebook Page ID"
    },
    "modelsCustomPage::SectionFoodtecApiToken": {
      "id": "models.custom_page/section.foodtec_api_token",
      "defaultMessage": "Foodtec API Token"
    },
    "modelsCustomPage::SectionFormsiteKeyUrl": {
      "id": "models.custom_page/section.formsite_key_url",
      "defaultMessage": "Formsite Key URL"
    },
    "modelsCustomPage::SectionGalleryType": {
      "id": "models.custom_page/section.gallery_type",
      "defaultMessage": "Gallery Layout"
    },
    "modelsCustomPage::SectionGalleryColumn": {
      "id": "models.custom_page/section.gallery_column",
      "defaultMessage": "Gallery Columns"
    },
    "modelsCustomPage::SectionGalleryPadding": {
      "id": "models.custom_page/section.gallery_padding",
      "defaultMessage": "Gallery Padding"
    },
    "modelsCustomPage::SectionGiftCardImageDescription": {
      "id": "models.custom_page/section.gift_card.description",
      "defaultMessage": "Photo Description"
    },
    "modelsCustomPage::SectionHasDynamicHeight": {
      "id": "models.custom_page/section.has_dynamic_height",
      "defaultMessage": "Dynamic Height"
    },
    "modelsCustomPage::SectionLocationContent": {
      "id": "models.custom_page/section.location_content",
      "defaultMessage": "Content"
    },
    "modelsCustomPage::SectionLocationSectionHeadings": {
      "id": "models.custom_page/section.location_section_headings",
      "defaultMessage": "Location Headings"
    },
    "modelsCustomPage::SectionLocationSectionLayout": {
      "id": "models.custom_page/section.location_section_layout",
      "defaultMessage": "Location Layout"
    },
    "modelsCustomPage::SectionLogoAltText": {
      "id": "models.custom_page/section.logo_alt_text",
      "defaultMessage": "Logo Description"
    },
    "modelsCustomPage::SectionMapTheme": {
      "id": "models.custom_page/section.map_theme",
      "defaultMessage": "Map Theme"
    },
    "modelsCustomPage::SectionMcdtAccordion": {
      "id": "models.custom_page/section.mcdt_accordion",
      "defaultMessage": "Accordion"
    },
    "modelsCustomPage::SectionMcdtMultiColumn": {
      "id": "models.custom_page/section.mcdt_multi_column",
      "defaultMessage": "Multi-Column"
    },
    "modelsCustomPage::SectionMenuToPage": {
      "id": "models.custom_page/section.menu_to_page",
      "defaultMessage": "Menu Pages"
    },
    "modelsCustomPage::SectionMultipleColumnType": {
      "id": "models.custom_page/section.multiple_column_type",
      "defaultMessage": "Split Column Content By"
    },
    "modelsCustomPage::SectionMultipleDisplayType": {
      "id": "models.custom_page/section.multiple_display_type",
      "defaultMessage": "Display Type"
    },
    "modelsCustomPage::SectionObjectFit": {
      "id": "models.custom_page/section.object_fit",
      "defaultMessage": "Photo Crop"
    },
    "modelsCustomPage::SectionCover": {
      "id": "models.custom_page/section.cover",
      "defaultMessage": "Center"
    },
    "modelsCustomPage::SectionContain": {
      "id": "models.custom_page/section.contain",
      "defaultMessage": "Show Full Image"
    },
    "modelsCustomPage::SectionPowrInstagramFeedId": {
      "id": "models.custom_page/section.powr_instagram_feed_id",
      "defaultMessage": "Powr Instagram Feed ID"
    },
    "modelsCustomPage::SectionYoutubeVideoUrl": {
      "id": "models.custom_page/section.youtube_video_url",
      "defaultMessage": "Youtube Video Embed URL"
    },
    "modelsCustomPage::SectionTripleseatFormId": {
      "id": "models.custom_page/section.tripleseat_form_id",
      "defaultMessage": "Tripleseat Form ID"
    },
    "modelsCustomPage::SectionTripleseatFormKey": {
      "id": "models.custom_page/section.tripleseat_form_key",
      "defaultMessage": "Tripleseat Form Key"
    },
    "modelsCustomPage::SectionConnectedInstagramPhotoGridTitle": {
      "id": "models.custom_page/section.connected_instagram_photo_grid_title",
      "defaultMessage": "Facebook & Instagram is Connected"
    },
    "modelsCustomPage::SectionConnectedInstagramPhotoGridSelectAccount": {
      "id": "models.custom_page/section.connected_instagram_photo_grid_select_account",
      "defaultMessage": "Select an Instagram account for the photo grid."
    },
    "modelsCustomPage::SectionConnectedInstagramPhotoGridFeed": {
      "id": "models.custom_page/section.connected_instagram_photo_grid_feed",
      "defaultMessage": "Instagram Feed"
    },
    "modelsCustomPage::SectionDisconnectedInstagramPhotoGridTitle": {
      "id": "models.custom_page/section.disconnected_instagram_photo_grid_title",
      "defaultMessage": "Connect Facebook with Instagram Access"
    },
    "modelsCustomPage::SectionDisconnectedInstagramPhotoGridWhyConnectFacebook": {
      "id": "models.custom_page/section.disconnected_instagram_photo_grid_why_connect_facebook",
      "defaultMessage": "Why connect to Facebook?"
    },
    "modelsCustomPage::SectionDisconnectedInstagramPhotoGridWhyConnectFacebookSummary": {
      "id": "models.custom_page/section.disconnected_instagram_photo_grid_why_connect_facebook_summary",
      "defaultMessage": "Facebook requires Instagram business accounts to be connected to your Facebook Business Page. In order for this to work, you need to connect to Facebook."
    },
    "modelsCustomPage::SectionDisconnectedInstagramPhotoGridConnectToFacebook": {
      "id": "models.custom_page/section.disconnected_instagram_photo_grid_connect_to_facebook",
      "defaultMessage": "Connect To Facebook"
    },
    "modelsCustomPage::SectionRemoveSection": {
      "id": "models.custom_page/section.remove_section",
      "defaultMessage": "Remove Section"
    },
    "modelsCustomPage::SectionSettings": {
      "id": "models.custom_page/section.settings",
      "defaultMessage": "Section Settings"
    },
    "modelsCustomPage::Section::FearturedItemsSection": {
      "id": "models.custom_page/section.featured_items",
      "defaultMessage": "Featured Items"
    },
    "modelsCustomPage::Section::FearturedItemsSectionAddDescription": {
      "id": "models.custom_page/section.featured_items_add_description",
      "defaultMessage": "Add items you'd like to feature."
    },
    "modelsCustomPage::Section::FearturedItemsSectionAddItems": {
      "id": "models.custom_page/section.featured_items_add_items",
      "defaultMessage": "Add items"
    },
    "modelsCustomPage::Section::FearturedItemsLayout": {
      "id": "models.custom_page/section.featured_items_layout",
      "defaultMessage": "Layout"
    },
    "modelsCustomPage::Section::FearturedItemsLayoutGrid": {
      "id": "models.custom_page/section.featured_items_layout.grid",
      "defaultMessage": "Grid"
    },
    "modelsCustomPage::Section::FearturedItemsLayoutCarousel": {
      "id": "models.custom_page/section.featured_items_layout.carousel",
      "defaultMessage": "Carousel"
    },
    "modelsCustomPage::Section::FearturedItemsLayoutSlide": {
      "id": "models.custom_page/section.featured_items_layout.slide",
      "defaultMessage": "Slide"
    },
    "modelsCustomForm::EntrySelf": {
      "id": "models.custom_form/entry.self",
      "defaultMessage": "Form Response"
    },
    "modelsCustomForm::EntryAttachment": {
      "id": "models.custom_form/entry.attachment",
      "defaultMessage": "Doc"
    },
    "modelsCustomForm::EntryAttachmentUrl": {
      "id": "models.custom_form/entry.attachment_url",
      "defaultMessage": "Doc"
    },
    "modelsCustomForm::EntryCreatedAt": {
      "id": "models.custom_form/entry.created_at",
      "defaultMessage": "Sent"
    },
    "modelsCustomForm::Entry::CustomFormLabel": {
      "id": "models.custom_form/entry.custom_form.label",
      "defaultMessage": "Form"
    },
    "modelsCustomForm::Entry::LocationName": {
      "id": "models.custom_form/entry.location.name",
      "defaultMessage": "Location"
    },
    "modelsCustomForm::EntryEmail": {
      "id": "models.custom_form/entry.email",
      "defaultMessage": "Email"
    },
    "modelsCustomForm::EntryLabel": {
      "id": "models.custom_form/entry.label",
      "defaultMessage": "Form"
    },
    "modelsCustomForm::EntryLocationId": {
      "id": "models.custom_form/entry.location_id",
      "defaultMessage": "Location"
    },
    "modelsCustomForm::EntryMessage": {
      "id": "models.custom_form/entry.message",
      "defaultMessage": "Message"
    },
    "modelsCustomForm::EntryName": {
      "id": "models.custom_form/entry.name",
      "defaultMessage": "Name"
    },
    "modelsCustomForm::EntryPhone": {
      "id": "models.custom_form/entry.phone",
      "defaultMessage": "Phone"
    },
    "modelsCustomForm::EntrySelectedDateTime": {
      "id": "models.custom_form/entry.selected_date_time",
      "defaultMessage": "Date"
    },
    "modelsCustomFormSelf": {
      "id": "models.custom_form.self",
      "defaultMessage": "Form"
    },
    "modelsCustomFormAllowAttachments": {
      "id": "models.custom_form.allow_attachments",
      "defaultMessage": "Accept attachments"
    },
    "modelsCustomFormDescription": {
      "id": "models.custom_form.description",
      "defaultMessage": "Form Description"
    },
    "modelsCustomFormDisplayGeneralFeedback": {
      "id": "models.custom_form.display_general_feedback",
      "defaultMessage": "Display general feedback option"
    },
    "modelsCustomFormEmailCsv": {
      "id": "models.custom_form.email_csv",
      "defaultMessage": "Notification Emails (CSV)"
    },
    "modelsCustomFormHeading": {
      "id": "models.custom_form.heading",
      "defaultMessage": "Form Heading"
    },
    "modelsCustomFormLabel": {
      "id": "models.custom_form.label",
      "defaultMessage": "Form Label (for internal use)"
    },
    "modelsCustomFormShowLocationDropdown": {
      "id": "models.custom_form.show_location_dropdown",
      "defaultMessage": "Display location dropdown"
    },
    "modelsCustomFormShowDateField": {
      "id": "models.custom_form.show_date_field",
      "defaultMessage": "Show date selection"
    },
    "modelsCustomFormIsPhoneRequired": {
      "id": "models.custom_form.is_phone_required",
      "defaultMessage": "Require phone number"
    },
    "modelsMenuItemCart::SelectedMenuItemSelf": {
      "id": "models.menu_item_cart/selected_menu_item.self",
      "defaultMessage": "Item"
    },
    "modelsMenuItemCartSelf": {
      "id": "models.menu_item_cart.self",
      "defaultMessage": "Order"
    },
    "modelsMenuItemCartEmail": {
      "id": "models.menu_item_cart.email",
      "defaultMessage": "Email"
    },
    "modelsMenuItemCartLocation": {
      "id": "models.menu_item_cart.location",
      "defaultMessage": "Location"
    },
    "modelsMenuItemCartStatus": {
      "id": "models.menu_item_cart.status",
      "defaultMessage": "Status"
    },
    "modelsMenuItemCartSubmittedAt": {
      "id": "models.menu_item_cart.submitted_at",
      "defaultMessage": "Order Placed"
    },
    "modelsMenuItemCartCancelCloseTab": {
      "id": "models.menu_item_cart.cancel_close_tab",
      "defaultMessage": "Cancel"
    },
    "modelsMenuItemCartCloseTabConfirm": {
      "id": "models.menu_item_cart.close_tab_confirm",
      "defaultMessage": "Yes, close tab"
    },
    "modelsMyBusinessAction": {
      "id": "models.my_business.action",
      "defaultMessage": "Action"
    },
    "modelsMyBusinessDisabled": {
      "id": "models.my_business.disabled",
      "defaultMessage": "Disabled"
    },
    "modelsMyBusinessDuplicate": {
      "id": "models.my_business.duplicate",
      "defaultMessage": "Duplicate location"
    },
    "modelsMyBusinessHasPendingEdits": {
      "id": "models.my_business.has_pending_edits",
      "defaultMessage": "Pending Edits"
    },
    "modelsMyBusinessLocationName": {
      "id": "models.my_business.location_name",
      "defaultMessage": "Location"
    },
    "modelsMyBusinessLocationState": {
      "id": "models.my_business.location_state",
      "defaultMessage": "Status"
    },
    "modelsMyBusinessNoLocations": {
      "id": "models.my_business.no_locations",
      "defaultMessage": "No locations found."
    },
    "modelsMyBusinessStatus": {
      "id": "models.my_business.status",
      "defaultMessage": "Status"
    },
    "modelsMyBusinessSuspended": {
      "id": "models.my_business.suspended",
      "defaultMessage": "Location suspended"
    },
    "modelsMyBusinessUnverified": {
      "id": "models.my_business.unverified",
      "defaultMessage": "Unverified"
    },
    "modelsMyBusinessVerificationRequired": {
      "id": "models.my_business.verification_required",
      "defaultMessage": "Verification Required"
    },
    "modelsMyBusinessVerified": {
      "id": "models.my_business.verified",
      "defaultMessage": "Verified"
    },
    "modelsPrintLayout::TextSectionSelf": {
      "id": "models.print_layout/text_section.self",
      "defaultMessage": "Text Layer"
    },
    "modelsPrintLayout::TextSectionContent": {
      "id": "models.print_layout/text_section.content",
      "defaultMessage": "Custom Text"
    },
    "modelsPrintLayout::TextSectionFontColor": {
      "id": "models.print_layout/text_section.font_color",
      "defaultMessage": "Font Color"
    },
    "modelsPrintLayout::TextSectionFontId": {
      "id": "models.print_layout/text_section.font_id",
      "defaultMessage": "Font"
    },
    "modelsPrintLayout::TextSectionFontSize": {
      "id": "models.print_layout/text_section.font_size",
      "defaultMessage": "Font Size"
    },
    "modelsPrintLayout::TextSectionIsAutoFontSize": {
      "id": "models.print_layout/text_section.is_auto_font_size",
      "defaultMessage": "Auto Font Size"
    },
    "modelsPrintLayout::TextSectionTextAlignment": {
      "id": "models.print_layout/text_section.text_alignment",
      "defaultMessage": "Alignment"
    },
    "modelsPrintLayout::TextSectionTitle": {
      "id": "models.print_layout/text_section.title",
      "defaultMessage": "Text"
    },
    "modelsPrintLayout::DishTagKeySectionSelf": {
      "id": "models.print_layout/dish_tag_key_section.self",
      "defaultMessage": "Item Tags Key Layer"
    },
    "modelsPrintLayout::DishTagKeySectionTitle": {
      "id": "models.print_layout/dish_tag_key_section.title",
      "defaultMessage": "Item Tags"
    },
    "modelsPrintLayoutSelf": {
      "id": "models.print_layout.self",
      "defaultMessage": "Page Layout"
    },
    "modelsPrintLayoutBackgroundImage": {
      "id": "models.print_layout.background_image",
      "defaultMessage": "Background Image"
    },
    "modelsPrintLayoutBackgroundImageOpacity": {
      "id": "models.print_layout.background_image_opacity",
      "defaultMessage": "Background Image Opacity"
    },
    "modelsPrintLayoutBodyFontColor": {
      "id": "models.print_layout.body_font_color",
      "defaultMessage": "Body Font Color"
    },
    "modelsPrintLayoutBodyFontId": {
      "id": "models.print_layout.body_font_id",
      "defaultMessage": "Body Font"
    },
    "modelsPrintLayoutHeadingFontColor": {
      "id": "models.print_layout.heading_font_color",
      "defaultMessage": "Heading Font Color"
    },
    "modelsPrintLayoutHeadingFontId": {
      "id": "models.print_layout.heading_font_id",
      "defaultMessage": "Heading Font"
    },
    "modelsPrintLayoutHeight": {
      "id": "models.print_layout.height",
      "defaultMessage": "Paper Height (in.)"
    },
    "modelsPrintLayoutMenuItemDishTagAlignment": {
      "id": "models.print_layout.menu_item_item_tag_alignment",
      "defaultMessage": "Item Tags"
    },
    "modelsPrintLayoutMenuItemDescriptionStyle": {
      "id": "models.print_layout.menu_item_description_style",
      "defaultMessage": "Item Description Style"
    },
    "modelsPrintLayoutMenuItemNameFontColor": {
      "id": "models.print_layout.menu_item_name_font_color",
      "defaultMessage": "Item Name Font Color"
    },
    "modelsPrintLayoutMenuItemNameFontId": {
      "id": "models.print_layout.menu_item_name_font_id",
      "defaultMessage": "Item Name Font"
    },
    "modelsPrintLayoutMenuItemNameFontCase": {
      "id": "models.print_layout.menu_item_name_font_case",
      "defaultMessage": "Item Name Font Case"
    },
    "modelsPrintLayoutMenuItemPriceAlignment": {
      "id": "models.print_layout.menu_item_price_alignment",
      "defaultMessage": "Item Price"
    },
    "modelsPrintLayoutMenuSectionDescriptionFontColor": {
      "id": "models.print_layout.menu_section_description_font_color",
      "defaultMessage": "Section Description Font Color"
    },
    "modelsPrintLayoutMenuSectionDescriptionFontId": {
      "id": "models.print_layout.menu_section_description_font_id",
      "defaultMessage": "Section Description Font"
    },
    "modelsPrintLayoutMenuSectionNameFontColor": {
      "id": "models.print_layout.menu_section_name_font_color",
      "defaultMessage": "Section Name Font Color"
    },
    "modelsPrintLayoutMenuSectionNameFontId": {
      "id": "models.print_layout.menu_section_name_font_id",
      "defaultMessage": "Section Name Font"
    },
    "modelsPrintLayoutMenuItemSizeExtraAlignment": {
      "id": "models.print_layout.menu_item_size_extra_alignment",
      "defaultMessage": "Item Sizes/Modifiers"
    },
    "modelsPrintLayoutName": {
      "id": "models.print_layout.name",
      "defaultMessage": "Title"
    },
    "modelsPrintLayoutShowMenuItemCurrency": {
      "id": "models.print_layout.show_menu_item_currency",
      "defaultMessage": "Currency Symbol"
    },
    "modelsPrintLayoutShowMenuItemDishTagsKey": {
      "id": "models.print_layout.show_menu_item_item_tags_key",
      "defaultMessage": "Item Tags Key"
    },
    "modelsPrintLayoutWidth": {
      "id": "models.print_layout.width",
      "defaultMessage": "Paper Width (in.)"
    },
    "modelsPrintLayoutAddImage": {
      "id": "models.print_layout.add_image",
      "defaultMessage": "Add Image"
    },
    "modelsPrintLayoutAddText": {
      "id": "models.print_layout.add_text",
      "defaultMessage": "Add Text"
    },
    "modelsPrintLayoutAddMenu": {
      "id": "models.print_layout.add_menu",
      "defaultMessage": "Add Menu"
    },
    "modelsPrintLayout::ImageSectionSelf": {
      "id": "models.print_layout/image_section.self",
      "defaultMessage": "Image Layer"
    },
    "modelsPrintLayout::ImageSectionImage": {
      "id": "models.print_layout/image_section.image",
      "defaultMessage": "Custom Image"
    },
    "modelsPrintLayout::ImageSectionOpacity": {
      "id": "models.print_layout/image_section.opacity",
      "defaultMessage": "Opacity"
    },
    "modelsPrintLayout::ImageSectionTitle": {
      "id": "models.print_layout/image_section.title",
      "defaultMessage": "Images"
    },
    "modelsPrintLayout::MenuSectionBackgroundColor": {
      "id": "models.print_layout/menu_section.background_color",
      "defaultMessage": "Background Color"
    },
    "modelsPrintLayout::MenuSectionColumnCount": {
      "id": "models.print_layout/menu_section.column_count",
      "defaultMessage": "Item Columns"
    },
    "modelsPrintLayout::MenuSectionMenuSectionBorderColor": {
      "id": "models.print_layout/menu_section.menu_section_border_color",
      "defaultMessage": "Border Color"
    },
    "modelsPrintLayout::MenuSectionMenuSectionBorderStyle": {
      "id": "models.print_layout/menu_section.menu_section_border_style",
      "defaultMessage": "Border Style"
    },
    "modelsPrintLayout::MenuSectionMenuSectionBorderWidth": {
      "id": "models.print_layout/menu_section.menu_section_border_width",
      "defaultMessage": "Border Width"
    },
    "modelsPrintLayout::MenuSectionMenuSectionNameFlow": {
      "id": "models.print_layout/menu_section.menu_section_name_flow",
      "defaultMessage": "Section Name Orientation"
    },
    "modelsPrintLayout::MenuSectionPaddingTop": {
      "id": "models.print_layout/menu_section.padding_top",
      "defaultMessage": "Margin"
    },
    "modelsPrintLayout::MenuSectionTextAlignment": {
      "id": "models.print_layout/menu_section.text_alignment",
      "defaultMessage": "Alignment"
    },
    "modelsPrintLayout::MenuSectionTitle": {
      "id": "models.print_layout/menu_section.title",
      "defaultMessage": "Menu Sections"
    },
    "modelsFollowerCreatedAt": {
      "id": "models.follower.created_at",
      "defaultMessage": "Followed"
    },
    "modelsFollowerPopsCount": {
      "id": "models.follower.pops_count",
      "defaultMessage": "Pops"
    },
    "modelsFollowerReviewsCount": {
      "id": "models.follower.reviews_count",
      "defaultMessage": "Reviews"
    },
    "modelsFollowerTags": {
      "id": "models.follower.tags",
      "defaultMessage": "Tags"
    },
    "modelsFollowerByOrdersCount": {
      "id": "models.follower.menu_item_carts_count",
      "defaultMessage": "Orders"
    },
    "modelsTextMessageGroupCreatedAt": {
      "id": "models.text_message_group.created_at",
      "defaultMessage": "Created At"
    },
    "modelsTextMessageGroupIsDraft": {
      "id": "models.text_message_group.is_draft",
      "defaultMessage": "Draft"
    },
    "modelsTextMessageGroupIsScheduled": {
      "id": "models.text_message_group.is_scheduled",
      "defaultMessage": "Schedule"
    },
    "modelsTextMessageGroupLocationId": {
      "id": "models.text_message_group.location_id",
      "defaultMessage": "Location"
    },
    "modelsTextMessageGroupMessage": {
      "id": "models.text_message_group.message",
      "defaultMessage": "Message"
    },
    "modelsTextMessageGroupMessageCount": {
      "id": "models.text_message_group.message_count",
      "defaultMessage": "Messages"
    },
    "modelsTextMessageGroupPhoto": {
      "id": "models.text_message_group.photo",
      "defaultMessage": "Photo (optional)"
    },
    "modelsTextMessageGroupSent": {
      "id": "models.text_message_group.sent",
      "defaultMessage": "Sent"
    },
    "modelsTextMessageGroupProcessedAt": {
      "id": "models.text_message_group.processed_at",
      "defaultMessage": "Sent At"
    },
    "modelsTextMessageGroupScheduledAt": {
      "id": "models.text_message_group.scheduled_at",
      "defaultMessage": "Scheduled"
    },
    "modelsTextMessageGroupSelf": {
      "id": "models.text_message_group.self",
      "defaultMessage": "Text Message"
    },
    "modelsTextMessageGroupSelves": {
      "id": "models.text_message_group.selves",
      "defaultMessage": "Text messages"
    },
    "modelsTextMessageGroupUpdatedAt": {
      "id": "models.text_message_group.updated_at",
      "defaultMessage": "Updated At"
    },
    "modelsRootSiteTemplateSelf": {
      "id": "models.root_site_template.self",
      "defaultMessage": "Site Template"
    },
    "modelsRootSiteTemplateCreatedAt": {
      "id": "models.root_site_template.created_at",
      "defaultMessage": "Created"
    },
    "modelsRootSiteTemplateDescription": {
      "id": "models.root_site_template.description",
      "defaultMessage": "Description"
    },
    "modelsRootSiteTemplateIsEnabled": {
      "id": "models.root_site_template.is_enabled",
      "defaultMessage": "Enabled"
    },
    "modelsRootSiteTemplateIsThemeTemplate": {
      "id": "models.root_site_template.is_theme_template",
      "defaultMessage": "Themed"
    },
    "modelsRootSiteTemplateIsRootAccessOnly": {
      "id": "models.root_site_template.is_root_access_only",
      "defaultMessage": "Root Access Only"
    },
    "modelsRootSiteTemplateName": {
      "id": "models.root_site_template.name",
      "defaultMessage": "Name"
    },
    "modelsRootSiteTemplatePosition": {
      "id": "models.root_site_template.position",
      "defaultMessage": "Order"
    },
    "modelsRootSiteTemplateRestaurantId": {
      "id": "models.root_site_template.restaurant_id",
      "defaultMessage": "Template Restaurant"
    },
    "modelsRootSectionTemplateSelf": {
      "id": "models.root_section_template.self",
      "defaultMessage": "Section Template"
    },
    "modelsRootSectionTemplateCreatedAt": {
      "id": "models.root_section_template.created_at",
      "defaultMessage": "Created"
    },
    "modelsRootSectionTemplateDescription": {
      "id": "models.root_section_template.description",
      "defaultMessage": "Description"
    },
    "modelsRootSectionTemplateIsEnabled": {
      "id": "models.root_section_template.is_enabled",
      "defaultMessage": "Enabled"
    },
    "modelsRootSectionTemplateName": {
      "id": "models.root_section_template.name",
      "defaultMessage": "Name"
    },
    "modelsRootSectionTemplatePosition": {
      "id": "models.root_section_template.position",
      "defaultMessage": "Order"
    },
    "modelsRootSectionTemplateRestaurantId": {
      "id": "models.root_section_template.restaurant_id",
      "defaultMessage": "Template Restaurant"
    },
    "modelsRootSectionTemplateSectiontId": {
      "id": "models.root_section_template.section_id",
      "defaultMessage": "Template Section"
    },
    "modelsRootSectionTemplateThumbnail": {
      "id": "models.root_section_templates.thumbnail",
      "defaultMessage": "Thumbnail"
    },
    "modelsCustomFileSelf": {
      "id": "models.custom_file.self",
      "defaultMessage": "File"
    },
    "modelsCustomFileName": {
      "id": "models.custom_file.name",
      "defaultMessage": "File Name"
    },
    "modelsCustomFileFile": {
      "id": "models.custom_file.file",
      "defaultMessage": "File"
    },
    "modelsCustomFileCreatedAt": {
      "id": "models.custom_file.created_at",
      "defaultMessage": "Uploaded"
    },
    "modelsOfferCodeCode": {
      "id": "models.offer_code.code",
      "defaultMessage": "Offer Code"
    },
    "modelsOfferCodeRedeemedAt": {
      "id": "models.offer_code.redeemed_at",
      "defaultMessage": "Redeemed"
    },
    "modelsOfferCodeUser": {
      "id": "models.offer_code.user",
      "defaultMessage": "Follower"
    },
    "modelsMessageSelf": {
      "id": "models.message.self",
      "defaultMessage": "Message"
    },
    "modelsMessageBouncedAt": {
      "id": "models.message.bounced_at",
      "defaultMessage": "Bounced At"
    },
    "modelsMessageClickedAt": {
      "id": "models.message.clicked_at",
      "defaultMessage": "Clicked"
    },
    "modelsMessageCreatedAt": {
      "id": "models.message.created_at",
      "defaultMessage": "Created"
    },
    "modelsMessageEmail": {
      "id": "models.message.email",
      "defaultMessage": "Email"
    },
    "modelsMessageMessageContent": {
      "id": "models.message.message_content",
      "defaultMessage": "Content"
    },
    "modelsMessageMessageSubject": {
      "id": "models.message.message_subject",
      "defaultMessage": "Subject"
    },
    "modelsMessageOpenedAt": {
      "id": "models.message.opened_at",
      "defaultMessage": "Opened"
    },
    "modelsMessageSentAt": {
      "id": "models.message.sent_at",
      "defaultMessage": "Sent"
    },
    "modelsMessageUser": {
      "id": "models.message.user",
      "defaultMessage": "User"
    },
    "modelsRestaurantWebhookSelf": {
      "id": "models.restaurant/webhook.self",
      "defaultMessage": "Webhook"
    },
    "modelsUserApiTokenSelf": {
      "id": "models.user/api_token.self",
      "defaultMessage": "Token"
    },
    "modelsUserSelf": {
      "id": "models.user.self",
      "defaultMessage": "User"
    },
    "modelsUserSelves": {
      "id": "models.user.selves",
      "defaultMessage": "Users"
    },
    "modelsUserCreatedAt": {
      "id": "models.user.created_at",
      "defaultMessage": "Signed Up"
    },
    "modelsUserName": {
      "id": "models.user.name",
      "defaultMessage": "Name"
    },
    "modelsUserFollowersCount": {
      "id": "models.user.followers_count",
      "defaultMessage": "Followers"
    },
    "modelsUserLastVisitAt": {
      "id": "models.user.last_visit_at",
      "defaultMessage": "Last Seen"
    },
    "modelsUserUserRole": {
      "id": "models.user.user_role",
      "defaultMessage": "Role"
    },
    "modelsMenuImportSelf": {
      "id": "models.menu_import.self",
      "defaultMessage": "Menu Import"
    },
    "modelsMenuImportCreatedAt": {
      "id": "models.menu_import.created_at",
      "defaultMessage": "Created"
    },
    "modelsMenuImportFinishedAt": {
      "id": "models.menu_import.finished_at",
      "defaultMessage": "Finished"
    },
    "modelsMenuImportMenuItemsCount": {
      "id": "models.menu_import.menu_items_count",
      "defaultMessage": "Menu Items"
    },
    "modelsMenuImportStatus": {
      "id": "models.menu_import.status",
      "defaultMessage": "Status"
    },
    "modelsMenuImportUserDisplayName": {
      "id": "models.menu_import.user_display_name",
      "defaultMessage": "Created By"
    },
    "modelsBillingInvoiceSelf": {
      "id": "models.billing_invoice.self",
      "defaultMessage": "Invoice"
    },
    "modelsBillingInvoiceDate": {
      "id": "models.billing_invoice.date",
      "defaultMessage": "Date"
    },
    "modelsBillingInvoicePeriod": {
      "id": "models.billing_invoice.period",
      "defaultMessage": "Period"
    },
    "modelsBillingInvoiceTotal": {
      "id": "models.billing_invoice.total",
      "defaultMessage": "Amount"
    },
    "modelsMasterMessageCampaignSelf": {
      "id": "models.master_message_campaign.self",
      "defaultMessage": "Root Smart Message"
    },
    "modelsMasterMessageCampaignCanIgnoreLimit": {
      "id": "models.master_message_campaign.can_ignore_limit",
      "defaultMessage": "Ignore Limits"
    },
    "modelsMasterMessageCampaignDescription": {
      "id": "models.master_message_campaign.description",
      "defaultMessage": "Summary"
    },
    "modelsMasterMessageCampaignImage": {
      "id": "models.master_message_campaign.image",
      "defaultMessage": "Image"
    },
    "modelsMasterMessageCampaignIsDefaultEnabled": {
      "id": "models.master_message_campaign.is_default_enabled",
      "defaultMessage": "Default Smart Message"
    },
    "modelsMasterAutomatedEmailIsDefaultEnabled": {
      "id": "models.master_automated_email.is_default_enabled",
      "defaultMessage": "Default Automated Email"
    },
    "modelsMasterMessageCampaignIsDefaultIncludeFeaturedContent": {
      "id": "models.master_message_campaign.is_default_include_featured_content",
      "defaultMessage": "Default Include Featured Content"
    },
    "modelsMasterMessageCampaignIsEnabled": {
      "id": "models.master_message_campaign.is_enabled",
      "defaultMessage": "Enabled"
    },
    "modelsMasterMessageCampaignMessageSubject": {
      "id": "models.master_message_campaign.message_subject",
      "defaultMessage": "Message Subject"
    },
    "modelsMasterMessageCampaignMessageContent": {
      "id": "models.master_message_campaign.message_content",
      "defaultMessage": "Message Content"
    },
    "modelsMasterMessageCampaignMessageTeaser": {
      "id": "models.master_message_campaign.message_teaser",
      "defaultMessage": "Message Teaser (optional)"
    },
    "modelsMasterMessageCampaignName": {
      "id": "models.master_message_campaign.name",
      "defaultMessage": "Name"
    },
    "modelsMasterMessageCampaignPosition": {
      "id": "models.master_message_campaign.position",
      "defaultMessage": "Display Order"
    },
    "modelsMasterMessageCampaignPositionTooltipSmartMessage": {
      "id": "models.master_message_campaign.position.tooltip.smart_message",
      "defaultMessage": "Display order of the cards in the Smart Messages tab"
    },
    "modelsMasterMessageCampaignPositionTooltipAutomatedEmail": {
      "id": "models.master_message_campaign.position.tooltip.automated_email",
      "defaultMessage": "Display order of the cards in the Automated Emails tab"
    },
    "modelsMasterMessageCampaignPriority": {
      "id": "models.master_message_campaign.priority",
      "defaultMessage": "Priority"
    },
    "modelsMasterMessageCampaignTriggerExpirationDays": {
      "id": "models.master_message_campaign.trigger_expiration_days",
      "defaultMessage": "Trigger Expiration (days)"
    },
    "modelsMasterMessageCampaignTriggerDelayMinutes": {
      "id": "models.master_message_campaign.trigger_delay_minutes",
      "defaultMessage": "Trigger Delay (minutes)"
    },
    "modelsMasterMessageCampaignTriggerAmountSpent": {
      "id": "models.master_message_campaign.trigger_amount_spent",
      "defaultMessage": "Amount Spent"
    },
    "modelsMasterMessageCampaignTriggerNumberOfDays": {
      "id": "models.master_message_campaign.number_of_days",
      "defaultMessage": "Number Of Days"
    },
    "modelsMasterMessageCampaignTriggerOrderNumber": {
      "id": "models.master_message_campaign.trigger_number_of_orders",
      "defaultMessage": "Number of Orders"
    },
    "modelsMasterMessageCampaignTriggerType": {
      "id": "models.master_message_campaign.trigger_type",
      "defaultMessage": "Trigger"
    },
    "modelsCuisineTypeGoogleMyBusinessCategoryId": {
      "id": "models.cuisine_type.google_my_business_category_id",
      "defaultMessage": "Google Category"
    },
    "modelsCuisineTypeName": {
      "id": "models.cuisine_type.name",
      "defaultMessage": "Category Name"
    },
    "modelsCuisineTypeSelf": {
      "id": "models.cuisine_type.self",
      "defaultMessage": "Restaurant Category"
    },
    "modelsCuisineTypeUpdatedAt": {
      "id": "models.cuisine_type.updated_at",
      "defaultMessage": "Last Update"
    },
    "modelsWoflowImportSelf": {
      "id": "models.woflow_import.self",
      "defaultMessage": "Woflow Import"
    },
    "modelsWoflowImportId": {
      "id": "models.woflow_import.id",
      "defaultMessage": "ID"
    },
    "modelsWoflowImportViewImportDetails": {
      "id": "models.woflow_import.view_import_details",
      "defaultMessage": "View import details"
    },
    "modelsWoflowImportViewJobData": {
      "id": "models.woflow_import.view_job_data",
      "defaultMessage": "View job data"
    },
    "modelsWoflowImportViewMenuData": {
      "id": "models.woflow_import.view_menu_data",
      "defaultMessage": "View menu data"
    },
    "modelsWoflowImportViewButton": {
      "id": "models.woflow_import.view_button",
      "defaultMessage": "View"
    },
    "modelsWoflowImportCreatedAt": {
      "id": "models.woflow_import.created_at",
      "defaultMessage": "Date Created"
    },
    "modelsWoflowImportUpdatedAt": {
      "id": "models.woflow_import.updated_at",
      "defaultMessage": "Date Updated"
    },
    "modelsWoflowImportSources": {
      "id": "models.woflow_import.sources",
      "defaultMessage": "Sources"
    },
    "modelsWoflowImportInstructions": {
      "id": "models.woflow_import.instructions",
      "defaultMessage": "Note"
    },
    "modelsWoflowImportWoflowJobData": {
      "id": "models.woflow_import.woflow_job_data",
      "defaultMessage": "Job Data"
    },
    "modelsWoflowImportWoflowJobId": {
      "id": "models.woflow_import.woflow_job_id",
      "defaultMessage": "Job Id"
    },
    "modelsWoflowImportWoflowMenuData": {
      "id": "models.woflow_import.woflow_menu_data",
      "defaultMessage": "Menu Data"
    },
    "modelsWoflowImportState": {
      "id": "models.woflow_import.state",
      "defaultMessage": "Status"
    },
    "modelsWoflowImportStateMessage": {
      "id": "models.woflow_import.state_message",
      "defaultMessage": "Status Message"
    },
    "modelsWoflowImportUserDisplayName": {
      "id": "models.woflow_import.user_name",
      "defaultMessage": "Requester"
    },
    "modelsWoflowImportStatusesCreatedStatus": {
      "id": "models.woflow_import.states.created",
      "defaultMessage": "Created"
    },
    "modelsWoflowImportStatusesSubmittedStatus": {
      "id": "models.woflow_import.states.submitted",
      "defaultMessage": "Submitted"
    },
    "modelsWoflowImportStatusesPendingStatus": {
      "id": "models.woflow_import.states.pending",
      "defaultMessage": "Pending"
    },
    "modelsWoflowImportStatusesProcessedStatus": {
      "id": "models.woflow_import.states.processed",
      "defaultMessage": "Processed"
    },
    "modelsWoflowImportStatusesCancelledStatus": {
      "id": "models.woflow_import.states.cancelled",
      "defaultMessage": "Cancelled"
    },
    "modelsWoflowImportStatusesCompletedStatus": {
      "id": "models.woflow_import.states.completed",
      "defaultMessage": "Completed"
    },
    "modelsWoflowImportStatusesFailedStatus": {
      "id": "models.woflow_import.states.failed",
      "defaultMessage": "Failed"
    },
    "modelsWoflowImportModalTitle": {
      "id": "models.woflow_import.modal.title",
      "defaultMessage": "New Import"
    },
    "modelsWoflowImportModalDetailsTitle": {
      "id": "models.woflow_import.modal.details_title",
      "defaultMessage": "Import Details"
    },
    "modelsWoflowImportModalDetailsConfirmCancelHeader": {
      "id": "models.woflow_import.details_modal.confirm_cancel_header",
      "defaultMessage": "Confirm Cancel"
    },
    "modelsWoflowImportModalDetailsConfirmCancelMessage": {
      "id": "models.woflow_import.details_modal.confirm_cancel_message",
      "defaultMessage": "Are you sure you want to cancel the import?"
    },
    "modelsWoflowImportModalSectionNoFilesOrUrls": {
      "id": "models.woflow_import.modal.section_no_files_or_urls",
      "defaultMessage": "Please upload menu files or add menu urls in order to request a new import."
    },
    "modelsWoflowImportModalSectionFiles": {
      "id": "models.woflow_import.modal.section_files",
      "defaultMessage": "Files"
    },
    "modelsWoflowImportModalSectionPhotos": {
      "id": "models.woflow_import.modal.section_photos",
      "defaultMessage": "Photos"
    },
    "modelsWoflowImportModalSectionUrls": {
      "id": "models.woflow_import.modal.section_urls",
      "defaultMessage": "Urls"
    },
    "modelsWoflowImportModalSectionNote": {
      "id": "models.woflow_import.modal.section_note",
      "defaultMessage": "Note"
    },
    "modelsWoflowImportModalSectionNotePlaceholder": {
      "id": "models.woflow_import.modal.section_note_placeholder",
      "defaultMessage": "Type note here..."
    },
    "modelsWoflowImportModalSubmitButton": {
      "id": "models.woflow_import.modal.submit_button",
      "defaultMessage": "Request"
    },
    "modelsWoflowImportDetailsModalSectionDetails": {
      "id": "models.woflow_import.details_modal.details",
      "defaultMessage": "Details"
    },
    "modelsWoflowImportDetailsModalSectionDetailsRefreshMessage": {
      "id": "models.woflow_import.details_modal.details_refresh_message",
      "defaultMessage": "Refresh Import"
    },
    "modelsWoflowImportDetailsModalSectionImportCancelMessage": {
      "id": "models.woflow_import.details_modal.import_cancel_message",
      "defaultMessage": "Cancel Import"
    },
    "modelsWoflowImportDetailsModalSectionImportCloseButton": {
      "id": "models.woflow_import.details_modal.import_close_button",
      "defaultMessage": "Close"
    },
    "modelsWoflowImportDetailsModalSectionMenus": {
      "id": "models.woflow_import.details_modal.menus",
      "defaultMessage": "Menus"
    },
    "modelsWoflowImportDetailsModalSectionMenusDeleteConfirmMessage": {
      "id": "models.woflow_import.details_modal.menus_delete_confirm_message",
      "defaultMessage": "Are you sure you want to delete all menus for this import?"
    },
    "modelsWoflowImportDetailsModalSectionMenusDeleteConfirmTitle": {
      "id": "models.woflow_import.details_modal.menus_delete_confirm_title",
      "defaultMessage": "Confirm Delete"
    },
    "modelsWoflowImportDetailsModalSectionMenusDeleteMessage": {
      "id": "models.woflow_import.details_modal.menus_delete_message",
      "defaultMessage": "Delete all menus"
    },
    "modelsWoflowImportDetailsModalSectionComments": {
      "id": "models.woflow_import.details_modal.comments",
      "defaultMessage": "Comments"
    },
    "modelsThemeActiveNavlinkColor": {
      "id": "models.theme.active_navlink_color",
      "defaultMessage": "Active Navlink Color"
    },
    "modelsThemeAnnouncementBgColor": {
      "id": "models.theme.announcement_bg_color",
      "defaultMessage": "Announcement BG Color"
    },
    "modelsThemeAnnouncementBorderColor": {
      "id": "models.theme.announcement_border_color",
      "defaultMessage": "Announcement Border Color"
    },
    "modelsThemeDefaultBackgroundColor": {
      "id": "models.theme.default_background_color",
      "defaultMessage": "Default Background Color"
    },
    "modelsThemeDefaultBackgroundEffect": {
      "id": "models.theme.default_background_effect",
      "defaultMessage": "Default Background Effect"
    },
    "modelsThemeDefaultBackgroundImage": {
      "id": "models.theme.default_background_image",
      "defaultMessage": "Default Background Image"
    },
    "modelsThemeCustomSass": {
      "id": "models.theme.custom_sass",
      "defaultMessage": "Custom Styles (CSS)"
    },
    "modelsThemeCustomSectionContentFontColor": {
      "id": "models.theme.custom_section_content_font_color",
      "defaultMessage": "Page Section Font Color"
    },
    "modelsThemeCustomSectionContentFontId": {
      "id": "models.theme.custom_section_content_font_id",
      "defaultMessage": "Page Section Font"
    },
    "modelsThemeCustomSectionHeaderFontColor": {
      "id": "models.theme.custom_section_header_font_color",
      "defaultMessage": "Page Section Heading Font Color"
    },
    "modelsThemeCustomSectionHeaderFontId": {
      "id": "models.theme.custom_section_header_font_id",
      "defaultMessage": "Page Section Heading Font"
    },
    "modelsThemeDefaultCustomSectionBgColor": {
      "id": "models.theme.default_custom_section_bg_color",
      "defaultMessage": "Default Page Section BG Color"
    },
    "modelsThemeDefaultFontColor": {
      "id": "models.theme.default_font_color",
      "defaultMessage": "Base Font Color"
    },
    "modelsThemeDefaultFontId": {
      "id": "models.theme.default_font_id",
      "defaultMessage": "Base Font"
    },
    "modelsThemeDefaultMenuLayout": {
      "id": "models.theme.default_menu_layout",
      "defaultMessage": "Default Menu Layout"
    },
    "modelsThemeDishLayout": {
      "id": "models.theme.dish_layout",
      "defaultMessage": "Item Details Layout"
    },
    "modelsThemeDishImageAlignment": {
      "id": "models.theme.dish_detail_image_alignment",
      "defaultMessage": "Image Alignment"
    },
    "modelsThemeDishExtraTab": {
      "id": "models.theme.dish_detail_tab",
      "defaultMessage": "Extra Tab"
    },
    "modelsThemeDefaultDishExtraTab": {
      "id": "models.theme.default_dish_detail_tab",
      "defaultMessage": "Default Selected Tab"
    },
    "modelsThemeDishDetailButton": {
      "id": "models.theme.dish_detail_button",
      "defaultMessage": "Dish Button"
    },
    "modelsThemeDishTagKeyPosition": {
      "id": "models.theme.dish_tag_key_position",
      "defaultMessage": "Item Tag Key Position"
    },
    "modelsThemeDefaultTransitionType": {
      "id": "models.theme.default_transition_type",
      "defaultMessage": "Default Transition Type"
    },
    "modelsThemeFeaturedDishBgColor": {
      "id": "models.theme.featured_dish_bg_color",
      "defaultMessage": "Featured Item BG Color"
    },
    "modelsThemeFontPreviewTitle": {
      "id": "models.theme.font_preview_title",
      "defaultMessage": "Font Preview"
    },
    "modelsThemeFontSample": {
      "id": "models.theme.font_sample",
      "defaultMessage": "The quick brown fox jumps over the lazy dog"
    },
    "modelsThemeFooterLogoEffect": {
      "id": "models.theme.footer_logo_effect",
      "defaultMessage": "Footer Logo Effect"
    },
    "modelsThemeHeaderFontWeight": {
      "id": "models.theme.header_font_weight",
      "defaultMessage": "Page Heading Weight"
    },
    "modelsThemeHeaderBackgroundEffect": {
      "id": "models.theme.header_background_effect",
      "defaultMessage": "Navbar BG"
    },
    "modelsThemeHeaderBorderColor": {
      "id": "models.theme.header_border_color",
      "defaultMessage": "Header Border Color"
    },
    "modelsThemeHeaderLogoAlignment": {
      "id": "models.theme.header_logo_alignment",
      "defaultMessage": "Navbar Logo Alignment"
    },
    "modelsThemeHeaderLogoEffect": {
      "id": "models.theme.header_logo_effect",
      "defaultMessage": "Navbar Logo Effect"
    },
    "modelsThemeHeaderLogoSize": {
      "id": "models.theme.header_logo_size",
      "defaultMessage": "Navbar Logo Size"
    },
    "modelsThemeHeaderNavFontColor": {
      "id": "models.theme.header_nav_font_color",
      "defaultMessage": "Navbar Font Color"
    },
    "modelsThemeIsActiveNavlinkColorEnabled": {
      "id": "models.theme.is_active_navlink_color_enabled",
      "defaultMessage": "Enable Active Navlink Color"
    },
    "modelsThemeIsWidgetResponsive": {
      "id": "models.theme.is_widget_responsive",
      "defaultMessage": "Responsive Embed"
    },
    "modelsThemeMenuColumnCount": {
      "id": "models.theme.menu_column_count",
      "defaultMessage": "Menu Columns"
    },
    "modelsThemeMenuFlowSetting": {
      "id": "models.theme.menu_flow_setting",
      "defaultMessage": "Menu Flow Setting"
    },
    "modelsThemeMenuHeaderFontColor": {
      "id": "models.theme.menu_header_font_color",
      "defaultMessage": "Menu Heading Font Color"
    },
    "modelsThemeMenuHeaderFontId": {
      "id": "models.theme.menu_header_font_id",
      "defaultMessage": "Menu Heading Font"
    },
    "modelsThemeMenuIconEffect": {
      "id": "models.theme.menu_icon_effect",
      "defaultMessage": "Item Icon Effect"
    },
    "modelsThemeMenuItemDescriptionFontColor": {
      "id": "models.theme.menu_item_description_font_color",
      "defaultMessage": "Item Description Font Color"
    },
    "modelsThemeMenuItemDescriptionFontId": {
      "id": "models.theme.menu_item_description_font_id",
      "defaultMessage": "Item Description Font"
    },
    "modelsThemeMenuItemFontAlignment": {
      "id": "models.theme.menu_item_font_alignment",
      "defaultMessage": "Item Font Alignment"
    },
    "modelsThemeMenuItemMobileFullWidth": {
      "id": "models.theme.menu_item_mobile_full_width",
      "defaultMessage": "Display Full Width Photos on Mobile (Next Layout Only)"
    },
    "modelsThemeMenuItemNameFontCase": {
      "id": "models.theme.menu_item_name_font_case",
      "defaultMessage": "Item Name Casing"
    },
    "modelsThemeMenuItemNameFontColor": {
      "id": "models.theme.menu_item_name_font_color",
      "defaultMessage": "Item Name Font Color"
    },
    "modelsThemeMenuItemNameFontId": {
      "id": "models.theme.menu_item_name_font_id",
      "defaultMessage": "Item Name Font"
    },
    "modelsThemeMenuItemNameFontWeight": {
      "id": "models.theme.menu_item_name_font_weight",
      "defaultMessage": "Item Name Weight"
    },
    "modelsThemeMenuRowHeight": {
      "id": "models.theme.menu_row_height",
      "defaultMessage": "Menu Rows"
    },
    "modelsThemeMenuTabsLayout": {
      "id": "models.theme.menu_tabs_layout",
      "defaultMessage": "Menu Tabs"
    },
    "modelsThemeNavbarBgColor": {
      "id": "models.theme.navbar_bg_color",
      "defaultMessage": "Navbar BG Color"
    },
    "modelsThemePageHeaderFontCase": {
      "id": "models.theme.page_header_font_case",
      "defaultMessage": "Page Heading Case"
    },
    "modelsThemePageSubHeaderCase": {
      "id": "models.theme.page_sub_header_case",
      "defaultMessage": "Page Subheading Case"
    },
    "modelsThemePageHeaderFontColor": {
      "id": "models.theme.page_header_font_color",
      "defaultMessage": "Page Heading Color"
    },
    "modelsThemePageHeaderFontId": {
      "id": "models.theme.page_header_font_id",
      "defaultMessage": "Page Heading Font"
    },
    "modelsThemePageSubHeaderFontColor": {
      "id": "models.theme.page_sub_header_font_color",
      "defaultMessage": "Page Subheading Color"
    },
    "modelsThemePageHeaderFontSize": {
      "id": "models.theme.page_header_font_size",
      "defaultMessage": "Page Heading Size"
    },
    "modelsThemePageSubHeaderFontId": {
      "id": "models.theme.page_sub_header_font_id",
      "defaultMessage": "Page Subheading Font"
    },
    "modelsThemePageSubHeaderFontSize": {
      "id": "models.theme.page_sub_header_font_size",
      "defaultMessage": "Page Subheading Size"
    },
    "modelsThemePaletteType": {
      "id": "models.theme.palette_type",
      "defaultMessage": "Palette"
    },
    "modelsThemePrimaryColor": {
      "id": "models.theme.primary_color",
      "defaultMessage": "Primary Color"
    },
    "modelsThemePopmenuLogoEffect": {
      "id": "models.theme.popmenu_logo_effect",
      "defaultMessage": "Popmenu Logo Color"
    },
    "modelsThemeRootThemeId": {
      "id": "models.theme.root_theme_id",
      "defaultMessage": "Theme"
    },
    "modelsThemeSecondaryColor": {
      "id": "models.theme.secondary_color",
      "defaultMessage": "Secondary Color"
    },
    "modelsThemeOrderingLogoBackgroundColor": {
      "id": "models.theme.ordering_logo_background_color",
      "defaultMessage": "Ordering Logo Background Color"
    },
    "modelsThemeShowCurrencySymbol": {
      "id": "models.theme.show_currency_symbol",
      "defaultMessage": "Display Currency"
    },
    "modelsThemeShowFollowSection": {
      "id": "models.theme.show_follow_section",
      "defaultMessage": "Display Follow Section"
    },
    "modelsThemeShowMenuItemDescription": {
      "id": "models.theme.show_menu_item_description",
      "defaultMessage": "Display Item Description"
    },
    "modelsThemeShowMenuItemDishTags": {
      "id": "models.theme.show_menu_item_dish_tags",
      "defaultMessage": "Display Item Tags"
    },
    "modelsThemeShowMenuItemExtraGroups": {
      "id": "models.theme.show_menu_item_extra_groups",
      "defaultMessage": "Display Modifier Groups on Details Page"
    },
    "modelsThemeShowPopularSection": {
      "id": "models.theme.show_popular_section",
      "defaultMessage": "Display Popular Section"
    },
    "modelsThemeShowPrice": {
      "id": "models.theme.show_price",
      "defaultMessage": "Display Price"
    },
    "modelsThemeShowMenuShareSection": {
      "id": "models.theme.show_menu_share_section",
      "defaultMessage": "Display Menu Share Section"
    },
    "modelsThemeShowMenuReviewSection": {
      "id": "models.theme.show_menu_review_section",
      "defaultMessage": "Display Menu Review Section"
    },
    "modelsThemeUseMobileMenuTabsLayout": {
      "id": "models.theme.use_mobile_menu_tabs_layout",
      "defaultMessage": "Enable Mobile Menu Tabs"
    },
    "modelsCcpaRequestSelf": {
      "id": "models.ccpa_request.self",
      "defaultMessage": "CCPA Request"
    },
    "modelsCcpaRequestSelves": {
      "id": "models.ccpa_request.selves",
      "defaultMessage": "CCPA Requests"
    },
    "modelsCcpaRequestCreatedAt": {
      "id": "models.ccpa_request.created_at",
      "defaultMessage": "Created"
    },
    "modelsCcpaRequestName": {
      "id": "models.ccpa_request.name",
      "defaultMessage": "Name"
    },
    "modelsCcpaRequestEmail": {
      "id": "models.ccpa_request.email",
      "defaultMessage": "Email"
    },
    "modelsCcpaRequestIsCaliforniaResident": {
      "id": "models.ccpa_request.is_california_resident",
      "defaultMessage": "California Resident"
    },
    "modelsCcpaRequestIsDeleteDataRequest": {
      "id": "models.ccpa_request.is_delete_data_request",
      "defaultMessage": "Delete Data"
    },
    "modelsCcpaRequestIsLearnHowDataIsUsedRequest": {
      "id": "models.ccpa_request.is_learn_how_data_is_used_request",
      "defaultMessage": "How data is used"
    },
    "modelsCcpaRequestIsLearnHowDataWasObtainedRequest": {
      "id": "models.ccpa_request.is_learn_how_data_was_obtained_request",
      "defaultMessage": "How data was obtained"
    },
    "modelsCcpaRequestIsLearnIfDataWasSharedRequest": {
      "id": "models.ccpa_request.is_learn_if_data_was_shared_request",
      "defaultMessage": "Data Shared"
    },
    "modelsCcpaRequestIsLearnWhatDataWeHaveRequest": {
      "id": "models.ccpa_request.is_learn_what_data_we_have_request",
      "defaultMessage": "What Data"
    },
    "modelsCcpaRequestIsResolved": {
      "id": "models.ccpa_request.is_resolved",
      "defaultMessage": "Resolved"
    },
    "modelsCcpaRequestResolvedAt": {
      "id": "models.ccpa_request.resolved_at",
      "defaultMessage": "Resolved On"
    },
    "modelsDncListSelf": {
      "id": "models.dnc_list.self",
      "defaultMessage": "Do Not Call"
    },
    "modelsDncListId": {
      "id": "models.dnc_list.id",
      "defaultMessage": "Id"
    },
    "modelsDncListDncInfo": {
      "id": "models.dnc_list.dnc_info",
      "defaultMessage": "Info"
    },
    "modelsDncListDncListType": {
      "id": "models.dnc_list.dnc_list_type",
      "defaultMessage": "List Type"
    },
    "modelsDncListErrorMessage": {
      "id": "models.dnc_list.error_message",
      "defaultMessage": "Error Message"
    },
    "modelsDncListFileS3Key": {
      "id": "models.dnc_list.file_s3_key",
      "defaultMessage": "S3 Key"
    },
    "modelsDncListSplitFileTally": {
      "id": "models.dnc_list.split_file_tally",
      "defaultMessage": "Tally"
    },
    "modelsDncListStatus": {
      "id": "models.dnc_list.status",
      "defaultMessage": "Status"
    },
    "modelsDncListStatusAt": {
      "id": "models.dnc_list.status_at",
      "defaultMessage": "Status At"
    },
    "modelsDncListUpdatedAt": {
      "id": "models.dnc_list.updated_at",
      "defaultMessage": "Updated At"
    },
    "modelsDncListCreatedAt": {
      "id": "models.dnc_list.created_at",
      "defaultMessage": "Created At"
    },
    "modelsSocialAccountSocialType": {
      "id": "models.social_account.social_type",
      "defaultMessage": "Service"
    },
    "modelsGoogleReviewComment": {
      "id": "models.google_review.comment",
      "defaultMessage": "Comment"
    },
    "modelsGoogleReviewCreateTime": {
      "id": "models.google_review.create_time",
      "defaultMessage": "Date"
    },
    "modelsGoogleReviewLocation": {
      "id": "models.google_review.location",
      "defaultMessage": "Location"
    },
    "modelsGoogleReviewReply": {
      "id": "models.google_review.reply",
      "defaultMessage": "Reply"
    },
    "modelsGoogleReviewReviewer": {
      "id": "models.google_review.reviewer",
      "defaultMessage": "Reviewer"
    },
    "modelsGoogleReviewSelf": {
      "id": "models.google_review.self",
      "defaultMessage": "Google Review"
    },
    "modelsGoogleReviewStarRating": {
      "id": "models.google_review.star_rating",
      "defaultMessage": "Rating"
    },
    "modelsRestaurantGroupCreatedAt": {
      "id": "models.restaurant_group.created_at",
      "defaultMessage": "Created"
    },
    "modelsRestaurantGroupName": {
      "id": "models.restaurant_group.name",
      "defaultMessage": "Name"
    },
    "modelsRestaurantGroupSelf": {
      "id": "models.restaurant_group.self",
      "defaultMessage": "Restaurant Group"
    },
    "modelsRestaurantGroupSelves": {
      "id": "models.restaurant_group.selves",
      "defaultMessage": "Restaurant Groups"
    },
    "modelsRootTaskActionTitle": {
      "id": "models.root_task.action_title",
      "defaultMessage": "Action Title"
    },
    "modelsRootTaskActionLink": {
      "id": "models.root_task.action_link",
      "defaultMessage": "Action Link"
    },
    "modelsRootTaskConcierge": {
      "id": "models.root_task.concierge",
      "defaultMessage": "Boost Only"
    },
    "modelsRootTaskConciergeMessage": {
      "id": "models.root_task.concierge_message",
      "defaultMessage": "Boost Message"
    },
    "modelsRootTaskDismissible": {
      "id": "models.root_task.dismissible",
      "defaultMessage": "Dismissible"
    },
    "modelsRootTaskEdit": {
      "id": "models.root_task.edit",
      "defaultMessage": "Edit Root Task"
    },
    "modelsRootTaskExpirationDate": {
      "id": "models.root_task.expiration_date",
      "defaultMessage": "Expiration Date"
    },
    "modelsRootTaskIsEnabled": {
      "id": "models.root_task.is_enabled",
      "defaultMessage": "Enabled"
    },
    "modelsRootTaskMessage": {
      "id": "models.root_task.message",
      "defaultMessage": "Message"
    },
    "modelsRootTaskName": {
      "id": "models.root_task.name",
      "defaultMessage": "Title"
    },
    "modelsRootTaskRecurring": {
      "id": "models.root_task.recurring",
      "defaultMessage": "Recurring"
    },
    "modelsRootTaskRoot": {
      "id": "models.root_task.root",
      "defaultMessage": "Root"
    },
    "modelsRootTaskSelf": {
      "id": "models.root_task.self",
      "defaultMessage": "Tasks"
    },
    "modelsRootTaskStagingClients": {
      "id": "models.root_task.staging_clients",
      "defaultMessage": "Staging Clients"
    },
    "modelsRootTaskTaskType": {
      "id": "models.root_task.task_type",
      "defaultMessage": "Task Type"
    },
    "modelsReviewLocation": {
      "id": "models.review.location",
      "defaultMessage": "Location"
    },
    "modelsReviewSelf": {
      "id": "models.review.self",
      "defaultMessage": "Review"
    },
    "modelsReviewSelves": {
      "id": "models.review.selves",
      "defaultMessage": "Reviews"
    },
    "modelsReviewApproved": {
      "id": "models.review.approved",
      "defaultMessage": "Status"
    },
    "modelsReviewContent": {
      "id": "models.review.content",
      "defaultMessage": "Content"
    },
    "modelsReviewCreatedAt": {
      "id": "models.review.created_at",
      "defaultMessage": "Submitted"
    },
    "modelsReviewDishableId": {
      "id": "models.review.dishable_id",
      "defaultMessage": "Review"
    },
    "modelsReviewDishableName": {
      "id": "models.review.dishable.name",
      "defaultMessage": "Dish"
    },
    "modelsReviewRestaurantName": {
      "id": "models.review.restaurant.name",
      "defaultMessage": "Restaurant Name"
    },
    "modelsReviewUserId": {
      "id": "models.review.user_id",
      "defaultMessage": "User"
    },
    "modelsReview::UserDisplayName": {
      "id": "models.review.user.full_display_name",
      "defaultMessage": "User"
    },
    "modelsSocialPost::LivePostSelf": {
      "id": "models.social_post/live_post.self",
      "defaultMessage": "Published Post"
    },
    "modelsSocialPost::LivePostSocialAccount": {
      "id": "models.social_post/live_post.social_account.display_name",
      "defaultMessage": "Account"
    },
    "modelsSocialPost::LivePostFacebookEngagedUsersCount": {
      "id": "models.social_post/live_post.facebook_engaged_users_count",
      "defaultMessage": "Facebook Engaged Users"
    },
    "modelsSocialPost::LivePostFacebookImpressionsCount": {
      "id": "models.social_post/live_post.facebook_impressions_count",
      "defaultMessage": "Facebook Impressions"
    },
    "modelsSocialPost::LivePostFacebookReactionsCount": {
      "id": "models.social_post/live_post.facebook_reactions_count",
      "defaultMessage": "Facebook Reactions"
    },
    "modelsSocialPost::LivePostInstagramEngagedUsersCount": {
      "id": "models.social_post/live_post.instagram_engaged_users_count",
      "defaultMessage": "Instagram Engaged Users"
    },
    "modelsSocialPost::LivePostInstagramImpressionsCount": {
      "id": "models.social_post/live_post.instagram_impressions_count",
      "defaultMessage": "Instagram Impressions"
    },
    "modelsSocialPost::LivePostMessage": {
      "id": "models.social_post/live_post.message",
      "defaultMessage": "Message"
    },
    "modelsSocialPost::LivePostProcessedAt": {
      "id": "models.social_post/live_post.processed_at",
      "defaultMessage": "Posted At"
    },
    "modelsSocialPost::LivePostRestaurantName": {
      "id": "models.social_post/live_post.restaurant_name",
      "defaultMessage": "Restaurant"
    },
    "modelsSocialPost::LivePostSocialType": {
      "id": "models.social_post/live_post.social_type",
      "defaultMessage": "Posted To"
    },
    "modelsSocialPost::LivePostTwitterFavoritesCount": {
      "id": "models.social_post/live_post.twitter_favorites_count",
      "defaultMessage": "Twitter Favorites"
    },
    "modelsSocialPost::LivePostTwitterRetweetsCount": {
      "id": "models.social_post/live_post.twitter_retweets_count",
      "defaultMessage": "Retweets"
    },
    "modelsSocialPost::LivePostUserDisplayName": {
      "id": "models.social_post/live_post.social_post.user.full_display_name",
      "defaultMessage": "Author"
    },
    "modelsSocialPostComments": {
      "id": "models.social_post.comments",
      "defaultMessage": "Comments"
    },
    "modelsSocialPostEngagements": {
      "id": "models.social_post.engagements",
      "defaultMessage": "Engagements"
    },
    "modelsSocialPostEvent": {
      "id": "models.social_post.event",
      "defaultMessage": "Event"
    },
    "modelsSocialPostEventOptional": {
      "id": "models.social_post.event_optional",
      "defaultMessage": "Event (optional)"
    },
    "modelsSocialPostFavorites": {
      "id": "models.social_post.favorites",
      "defaultMessage": "Favorites"
    },
    "modelsSocialPostSelf": {
      "id": "models.social_post.self",
      "defaultMessage": "Post"
    },
    "modelsSocialPostSearchEvents": {
      "id": "models.social_post.search_events",
      "defaultMessage": "Search events..."
    },
    "modelsSocialPostSearchOrderingEvent": {
      "id": "models.social_post.search_ordering_event",
      "defaultMessage": "Search ordering event..."
    },
    "modelsSocialPostSearchOrderingEvents": {
      "id": "models.social_post.search_ordering_events",
      "defaultMessage": "Search ordering events..."
    },
    "modelsSocialPostOrderingEvent": {
      "id": "models.social_post.ordering_event",
      "defaultMessage": "Ordering Event"
    },
    "modelsSocialPostSearchMenuItems": {
      "id": "models.social_post.search_menu_items",
      "defaultMessage": "Search menu items..."
    },
    "modelsSocialPostSearchMenuItem": {
      "id": "models.social_post.search_menu_item",
      "defaultMessage": "Search menu item..."
    },
    "modelsSocialPostSearchEvent": {
      "id": "models.social_post.search_event",
      "defaultMessage": "Search event..."
    },
    "modelsSocialPostImpressions": {
      "id": "models.social_post.impressions",
      "defaultMessage": "Impressions"
    },
    "modelsSocialPostIsDraft": {
      "id": "models.social_post.is_draft",
      "defaultMessage": "Draft"
    },
    "modelsSocialPostIsScheduled": {
      "id": "models.social_post.is_scheduled",
      "defaultMessage": "Schedule"
    },
    "modelsSocialPostJoinUs": {
      "id": "models.social_post.join_us",
      "defaultMessage": "Join us for"
    },
    "modelsSocialPostLikes": {
      "id": "models.social_post.likes",
      "defaultMessage": "Likes"
    },
    "modelsSocialPostLinkUrl": {
      "id": "models.social_post.link_url",
      "defaultMessage": "Link (optional)"
    },
    "modelsSocialPostLivePosts": {
      "id": "models.social_post.live_posts",
      "defaultMessage": "Posts"
    },
    "modelsSocialPostMenuItem": {
      "id": "models.social_post.menu_item",
      "defaultMessage": "Menu Item"
    },
    "modelsSocialPostMenuItemOptional": {
      "id": "models.social_post.menu_item_optional",
      "defaultMessage": "Menu Item (optional)"
    },
    "modelsSocialPostMessage": {
      "id": "models.social_post.message",
      "defaultMessage": "Post Content"
    },
    "modelsSocialPostSaveDraft": {
      "id": "models.social_post.save_draft",
      "defaultMessage": "Save as draft"
    },
    "modelsSocialPostSavePost": {
      "id": "models.social_post.save_post",
      "defaultMessage": "Save post"
    },
    "modelsSocialPostNoSocialAccount": {
      "id": "models.social_post.no_social_account",
      "defaultMessage": "No Social Account(s)"
    },
    "modelsSocialPostNoLivePosts": {
      "id": "models.social_post.no_live_posts",
      "defaultMessage": "No Live Posts"
    },
    "modelsSocialPostTooltipMenuItem": {
      "id": "models.social_post.tooltip_menu_item",
      "defaultMessage": "Optionally select a menu item to auto-populate the post content"
    },
    "modelsSocialPostTooltipEvent": {
      "id": "models.social_post.tooltip_event",
      "defaultMessage": "Optionally select an event to auto-populate the post content"
    },
    "modelsSocialPostPerformance": {
      "id": "models.social_post.performance",
      "defaultMessage": "{social_type} Performance"
    },
    "modelsSocialPostPhoto": {
      "id": "models.social_post.photo",
      "defaultMessage": "Photo (optional)"
    },
    "modelsSocialPostDateAndAuthor": {
      "id": "models.social_post.date_and_author",
      "defaultMessage": "Posted on {date} by {author}"
    },
    "modelsSocialPostReactions": {
      "id": "models.social_post.reactions",
      "defaultMessage": "Reactions"
    },
    "modelsSocialPostSelectAll": {
      "id": "models.social_post.select_all",
      "defaultMessage": "Select All"
    },
    "modelsSocialPostTryLatest": {
      "id": "models.social_post.try_latest",
      "defaultMessage": "Try our latest featured item: "
    },
    "modelsSocialPostVideo": {
      "id": "models.social_post.video",
      "defaultMessage": "Video (optional)"
    },
    "modelsSocialPostViewPost": {
      "id": "models.social_post.view_post",
      "defaultMessage": "View Post"
    },
    "modelsSocialPostProcessedAt": {
      "id": "models.social_post.processed_at",
      "defaultMessage": "Published"
    },
    "modelsSocialPostScheduledAt": {
      "id": "models.social_post.scheduled_at",
      "defaultMessage": "Scheduled"
    },
    "modelsSocialPostSocialAccounts": {
      "id": "models.social_post.social_accounts",
      "defaultMessage": "Accounts"
    },
    "modelsSocialPostUserDisplayName": {
      "id": "models.social_post.user_display_name",
      "defaultMessage": "Author"
    },
    "modelsSocialPostUseShareToFeed": {
      "id": "models.social_post.share_to_feed",
      "defaultMessage": "Main Grid"
    },
    "modelsSocialPostUseReelsGrid": {
      "id": "models.social_post.reels",
      "defaultMessage": "Reels Grid"
    },
    "modelsSocialPostUseStory": {
      "id": "models.social_post.story",
      "defaultMessage": "Story"
    },
    "modelsSocialPostCarouselMaxLimit": {
      "id": "models.social_post.carousel_max_limit",
      "defaultMessage": "You cannot upload more than 10 images"
    },
    "modelsSocialPostCarouselMaxLimitVideo": {
      "id": "models.social_post.carousel_max_limit_video",
      "defaultMessage": "You cannot upload more than 10 videos"
    },
    "modelsSocialPostCarouselOption": {
      "id": "models.social_post.carousel_option",
      "defaultMessage": "This post will be a carousel for Instagram accounts"
    },
    "modelsSocialPostTranscodedVideo": {
      "id": "models.social_post.transcoded_video",
      "defaultMessage": "Videos may be automatically resized to fit specifications of selected social media sites."
    },
    "modelsSocialPostUseShortLinks": {
      "id": "models.social_post.use_short_links",
      "defaultMessage": "Use Short Links"
    },
    "modelsSocialPostPublishTo": {
      "id": "models.social_post.publish_to",
      "defaultMessage": "Publish to"
    },
    "modelsMenu::SectionPosition": {
      "id": "models.menu/section.position",
      "defaultMessage": "Order"
    },
    "modelsMenu::SubsectionCustomHtml": {
      "id": "models.menu/subsection.custom_html",
      "defaultMessage": "Custom HTML"
    },
    "modelsMenuPosition": {
      "id": "models.menu.position",
      "defaultMessage": "Order"
    },
    "modelsMenu::ItemPosition": {
      "id": "models.menu/item.position",
      "defaultMessage": "Order"
    },
    "modelsMenu::Item::ExtraName": {
      "id": "models.menu/item/extra.name",
      "defaultMessage": "Modifier Name"
    },
    "modelsMenu::Item::ExtraPrice": {
      "id": "models.menu/item/extra.price",
      "defaultMessage": "Price"
    },
    "modelsMenu::Item::SizeName": {
      "id": "models.menu/item/size.name",
      "defaultMessage": "Size Name"
    },
    "modelsMenu::Item::SizePrice": {
      "id": "models.menu/item/size.price",
      "defaultMessage": "Price"
    },
    "modelsBlockedTermSelf": {
      "id": "models.blocked_term.self",
      "defaultMessage": "Root Block Term"
    },
    "modelsBlockedTermTerm": {
      "id": "models.blocked_term.term",
      "defaultMessage": "Blocked Term"
    },
    "modelsBlockedTermCreatedAt": {
      "id": "models.blocked_term.created_at",
      "defaultMessage": "Created at"
    },
    "modelsRootDishTagAbbr": {
      "id": "models.root_dish_tag.abbr",
      "defaultMessage": "Abbreviation"
    },
    "modelsRootDishTagColor": {
      "id": "models.root_dish_tag.color",
      "defaultMessage": "Color"
    },
    "modelsRootDishTagCreatedAt": {
      "id": "models.root_dish_tag.created_at",
      "defaultMessage": "Created"
    },
    "modelsRootDishTagDishesTagCount": {
      "id": "models.root_dish_tag.dishes_tag_count",
      "defaultMessage": "Total Items"
    },
    "modelsRootDishTagImage": {
      "id": "models.root_dish_tag.image",
      "defaultMessage": "Image"
    },
    "modelsRootDishTagImageTooltip": {
      "id": "models.root_dish_tag.image_tooltip",
      "defaultMessage": "This image replaces the abbreviation and color"
    },
    "modelsRootDishTagRestaurantsTagCount": {
      "id": "models.root_dish_tag.restaurants_tag_count",
      "defaultMessage": "Total Restaurants"
    },
    "modelsRootDishTagName": {
      "id": "models.root_dish_tag.name",
      "defaultMessage": "Name"
    },
    "modelsRootDishTagSelf": {
      "id": "models.root_dish_tag.self",
      "defaultMessage": "Tag"
    },
    "modelsOrderingOfferSelf": {
      "id": "models.ordering_offer.self",
      "defaultMessage": "Offer"
    },
    "modelsOrderingOfferCode": {
      "id": "models.ordering_offer.code",
      "defaultMessage": "Offer Code"
    },
    "modelsOrderingOfferContent": {
      "id": "models.ordering_offer.content",
      "defaultMessage": "Offer Message"
    },
    "modelsOrderingOfferCreatedAt": {
      "id": "models.ordering_offer.created_at",
      "defaultMessage": "Created"
    },
    "modelsOrderingOfferDisclaimer": {
      "id": "models.ordering_offer.disclaimer",
      "defaultMessage": "Disclaimer"
    },
    "modelsOrderingOfferExpirationDate": {
      "id": "models.ordering_offer.expiration_date",
      "defaultMessage": "Expiration Date"
    },
    "modelsOrderingOfferImage": {
      "id": "models.ordering_offer.image",
      "defaultMessage": "Offer Image"
    },
    "modelsOrderingOfferUsageLimits": {
      "id": "models.ordering_offer.usage_limits",
      "defaultMessage": "Usage Limits"
    },
    "modelsOrderingOfferIsOnetimeUseSingle": {
      "id": "models.ordering_offer.is_onetime_use.single",
      "defaultMessage": "Single-Use"
    },
    "modelsOrderingOfferIsOnetimeUseMulti": {
      "id": "models.ordering_offer.is_onetime_use.multi",
      "defaultMessage": "Multi-Use"
    },
    "modelsOrderingOfferLiveAt": {
      "id": "models.ordering_offer.live_at",
      "defaultMessage": "Live"
    },
    "modelsOrderingOfferName": {
      "id": "models.ordering_offer.name",
      "defaultMessage": "Offer Title"
    },
    "modelsOrderingOfferOfferCodesCount": {
      "id": "models.ordering_offer.offer_codes_count",
      "defaultMessage": "Received Followers"
    },
    "modelsOrderingOfferRedeemedOfferCodesCount": {
      "id": "models.ordering_offer.redeemed_offer_codes_count",
      "defaultMessage": "Redeemed"
    },
    "modelsOrderingOfferRedemptionLimit": {
      "id": "models.ordering_offer.redemption_limit",
      "defaultMessage": "Redemption Limit"
    },
    "modelsOrderingOfferRedemptionLimitUnlimited": {
      "id": "models.ordering_offer.redemption_limit.unlimited",
      "defaultMessage": "Unlimited"
    },
    "modelsOrderingOfferTotalOfferValue": {
      "id": "models.ordering_offer.total_offer_value",
      "defaultMessage": "Total Offer Value"
    },
    "modelsOrderingOfferTotalOrderValue": {
      "id": "models.ordering_offer.total_order_value",
      "defaultMessage": "Total Order Value"
    },
    "modelsOrderingOfferActiveStatusAt": {
      "id": "models.ordering_offer.active_status_at",
      "defaultMessage": "Live"
    },
    "modelsOrderingOfferDeliveryMethodSmartMessageMethodOption": {
      "id": "models.ordering_offer.delivery_method.smart_message_method_option",
      "defaultMessage": "Smart Message"
    },
    "modelsOrderingOfferDeliveryMethodAutomatedEmailMethodOption": {
      "id": "models.ordering_offer.delivery_method.automated_email_method_option",
      "defaultMessage": "Automated Email"
    },
    "modelsOrderingOfferDeliveryMethodMassMessageMethodOption": {
      "id": "models.ordering_offer.delivery_method.mass_message_method_option",
      "defaultMessage": "Dynamic Message"
    },
    "modelsOrderingOfferArchivedStatusAt": {
      "id": "models.ordering_offer.archived_status_at",
      "defaultMessage": "Time Frame"
    },
    "modelsMarketingOfferMarketingLocation": {
      "id": "models.marketing_offer.promotion_location",
      "defaultMessage": "Promotion Location"
    },
    "modelsMarketingOfferMarketingTypeAnnouncements": {
      "id": "models.marketing_offer.marketing_type.mt_announcements",
      "defaultMessage": "Announcements"
    },
    "modelsMarketingOfferMarketingTypeBecomeVip": {
      "id": "models.marketing_offer.marketing_type.mt_become_vip",
      "defaultMessage": "Become a VIP Modal"
    },
    "modelsBillingCreditCardSelf": {
      "id": "models.billing_credit_card.self",
      "defaultMessage": "Payment Method"
    },
    "modelsBillingCreditCardCreditCardCvc": {
      "id": "models.billing_credit_card.credit_card_cvc",
      "defaultMessage": "Security Code"
    },
    "modelsBillingCreditCardCreditCardExpiry": {
      "id": "models.billing_credit_card.credit_card_expiry",
      "defaultMessage": "Expiration"
    },
    "modelsBillingCreditCardCreditCardNumber": {
      "id": "models.billing_credit_card.credit_card_number",
      "defaultMessage": "Card Number"
    },
    "modelsBillingSubscriptionItemSelf": {
      "id": "models.billing_subscription_item.self",
      "defaultMessage": "Product"
    },
    "modelsLiveDomain::DnsSnapshot::StagedRecordSelf": {
      "id": "models.live_domain/dns_snapshot/staged_record.self",
      "defaultMessage": "Custom Record"
    },
    "modelsLiveDomain::DnsSnapshot::StagedRecordRecordName": {
      "id": "models.live_domain/dns_snapshot/staged_record.record_name",
      "defaultMessage": "Subdomain"
    },
    "modelsLiveDomain::DnsSnapshot::StagedRecordRecordType": {
      "id": "models.live_domain/dns_snapshot/staged_record.record_type",
      "defaultMessage": "Type"
    },
    "modelsLiveDomain::DnsSnapshot::StagedRecordRecordValue": {
      "id": "models.live_domain/dns_snapshot/staged_record.record_value",
      "defaultMessage": "Value"
    },
    "modelsLiveDomainSelf": {
      "id": "models.live_domain.self",
      "defaultMessage": "Domain"
    },
    "modelsLiveDomainSelves": {
      "id": "models.live_domain.selves",
      "defaultMessage": "Domains"
    },
    "modelsLiveDomainCreatedAt": {
      "id": "models.live_domain.created_at",
      "defaultMessage": "Added to Popmenu"
    },
    "modelsLiveDomainDomain": {
      "id": "models.live_domain.domain",
      "defaultMessage": "Domain"
    },
    "modelsLiveDomainRestaurant": {
      "id": "models.live_domain.restaurant",
      "defaultMessage": "Restaurant"
    },
    "modelsLoginCarouselSlideDescription": {
      "id": "models.login_carousel_slide.description",
      "defaultMessage": "Description"
    },
    "modelsLoginCarouselSlidePosition": {
      "id": "models.login_carousel_slide.position",
      "defaultMessage": "Display Position"
    },
    "modelsLoginCarouselSlideImage": {
      "id": "models.login_carousel_slide.image",
      "defaultMessage": "Image"
    },
    "modelsLoginCarouselSlideIsEnabled": {
      "id": "models.login_carousel_slide.is_enabled",
      "defaultMessage": "Enabled"
    },
    "modelsLoginCarouselSlideTitle": {
      "id": "models.login_carousel_slide.title",
      "defaultMessage": "Title"
    },
    "modelsLoginCarouselSlideUrl": {
      "id": "models.login_carousel_slide.url",
      "defaultMessage": "URL"
    },
    "modelsMyBusinessVerificationCreateTime": {
      "id": "models.my_business_verification.create_time",
      "defaultMessage": "Time"
    },
    "modelsMyBusinessVerificationState": {
      "id": "models.my_business_verification.state",
      "defaultMessage": "Status"
    },
    "modelsMyBusinessVerificationVerificationMethod": {
      "id": "models.my_business_verification.verification_method",
      "defaultMessage": "Method"
    },
    "modelsCustomTaxRateSelf": {
      "id": "models.custom_tax_rate.self",
      "defaultMessage": "Tax Rate"
    },
    "modelsCustomTaxRateDishesCount": {
      "id": "models.custom_tax_rate.dishes_count",
      "defaultMessage": "Items"
    },
    "modelsCustomTaxRateName": {
      "id": "models.custom_tax_rate.name",
      "defaultMessage": "Tax Rate"
    },
    "modelsLighthouseReportRestaurantName": {
      "id": "models.lighthouse_report.restaurant_name",
      "defaultMessage": "Restaurant"
    },
    "modelsLighthouseReport::PageReportSelf": {
      "id": "models.lighthouse_report/page_report.self",
      "defaultMessage": "Lighthouse Page Report"
    },
    "modelsLighthouseReport::PageReportAccessibilityScore": {
      "id": "models.lighthouse_report/page_report.accessibility_score",
      "defaultMessage": "Accessibility"
    },
    "modelsLighthouseReport::PageReportBestPracticesScore": {
      "id": "models.lighthouse_report/page_report.best_practices_score",
      "defaultMessage": "Best Practices"
    },
    "modelsLighthouseReport::PageReportLargestContentfulPaintScore": {
      "id": "models.lighthouse_report/page_report.largest_contentful_paint_score",
      "defaultMessage": "LCP"
    },
    "modelsLighthouseReport::PageReportCumulativeLayoutShiftScore": {
      "id": "models.lighthouse_report/page_report.cumulative_layout_shift_score",
      "defaultMessage": "CLS"
    },
    "modelsLighthouseReport::PageReportTotalBlockingTimeScore": {
      "id": "models.lighthouse_report/page_report.total_blocking_time_score",
      "defaultMessage": "TBT"
    },
    "modelsLighthouseReport::PageReportPerformanceScore": {
      "id": "models.lighthouse_report/page_report.performance_score",
      "defaultMessage": "Performance"
    },
    "modelsLighthouseReport::PageReportSeoScore": {
      "id": "models.lighthouse_report/page_report.seo_score",
      "defaultMessage": "SEO"
    },
    "modelsLighthouseReportSelf": {
      "id": "models.lighthouse_report.self",
      "defaultMessage": "Lighthouse Report"
    },
    "modelsLighthouseReportMinAccessibilityScoreDesktop": {
      "id": "models.lighthouse_report.min_accessibility_score_desktop",
      "defaultMessage": "Accessibility"
    },
    "modelsLighthouseReportMinBestPracticesScoreDesktop": {
      "id": "models.lighthouse_report.min_best_practices_score_desktop",
      "defaultMessage": "Best Practices"
    },
    "modelsLighthouseReportMinCumulativeLayoutShiftScoreDesktop": {
      "id": "models.lighthouse_report.min_cumulative_layout_shift_score_desktop",
      "defaultMessage": "CLS"
    },
    "modelsLighthouseReportMinCumulativeLayoutShiftScoreMobile": {
      "id": "models.lighthouse_report.min_cumulative_layout_shift_score_mobile",
      "defaultMessage": "CLS"
    },
    "modelsLighthouseReportMinLargestContentfulPaintScoreDesktop": {
      "id": "models.lighthouse_report.min_largest_contentful_paint_score_desktop",
      "defaultMessage": "LCP"
    },
    "modelsLighthouseReportMinLargestContentfulPaintScoreMobile": {
      "id": "models.lighthouse_report.min_largest_contentful_paint_score_mobile",
      "defaultMessage": "LCP"
    },
    "modelsLighthouseReportMinTotalBlockingTimeScoreDesktop": {
      "id": "models.lighthouse_report.min_total_blocking_time_score_desktop",
      "defaultMessage": "TBT"
    },
    "modelsLighthouseReportMinTotalBlockingTimeScoreMobile": {
      "id": "models.lighthouse_report.min_total_blocking_time_score_mobile",
      "defaultMessage": "TBT"
    },
    "modelsLighthouseReportMinPerformanceScoreDesktop": {
      "id": "models.lighthouse_report.min_performance_score_desktop",
      "defaultMessage": "Performance"
    },
    "modelsLighthouseReportMinSeoScoreDesktop": {
      "id": "models.lighthouse_report.min_seo_score_desktop",
      "defaultMessage": "SEO"
    },
    "modelsLighthouseReportMinAccessibilityScoreMobile": {
      "id": "models.lighthouse_report.min_accessibility_score_mobile",
      "defaultMessage": "Accessibility"
    },
    "modelsLighthouseReportMinBestPracticesScoreMobile": {
      "id": "models.lighthouse_report.min_best_practices_score_mobile",
      "defaultMessage": "Best Practices"
    },
    "modelsLighthouseReportMinPerformanceScoreMobile": {
      "id": "models.lighthouse_report.min_performance_score_mobile",
      "defaultMessage": "Performance"
    },
    "modelsLighthouseReportMinSeoScoreMobile": {
      "id": "models.lighthouse_report.min_seo_score_mobile",
      "defaultMessage": "SEO"
    },
    "modelsLighthouseReportReportAt": {
      "id": "models.lighthouse_report.report_at",
      "defaultMessage": "Generated"
    },
    "modelsLighthouseReportRestaurantUrl": {
      "id": "models.lighthouse_report.restaurant_url",
      "defaultMessage": "Domain"
    },
    "modelsAnnouncementCreatedAt": {
      "id": "models.announcement.created_at",
      "defaultMessage": "Created"
    },
    "modelsAnnouncementStatus": {
      "id": "models.announcement.status",
      "defaultMessage": "Status"
    },
    "modelsRootThemeTemplateSelf": {
      "id": "models.root_theme_template.self",
      "defaultMessage": "Theme Template"
    },
    "modelsRootThemeTemplateCreatedAt": {
      "id": "models.root_theme_template.created_at",
      "defaultMessage": "Created"
    },
    "modelsRootThemeTemplateDescription": {
      "id": "models.root_theme_template.description",
      "defaultMessage": "Description"
    },
    "modelsRootThemeTemplateIsEnabled": {
      "id": "models.root_theme_template.is_enabled",
      "defaultMessage": "Enabled"
    },
    "modelsRootThemeTemplateIsRootAccessOnly": {
      "id": "models.root_theme_template.is_root_access_only",
      "defaultMessage": "Root Access Only"
    },
    "modelsRootThemeTemplateName": {
      "id": "models.root_theme_template.name",
      "defaultMessage": "Name"
    },
    "modelsRootThemeTemplatePosition": {
      "id": "models.root_theme_template.position",
      "defaultMessage": "Order"
    },
    "modelsRootThemeTemplateRestaurantId": {
      "id": "models.root_theme_template.restaurant_id",
      "defaultMessage": "Template Restaurant"
    },
    "modelsConciergeGoalSelf": {
      "id": "models.concierge_goal.self",
      "defaultMessage": "Boost Goal"
    },
    "modelsConciergeGoalSelves": {
      "id": "models.concierge_goal.selves",
      "defaultMessage": "Boost Goals"
    },
    "modelsConciergeGoalGoogleAds": {
      "id": "models.concierge_goal.google_ads",
      "defaultMessage": "Google Ads"
    },
    "modelsConciergeGoalMessages": {
      "id": "models.concierge_goal.messages",
      "defaultMessage": "Messages"
    },
    "modelsConciergeGoalSocialPosts": {
      "id": "models.concierge_goal.social_posts",
      "defaultMessage": "Social Posts"
    },
    "modelsGooglePostLocationLocationName": {
      "id": "models.google_post_location.location_name",
      "defaultMessage": "Location"
    },
    "modelsGooglePostLocationSelf": {
      "id": "models.google_post_location.self",
      "defaultMessage": "Local Post"
    },
    "modelsGooglePostLocationState": {
      "id": "models.google_post_location.state",
      "defaultMessage": "Status"
    },
    "modelsPhotoSelf": {
      "id": "models.photo.self",
      "defaultMessage": "Photo"
    },
    "modelsBillingCustomerSelves": {
      "id": "models.billing_customer.selves",
      "defaultMessage": "Billing Customers"
    },
    "modelsBillingCustomerCurrentPeriodEnd": {
      "id": "models.billing_customer.current_period_end",
      "defaultMessage": "End of Period"
    },
    "modelsBillingCustomerDefaultPayment": {
      "id": "models.billing_customer.default_payment",
      "defaultMessage": "Select a payment method"
    },
    "modelsBillingCustomerDelinquent": {
      "id": "models.billing_customer.delinquent",
      "defaultMessage": "Delinquent"
    },
    "modelsBillingCustomerIsRestaurantAddress": {
      "id": "models.billing_customer.is_restaurant_address",
      "defaultMessage": "Same as restaurant address"
    },
    "modelsBillingCustomerFirstInvoiceDate": {
      "id": "models.billing_customer.first_invoice_date",
      "defaultMessage": "First Invoice Date"
    },
    "modelsBillingCustomerName": {
      "id": "models.billing_customer.name",
      "defaultMessage": "Restaurant Name"
    },
    "modelsBillingCustomerPaymentType": {
      "id": "models.billing_customer.payment_type",
      "defaultMessage": "Payment Method"
    },
    "modelsBillingCustomerStripeId": {
      "id": "models.billing_customer.stripe_id",
      "defaultMessage": "Stripe ID"
    },
    "modelsBillingCustomerUnpaidInvoiceBalance": {
      "id": "models.billing_customer.unpaid_invoice_balance",
      "defaultMessage": "Unpaid Invoice Balance"
    },
    "modelsRootSectionTypeSelf": {
      "id": "models.root_section_type.self",
      "defaultMessage": "Page Section"
    },
    "modelsRootSectionTypeCategoryName": {
      "id": "models.root_section_type.category_name",
      "defaultMessage": "Category"
    },
    "modelsRootSectionTypeCreatedAt": {
      "id": "models.root_section_type.created_at",
      "defaultMessage": "Created"
    },
    "modelsRootSectionTypeDescription": {
      "id": "models.root_section_type.description",
      "defaultMessage": "Description"
    },
    "modelsRootSectionTypeIsEnabled": {
      "id": "models.root_section_type.is_enabled",
      "defaultMessage": "Enabled"
    },
    "modelsRootSectionTypeName": {
      "id": "models.root_section_type.name",
      "defaultMessage": "Name"
    },
    "modelsRootSectionTypeTemplateHtml": {
      "id": "models.root_section_type.template_html",
      "defaultMessage": "Template HTML"
    },
    "modelsRootThemeSelf": {
      "id": "models.root_theme.self",
      "defaultMessage": "Root Theme"
    },
    "modelsRootThemeCreatedAt": {
      "id": "models.root_theme.created_at",
      "defaultMessage": "Created"
    },
    "modelsRootThemeCustomCss": {
      "id": "models.root_theme.custom_css",
      "defaultMessage": "Custom Styles (CSS)"
    },
    "modelsRootThemeIsDefault": {
      "id": "models.root_theme.is_default",
      "defaultMessage": "Default Theme"
    },
    "modelsRootThemeIsEnabled": {
      "id": "models.root_theme.is_enabled",
      "defaultMessage": "Enabled"
    },
    "modelsRootThemeName": {
      "id": "models.root_theme.name",
      "defaultMessage": "Name"
    },
    "modelsFollowerTagFollowersCount": {
      "id": "models.follower_tag.followers_count",
      "defaultMessage": "Followers"
    },
    "modelsTwilioMessageCreatedAt": {
      "id": "models.twilio_message.created_at",
      "defaultMessage": "Message Time"
    },
    "modelsTwilioMessageFromNumber": {
      "id": "models.twilio_message.from_number",
      "defaultMessage": "From Number"
    },
    "modelsTwilioMessageIsSent": {
      "id": "models.twilio_message.is_sent",
      "defaultMessage": "Sent"
    },
    "modelsTwilioMessageMessage": {
      "id": "models.twilio_message.message",
      "defaultMessage": "Message"
    },
    "modelsTwilioMessageSelfs": {
      "id": "models.twilio_message.selfs",
      "defaultMessage": "Messages"
    },
    "modelsTwilioMessageSentAt": {
      "id": "models.twilio_message.sent_at",
      "defaultMessage": "Sent At"
    },
    "modelsTwilioMessageUser": {
      "id": "models.twilio_message.user",
      "defaultMessage": "Sent To"
    },
    "modelsGoogleAdsKeywordsReportClicks": {
      "id": "models.google_ads_keywords_report.clicks",
      "defaultMessage": "Clicks"
    },
    "modelsGoogleAdsKeywordsReportConversions": {
      "id": "models.google_ads_keywords_report.conversions",
      "defaultMessage": "Conversions"
    },
    "modelsGoogleAdsKeywordsReportCost": {
      "id": "models.google_ads_keywords_report.cost",
      "defaultMessage": "Cost"
    },
    "modelsGoogleAdsKeywordsReportClickThroughRate": {
      "id": "models.google_ads_keywords_report.click_through_rate",
      "defaultMessage": "CTR"
    },
    "modelsGoogleAdsKeywordsReportKeyword": {
      "id": "models.google_ads_keywords_report.keyword",
      "defaultMessage": "Keyword"
    },
    "modelsGoogleAdsKeywordsReportImpressions": {
      "id": "models.google_ads_keywords_report.impressions",
      "defaultMessage": "Impressions"
    },
    "modelsGoogleAdsKeywordsReportSelf": {
      "id": "models.google_ads_keywords_report.self",
      "defaultMessage": "Keywords Performance"
    },
    "modelsCustomPageTransitionTiming": {
      "id": "models.custom_page.transition_timing",
      "defaultMessage": "Transition Timing"
    },
    "modelsCustomPageTransitionTimingTypeSeconds": {
      "id": "models.custom_page.transition_timing_type.seconds",
      "defaultMessage": "s"
    },
    "modelsCustomPageTransitionTimingTypeMilliseconds": {
      "id": "models.custom_page.transition_timing_type.milliseconds",
      "defaultMessage": "ms"
    },
    "modelsCustomPageHeaderTransitionTypesHtFade": {
      "id": "models.custom_page.header_transition_types.ht_fade",
      "defaultMessage": "Fade"
    },
    "modelsCustomPageHeaderTransitionTypesHtSlide": {
      "id": "models.custom_page.header_transition_types.ht_slide",
      "defaultMessage": "Slide"
    },
    "modelsCustomPageHeaderImageSettingsStatic": {
      "id": "models.custom_page.header_image_settings.static",
      "defaultMessage": "Static"
    },
    "modelsCustomPageHeaderImageSettingsFixed": {
      "id": "models.custom_page.header_image_settings.fixed",
      "defaultMessage": "Fixed"
    },
    "modelsCustomPageHeaderImageSettingsParallax": {
      "id": "models.custom_page.header_image_settings.parallax",
      "defaultMessage": "Parallax"
    },
    "modelsCustomPageHeaderImageSettingsImages": {
      "id": "models.custom_page.header_image_settings.Images",
      "defaultMessage": "Images"
    },
    "modelsCustomPageHeaderImageSettingsVideo": {
      "id": "models.custom_page.header_image_settings.Video",
      "defaultMessage": "Video"
    },
    "modelsRestaurant::NavLinkLinkTypesBlogType": {
      "id": "models.restaurant/nav_link.link_types.blog_type",
      "defaultMessage": "Blog"
    },
    "modelsRestaurant::NavLinkLinkTypesCustomType": {
      "id": "models.restaurant/nav_link.link_types.custom_type",
      "defaultMessage": "Custom URL"
    },
    "modelsRestaurant::NavLinkLinkTypesCustomPageType": {
      "id": "models.restaurant/nav_link.link_types.custom_page_type",
      "defaultMessage": "Page"
    },
    "modelsRestaurant::NavLinkLinkTypesMenuType": {
      "id": "models.restaurant/nav_link.link_types.menu_type",
      "defaultMessage": "Menus"
    },
    "modelsRestaurant::NavLinkLinkTypesOrderingAppType": {
      "id": "models.restaurant/nav_link.link_types.ordering_app_type",
      "defaultMessage": "Online Ordering"
    },
    "modelsRestaurant::NavLinkLinkTypesCateringOrderingAppType": {
      "id": "models.restaurant/nav_link.link_types.catering_ordering_app_type",
      "defaultMessage": "Catering"
    },
    "modelsRestaurant::NavLinkLinkTypesReservationsType": {
      "id": "models.restaurant/nav_link.link_types.reservations_type",
      "defaultMessage": "Reservations"
    },
    "modelsRestaurant::NavLinkLinkTypesReviewsType": {
      "id": "models.restaurant/nav_link.link_types.reviews_type",
      "defaultMessage": "Reviews"
    },
    "modelsRestaurant::NavLinkLinkTypesSignUpType": {
      "id": "models.restaurant/nav_link.link_types.sign_up_type",
      "defaultMessage": "Sign Up"
    },
    "modelsRestaurant::NavLinkLinkTypesSignInType": {
      "id": "models.restaurant/nav_link.link_types.sign_in_type",
      "defaultMessage": "Sign In"
    },
    "modelsRestaurant::NavLinkLinkTypesWaitlistType": {
      "id": "models.restaurant/nav_link.link_types.waitlist_type",
      "defaultMessage": "Waitlist"
    },
    "modelsCustomPage::SectionSettingsPanelContent": {
      "id": "models.custom_page/section.settings_panel.content",
      "defaultMessage": "Content"
    },
    "modelsCustomPage::SectionSettingsPanelSettings": {
      "id": "models.custom_page/section.settings_panel.settings",
      "defaultMessage": "Settings"
    },
    "modelsCustomPage::SectionSettingsPanelAdvancedSettings": {
      "id": "models.custom_page/section.settings_panel.advanced",
      "defaultMessage": "Advanced Settings"
    },
    "modelsCustomPage::SectionSettingsPanelContentSettings": {
      "id": "models.custom_page/section.settings_panel.content_settings",
      "defaultMessage": "Content Settings"
    },
    "modelsCustomPage::SectionSettingsPanelShowSectionNameList": {
      "id": "models.custom_page/section.settings_panel.show_section_name_list",
      "defaultMessage": "This name will show in the section list"
    },
    "modelsCustomPage::SectionSettingsPanelBackgroundSettings": {
      "id": "models.custom_page/section.settings_panel.background",
      "defaultMessage": "Background"
    },
    "modelsCustomPage::SectionSettingsPanelSaveSection": {
      "id": "models.custom_page/section.settings_panel.save_section",
      "defaultMessage": "Save Section"
    },
    "modelsCustomPage::SectionSettingsPanelSectionSaved": {
      "id": "models.custom_page/section.settings_panel.section_saved",
      "defaultMessage": "Section Saved!"
    },
    "modelsCustomPage::SectionAboutContentMaxWidthsAcmx100": {
      "id": "models.custom_page/section.about_content_max_widths.acmx_100",
      "defaultMessage": "100%%"
    },
    "modelsCustomPage::SectionAboutContentMaxWidthsAcmx75": {
      "id": "models.custom_page/section.about_content_max_widths.acmx_75",
      "defaultMessage": "75%%"
    },
    "modelsCustomPage::SectionAboutContentMaxWidthsAcmx50": {
      "id": "models.custom_page/section.about_content_max_widths.acmx_50",
      "defaultMessage": "50%%"
    },
    "modelsCustomPage::SectionAboutFontAlignmentsLeft": {
      "id": "models.custom_page/section.about_font_alignments.left",
      "defaultMessage": "Left"
    },
    "modelsCustomPage::SectionAboutFontAlignmentsCenter": {
      "id": "models.custom_page/section.about_font_alignments.center",
      "defaultMessage": "Center"
    },
    "modelsCustomPage::SectionAboutFontAlignmentsRight": {
      "id": "models.custom_page/section.about_font_alignments.right",
      "defaultMessage": "Right"
    },
    "modelsCustomPage::SectionAboutPhotoPositionsApLeft": {
      "id": "models.custom_page/section.about_photo_positions.ap_left",
      "defaultMessage": "Left"
    },
    "modelsCustomPage::SectionAboutPhotoPositionsApRight": {
      "id": "models.custom_page/section.about_photo_positions.ap_right",
      "defaultMessage": "Right"
    },
    "modelsCustomPage::SectionAboutPhotoPositionsApTop": {
      "id": "models.custom_page/section.about_photo_positions.ap_top",
      "defaultMessage": "Top"
    },
    "modelsCustomPage::SectionAboutPhotoPositionsApBottom": {
      "id": "models.custom_page/section.about_photo_positions.ap_bottom",
      "defaultMessage": "Bottom"
    },
    "modelsCustomPage::SectionAnnouncementFontAlignmentsAfaLeft": {
      "id": "models.custom_page/section.announcement_font_alignments.afa_left",
      "defaultMessage": "Left"
    },
    "modelsCustomPage::SectionAnnouncementFontAlignmentsAfaCenter": {
      "id": "models.custom_page/section.announcement_font_alignments.afa_center",
      "defaultMessage": "Center"
    },
    "modelsCustomPage::SectionAnnouncementFontAlignmentsAfaRight": {
      "id": "models.custom_page/section.announcement_font_alignments.afa_right",
      "defaultMessage": "Right"
    },
    "modelsCustomPage::SectionAnnouncementHint": {
      "id": "models.custom_page/section.announcement_hint",
      "defaultMessage": "This section will dynamically display the latest featured announcement."
    },
    "modelsCustomPage::SectionGalleryImageSelf": {
      "id": "models.custom_page/section.gallery_image.self",
      "defaultMessage": "Gallery Images"
    },
    "modelsCustomPage::SectionGalleryImageDescription": {
      "id": "models.custom_page/section.gallery_image.description",
      "defaultMessage": "Description"
    },
    "modelsCustomPage::SectionGalleryImageAddBtn": {
      "id": "models.custom_page/section.gallery_image.add_button",
      "defaultMessage": "Add Gallery Image"
    },
    "modelsCustomPage::SectionGalleryTypesSliderGallery": {
      "id": "models.custom_page/section.gallery_types.slider_gallery",
      "defaultMessage": "Slider"
    },
    "modelsCustomPage::SectionGalleryTypesMasonryGallery": {
      "id": "models.custom_page/section.gallery_types.masonry_gallery",
      "defaultMessage": "Masonry"
    },
    "modelsCustomPage::SectionGalleryTypesGridGallery": {
      "id": "models.custom_page/section.gallery_types.grid_gallery",
      "defaultMessage": "Grid"
    },
    "modelsCustomPage::SectionMapThemesStreets": {
      "id": "models.custom_page/section.map_themes.streets",
      "defaultMessage": "Light"
    },
    "modelsCustomPage::SectionMapThemesDark": {
      "id": "models.custom_page/section.map_themes.dark",
      "defaultMessage": "Dark"
    },
    "modelsCustomPage::SectionDefaultZoomLevels1": {
      "id": "models.custom_page/section.default_zoom_levels.1",
      "defaultMessage": "1 - World"
    },
    "modelsCustomPage::SectionDefaultZoomLevels2": {
      "id": "models.custom_page/section.default_zoom_levels.2",
      "defaultMessage": "2"
    },
    "modelsCustomPage::SectionDefaultZoomLevels3": {
      "id": "models.custom_page/section.default_zoom_levels.3",
      "defaultMessage": "3"
    },
    "modelsCustomPage::SectionDefaultZoomLevels4": {
      "id": "models.custom_page/section.default_zoom_levels.4",
      "defaultMessage": "4"
    },
    "modelsCustomPage::SectionDefaultZoomLevels5": {
      "id": "models.custom_page/section.default_zoom_levels.5",
      "defaultMessage": "5 - Continent"
    },
    "modelsCustomPage::SectionDefaultZoomLevels6": {
      "id": "models.custom_page/section.default_zoom_levels.6",
      "defaultMessage": "6"
    },
    "modelsCustomPage::SectionDefaultZoomLevels7": {
      "id": "models.custom_page/section.default_zoom_levels.7",
      "defaultMessage": "7"
    },
    "modelsCustomPage::SectionDefaultZoomLevels8": {
      "id": "models.custom_page/section.default_zoom_levels.8",
      "defaultMessage": "8"
    },
    "modelsCustomPage::SectionDefaultZoomLevels9": {
      "id": "models.custom_page/section.default_zoom_levels.9",
      "defaultMessage": "9"
    },
    "modelsCustomPage::SectionDefaultZoomLevels10": {
      "id": "models.custom_page/section.default_zoom_levels.10",
      "defaultMessage": "10 - City"
    },
    "modelsCustomPage::SectionDefaultZoomLevels11": {
      "id": "models.custom_page/section.default_zoom_levels.11",
      "defaultMessage": "11"
    },
    "modelsCustomPage::SectionDefaultZoomLevels12": {
      "id": "models.custom_page/section.default_zoom_levels.12",
      "defaultMessage": "12"
    },
    "modelsCustomPage::SectionDefaultZoomLevels13": {
      "id": "models.custom_page/section.default_zoom_levels.13",
      "defaultMessage": "13"
    },
    "modelsCustomPage::SectionDefaultZoomLevels14": {
      "id": "models.custom_page/section.default_zoom_levels.14",
      "defaultMessage": "14"
    },
    "modelsCustomPage::SectionDefaultZoomLevels15": {
      "id": "models.custom_page/section.default_zoom_levels.15",
      "defaultMessage": "15 - Streets (default)"
    },
    "modelsCustomPage::SectionDefaultZoomLevels16": {
      "id": "models.custom_page/section.default_zoom_levels.16",
      "defaultMessage": "16"
    },
    "modelsCustomPage::SectionDefaultZoomLevels17": {
      "id": "models.custom_page/section.default_zoom_levels.17",
      "defaultMessage": "17"
    },
    "modelsCustomPage::SectionDefaultZoomLevels18": {
      "id": "models.custom_page/section.default_zoom_levels.18",
      "defaultMessage": "18"
    },
    "modelsCustomPage::SectionDefaultZoomLevels19": {
      "id": "models.custom_page/section.default_zoom_levels.19",
      "defaultMessage": "19"
    },
    "modelsCustomPage::SectionDefaultZoomLevels20": {
      "id": "models.custom_page/section.default_zoom_levels.20",
      "defaultMessage": "20 - Buildings"
    },
    "modelsCustomPage::SectionLocationSectionLayoutsLlsBottom": {
      "id": "models.custom_page/section.location_section_layouts.lls_bottom",
      "defaultMessage": "Bottom"
    },
    "modelsCustomPage::SectionLocationSectionLayoutsLlsLeft": {
      "id": "models.custom_page/section.location_section_layouts.lls_left",
      "defaultMessage": "Left"
    },
    "modelsCustomPage::SectionCartTypesDefaultCartType": {
      "id": "models.custom_page/section.cart_types.default_cart_type",
      "defaultMessage": "Pickup Menus"
    },
    "modelsCustomPage::SectionCartTypesCateringCartType": {
      "id": "models.custom_page/section.cart_types.catering_cart_type",
      "defaultMessage": "Catering Menus"
    },
    "modelsPrintLayout::TextSectionTextAlignmentsTaCenter": {
      "id": "models.print_layout/text_section.text_alignments.ta_center",
      "defaultMessage": "Center"
    },
    "modelsPrintLayout::TextSectionTextAlignmentsTaLeft": {
      "id": "models.print_layout/text_section.text_alignments.ta_left",
      "defaultMessage": "Left"
    },
    "modelsPrintLayout::TextSectionTextAlignmentsTaRight": {
      "id": "models.print_layout/text_section.text_alignments.ta_right",
      "defaultMessage": "Right"
    },
    "modelsPrintLayout::TextSectionFontSizes0": {
      "id": "models.print_layout/text_section.font_sizes.0",
      "defaultMessage": "XS"
    },
    "modelsPrintLayout::TextSectionFontSizes1": {
      "id": "models.print_layout/text_section.font_sizes.1",
      "defaultMessage": "Sm"
    },
    "modelsPrintLayout::TextSectionFontSizes2": {
      "id": "models.print_layout/text_section.font_sizes.2",
      "defaultMessage": "Md"
    },
    "modelsPrintLayout::TextSectionFontSizes3": {
      "id": "models.print_layout/text_section.font_sizes.3",
      "defaultMessage": "Lg"
    },
    "modelsPrintLayout::TextSectionFontSizes4": {
      "id": "models.print_layout/text_section.font_sizes.4",
      "defaultMessage": "XL"
    },
    "modelsPrintLayoutMenuItemSizeExtraAlignmentsMiseaAfterDescription": {
      "id": "models.print_layout.menu_item_size_extra_alignments.misea_after_description",
      "defaultMessage": "After Description"
    },
    "modelsPrintLayoutMenuItemSizeExtraAlignmentsMiseaAfterPrice": {
      "id": "models.print_layout.menu_item_size_extra_alignments.misea_after_price",
      "defaultMessage": "After Price"
    },
    "modelsPrintLayoutMenuItemSizeExtraAlignmentsMiseaNone": {
      "id": "models.print_layout.menu_item_size_extra_alignments.misea_none",
      "defaultMessage": "Hidden"
    },
    "modelsPrintLayoutMenuItemPriceAlignmentsMipaColumn": {
      "id": "models.print_layout.menu_item_price_alignments.mipa_column",
      "defaultMessage": "Column"
    },
    "modelsPrintLayoutMenuItemPriceAlignmentsMipaInlineDescription": {
      "id": "models.print_layout.menu_item_price_alignments.mipa_inline_description",
      "defaultMessage": "After Description"
    },
    "modelsPrintLayoutMenuItemPriceAlignmentsMipaInlineName": {
      "id": "models.print_layout.menu_item_price_alignments.mipa_inline_name",
      "defaultMessage": "After Name"
    },
    "modelsPrintLayoutMenuItemPriceAlignmentsMipaNone": {
      "id": "models.print_layout.menu_item_price_alignments.mipa_none",
      "defaultMessage": "Hidden"
    },
    "modelsPrintLayoutMenuItemDishTagAlignmentsMidtaAfterDescription": {
      "id": "models.print_layout.menu_item_item_tag_alignments.midta_after_description",
      "defaultMessage": "After Description"
    },
    "modelsPrintLayoutMenuItemDishTagAlignmentsMidtaInlineName": {
      "id": "models.print_layout.menu_item_item_tag_alignments.midta_inline_name",
      "defaultMessage": "After Name"
    },
    "modelsPrintLayoutMenuItemDishTagAlignmentsMidtaNone": {
      "id": "models.print_layout.menu_item_item_tag_alignments.midta_none",
      "defaultMessage": "Hidden"
    },
    "modelsPrintLayout::MenuSectionColumnCounts1": {
      "id": "models.print_layout/menu_section.column_counts.1",
      "defaultMessage": "1 column"
    },
    "modelsPrintLayout::MenuSectionColumnCounts2": {
      "id": "models.print_layout/menu_section.column_counts.2",
      "defaultMessage": "2 columns"
    },
    "modelsPrintLayout::MenuSectionColumnCounts3": {
      "id": "models.print_layout/menu_section.column_counts.3",
      "defaultMessage": "3 columns"
    },
    "modelsPrintLayout::MenuSectionColumnCounts4": {
      "id": "models.print_layout/menu_section.column_counts.4",
      "defaultMessage": "4 columns"
    },
    "modelsPrintLayout::MenuSectionTextAlignmentsTaCenter": {
      "id": "models.print_layout/menu_section.text_alignments.ta_center",
      "defaultMessage": "Center"
    },
    "modelsPrintLayout::MenuSectionTextAlignmentsTaLeft": {
      "id": "models.print_layout/menu_section.text_alignments.ta_left",
      "defaultMessage": "Left"
    },
    "modelsPrintLayout::MenuSectionTextAlignmentsTaRight": {
      "id": "models.print_layout/menu_section.text_alignments.ta_right",
      "defaultMessage": "Right"
    },
    "modelsPrintLayout::MenuSectionMenuSectionBorderStylesMsbsNone": {
      "id": "models.print_layout/menu_section.menu_section_border_styles.msbs_none",
      "defaultMessage": "None"
    },
    "modelsPrintLayout::MenuSectionMenuSectionBorderStylesMsbsSolid": {
      "id": "models.print_layout/menu_section.menu_section_border_styles.msbs_solid",
      "defaultMessage": "Solid"
    },
    "modelsPrintLayout::MenuSectionMenuSectionNameFlowsMsnfHorizontal": {
      "id": "models.print_layout/menu_section.menu_section_name_flows.msnf_horizontal",
      "defaultMessage": "Horizontal"
    },
    "modelsPrintLayout::MenuSectionMenuSectionNameFlowsMsnfVertical": {
      "id": "models.print_layout/menu_section.menu_section_name_flows.msnf_vertical",
      "defaultMessage": "Vertical"
    },
    "modelsPrintLayout::MenuSectionMenuSectionNameFlowsMsnfNone": {
      "id": "models.print_layout/menu_section.menu_section_name_flows.msnf_none",
      "defaultMessage": "Hidden"
    },
    "modelsPrintLayoutMenuItemNameFontCasesMinfcUppercase": {
      "id": "models.print_layout.menu_item_name_font_cases.minfc_uppercase",
      "defaultMessage": "Uppercase"
    },
    "modelsPrintLayoutMenuItemNameFontCasesMinfcCapitalize": {
      "id": "models.print_layout.menu_item_name_font_cases.minfc_capitalize",
      "defaultMessage": "Capitalize"
    },
    "modelsPrintLayoutMenuItemNameFontCasesMinfcLowercase": {
      "id": "models.print_layout.menu_item_name_font_cases.minfc_lowercase",
      "defaultMessage": "Lowercase"
    },
    "modelsPrintLayoutMenuItemNameFontCasesMinfcNone": {
      "id": "models.print_layout.menu_item_name_font_cases.minfc_none",
      "defaultMessage": "None"
    },
    "modelsPrintLayoutMenuItemDescriptionStylesMidsItalic": {
      "id": "models.print_layout.menu_item_description_styles.mids_italic",
      "defaultMessage": "Italic"
    },
    "modelsPrintLayoutMenuItemDescriptionStylesMidsNone": {
      "id": "models.print_layout.menu_item_description_styles.mids_none",
      "defaultMessage": "Normal"
    },
    "modelsRestaurantOnboardingStatus": {
      "id": "models.restaurant.onboarding_status",
      "defaultMessage": "Onboarding Status"
    },
    "modelsRestaurantOnboardingStatusesOsStart": {
      "id": "models.restaurant.onboarding_statuses.os_start",
      "defaultMessage": "Joined Popmenu"
    },
    "modelsRestaurantOnboardingStatusesOsBuild": {
      "id": "models.restaurant.onboarding_statuses.os_build",
      "defaultMessage": "Site Build"
    },
    "modelsRestaurantOnboardingStatusesOsPreview": {
      "id": "models.restaurant.onboarding_statuses.os_preview",
      "defaultMessage": "Preview"
    },
    "modelsRestaurantOnboardingStatusesOsLaunch": {
      "id": "models.restaurant.onboarding_statuses.os_launch",
      "defaultMessage": "Ready to Launch"
    },
    "modelsRestaurantStatusesStatusClient": {
      "id": "models.restaurant.statuses.status_client",
      "defaultMessage": "Client (Enabled)"
    },
    "modelsRestaurantStatusesStatusDemo": {
      "id": "models.restaurant.statuses.status_demo",
      "defaultMessage": "Demo"
    },
    "modelsRestaurantStatusesStatusDemoTemplate": {
      "id": "models.restaurant.statuses.status_demo_template",
      "defaultMessage": "Demo Template"
    },
    "modelsRestaurantStatusesStatusClientStaging": {
      "id": "models.restaurant.statuses.status_client_staging",
      "defaultMessage": "Client (Staging)"
    },
    "modelsRestaurantStatusesStatusClientSuspended": {
      "id": "models.restaurant.statuses.status_client_suspended",
      "defaultMessage": "Client (Suspended)"
    },
    "modelsRestaurantStatusesStatusTest": {
      "id": "models.restaurant.statuses.status_test",
      "defaultMessage": "Example"
    },
    "modelsRestaurantStatusesStatusDisabled": {
      "id": "models.restaurant.statuses.status_disabled",
      "defaultMessage": "Disabled"
    },
    "modelsRestaurantStripeBillingSources": {
      "id": "models.restaurant.stripe_billing_sources",
      "defaultMessage": "Stripe Billing"
    },
    "modelsRestaurantStripeBillingSourcesDefault": {
      "id": "models.restaurant.stripe_billing_sources.sbs_default",
      "defaultMessage": "Default"
    },
    "modelsRestaurantStripeBillingSourcesUk": {
      "id": "models.restaurant.stripe_billing_sources.sbs_uk",
      "defaultMessage": "United Kingdom"
    },
    "modelsRestaurant::OnboardingInfoBodyFontId": {
      "id": "models.restaurant/onboarding_info.body_font_id",
      "defaultMessage": "Body Font"
    },
    "modelsRestaurant::OnboardingInfoHeadingFontId": {
      "id": "models.restaurant/onboarding_info.heading_font_id",
      "defaultMessage": "Heading Font"
    },
    "modelsRestaurant::OnboardingInfo::LinkCreatedAt": {
      "id": "models.restaurant/onboarding_info/link.created_at",
      "defaultMessage": "Created"
    },
    "modelsRestaurant::OnboardingInfo::LinkUrl": {
      "id": "models.restaurant/onboarding_info/link.url",
      "defaultMessage": "URL"
    },
    "modelsRestaurant::ServedMealMealTypesMealBreakfast": {
      "id": "models.restaurant/served_meal.meal_types.meal_breakfast",
      "defaultMessage": "Breakfast"
    },
    "modelsRestaurant::ServedMealMealTypesMealLunch": {
      "id": "models.restaurant/served_meal.meal_types.meal_lunch",
      "defaultMessage": "Lunch"
    },
    "modelsRestaurant::ServedMealMealTypesMealBrunch": {
      "id": "models.restaurant/served_meal.meal_types.meal_brunch",
      "defaultMessage": "Brunch"
    },
    "modelsRestaurant::ServedMealMealTypesMealDinner": {
      "id": "models.restaurant/served_meal.meal_types.meal_dinner",
      "defaultMessage": "Dinner"
    },
    "modelsRestaurant::ServedMealMealTypesMealHappyHour": {
      "id": "models.restaurant/served_meal.meal_types.meal_happy_hour",
      "defaultMessage": "Happy Hour"
    },
    "modelsRestaurant::ServedMealMealTypesMealLateNight": {
      "id": "models.restaurant/served_meal.meal_types.meal_late_night",
      "defaultMessage": "Late Night"
    },
    "modelsMenuImportStatusesFailedStatus": {
      "id": "models.menu_import.statuses.failed_status",
      "defaultMessage": "Failed"
    },
    "modelsMenuImportStatusesImportedStatus": {
      "id": "models.menu_import.statuses.imported_status",
      "defaultMessage": "Imported"
    },
    "modelsMenuImportStatusesStartedStatus": {
      "id": "models.menu_import.statuses.started_status",
      "defaultMessage": "Processing"
    },
    "modelsMenuImportStatusesUnprocessdStatus": {
      "id": "models.menu_import.statuses.unprocessd_status",
      "defaultMessage": "Queued"
    },
    "modelsRestaurant::SocialHandleFormAddButton": {
      "id": "models.restaurant/social_handle_form_add_button",
      "defaultMessage": "Add Social Handle"
    },
    "modelsRestaurant::SocialHandleSocialTypesFacebook": {
      "id": "models.restaurant/social_handle.social_types.facebook",
      "defaultMessage": "Facebook"
    },
    "modelsRestaurant::SocialHandleSocialTypesInstagram": {
      "id": "models.restaurant/social_handle.social_types.instagram",
      "defaultMessage": "Instagram"
    },
    "modelsRestaurant::SocialHandleSocialTypesLinkedin": {
      "id": "models.restaurant/social_handle.social_types.linkedin",
      "defaultMessage": "Linkedin"
    },
    "modelsRestaurant::SocialHandleSocialTypesPinterest": {
      "id": "models.restaurant/social_handle.social_types.pinterest",
      "defaultMessage": "Pinterest"
    },
    "modelsRestaurant::SocialHandleSocialTypesSnapchat": {
      "id": "models.restaurant/social_handle.social_types.snapchat",
      "defaultMessage": "Snapchat"
    },
    "modelsRestaurant::SocialHandleSocialTypesTikTok": {
      "id": "models.restaurant/social_handle.social_types.tiktok",
      "defaultMessage": "TikTok"
    },
    "modelsRestaurant::SocialHandleSocialTypesTwitter": {
      "id": "models.restaurant/social_handle.social_types.twitter",
      "defaultMessage": "Twitter"
    },
    "modelsRestaurant::SocialHandleSocialTypesYoutube": {
      "id": "models.restaurant/social_handle.social_types.youtube",
      "defaultMessage": "Youtube"
    },
    "modelsRestaurant::CustomScriptScriptTypesTracking": {
      "id": "models.restaurant/custom_script.script_types.tracking",
      "defaultMessage": "Tracking"
    },
    "modelsRestaurant::CustomScriptScriptTypesConversion": {
      "id": "models.restaurant/custom_script.script_types.conversion",
      "defaultMessage": "Conversion"
    },
    "modelsRestaurant::CustomScriptScriptTypesOrderConversion": {
      "id": "models.restaurant/custom_script.script_types.order_conversion",
      "defaultMessage": "Order Conversion"
    },
    "modelsRestaurant::CustomScriptTrackingScriptTypesEndBodyTrackingScript": {
      "id": "models.restaurant/custom_script.tracking_script_types.end_body_tracking_script",
      "defaultMessage": "Body (end)"
    },
    "modelsRestaurant::CustomScriptTrackingScriptTypesHeadTrackingScript": {
      "id": "models.restaurant/custom_script.tracking_script_types.head_tracking_script",
      "defaultMessage": "Head"
    },
    "modelsRestaurant::CustomScriptTrackingScriptTypesStartBodyTrackingScript": {
      "id": "models.restaurant/custom_script.tracking_script_types.start_body_tracking_script",
      "defaultMessage": "Body (start)"
    },
    "modelsMyBusinessVerificationMethodAddress": {
      "id": "models.my_business_verification.method.address",
      "defaultMessage": "Mail"
    },
    "modelsMyBusinessVerificationMethodAuto": {
      "id": "models.my_business_verification.method.auto",
      "defaultMessage": "Auto"
    },
    "modelsMyBusinessVerificationMethodEmail": {
      "id": "models.my_business_verification.method.email",
      "defaultMessage": "Email"
    },
    "modelsMyBusinessVerificationMethodPhoneCall": {
      "id": "models.my_business_verification.method.phone_call",
      "defaultMessage": "Call"
    },
    "modelsMyBusinessVerificationMethodSms": {
      "id": "models.my_business_verification.method.sms",
      "defaultMessage": "Text"
    },
    "modelsMyBusinessVerificationMethodUnspecified": {
      "id": "models.my_business_verification.method.unspecified",
      "defaultMessage": "Unspecified"
    },
    "modelsRestaurantHostingTypesHostingSubdomain": {
      "id": "models.restaurant.hosting_types.hosting_subdomain",
      "defaultMessage": "Subdomain"
    },
    "modelsRestaurantHostingTypesHostingWidget": {
      "id": "models.restaurant.hosting_types.hosting_widget",
      "defaultMessage": "Embed"
    },
    "modelsRestaurantHostingTypesHostingDomain": {
      "id": "models.restaurant.hosting_types.hosting_domain",
      "defaultMessage": "Full-Site"
    },
    "modelsLiveDomain::DnsSnapshot::StagedRecordRecordTypesARecordType": {
      "id": "models.live_domain/dns_snapshot/staged_record.record_types.a_record_type",
      "defaultMessage": "A"
    },
    "modelsLiveDomain::DnsSnapshot::StagedRecordRecordTypesAaaaRecordType": {
      "id": "models.live_domain/dns_snapshot/staged_record.record_types.aaaa_record_type",
      "defaultMessage": "AAAA"
    },
    "modelsLiveDomain::DnsSnapshot::StagedRecordRecordTypesCnameRecordType": {
      "id": "models.live_domain/dns_snapshot/staged_record.record_types.cname_record_type",
      "defaultMessage": "CNAME"
    },
    "modelsLiveDomain::DnsSnapshot::StagedRecordRecordTypesMxRecordType": {
      "id": "models.live_domain/dns_snapshot/staged_record.record_types.mx_record_type",
      "defaultMessage": "MX"
    },
    "modelsLiveDomain::DnsSnapshot::StagedRecordRecordTypesNsRecordType": {
      "id": "models.live_domain/dns_snapshot/staged_record.record_types.ns_record_type",
      "defaultMessage": "NS"
    },
    "modelsLiveDomain::DnsSnapshot::StagedRecordRecordTypesSpfRecordType": {
      "id": "models.live_domain/dns_snapshot/staged_record.record_types.spf_record_type",
      "defaultMessage": "SPF"
    },
    "modelsLiveDomain::DnsSnapshot::StagedRecordRecordTypesSrvRecordType": {
      "id": "models.live_domain/dns_snapshot/staged_record.record_types.srv_record_type",
      "defaultMessage": "SRV"
    },
    "modelsLiveDomain::DnsSnapshot::StagedRecordRecordTypesTxtRecordType": {
      "id": "models.live_domain/dns_snapshot/staged_record.record_types.txt_record_type",
      "defaultMessage": "TXT"
    },
    "modelsSproutSocialGroupSelf": {
      "id": "models.sprout_social/group.self",
      "defaultMessage": "Sprout Social Group"
    },
    "modelsSproutSocialGroupName": {
      "id": "models.sprout_social/group.name",
      "defaultMessage": "Group Name"
    },
    "modelsSproutSocialGroupRestaurant": {
      "id": "models.sprout_social/group.restaurant_id",
      "defaultMessage": "Restaurant"
    },
    "modelsThemePageHeaderFontCasesPhfUppercase": {
      "id": "models.theme.page_header_font_cases.phf_uppercase",
      "defaultMessage": "Uppercase"
    },
    "modelsThemePageHeaderFontCasesPhfLowercase": {
      "id": "models.theme.page_header_font_cases.phf_lowercase",
      "defaultMessage": "Lowercase"
    },
    "modelsThemePageHeaderFontCasesPhfCapitalize": {
      "id": "models.theme.page_header_font_cases.phf_capitalize",
      "defaultMessage": "Titleize"
    },
    "modelsThemePageHeaderFontCasesPhfNone": {
      "id": "models.theme.page_header_font_cases.phf_none",
      "defaultMessage": "Normal"
    },
    "modelsThemeHeaderLogoSizesHLogoSm": {
      "id": "models.theme.header_logo_sizes.h_logo_sm",
      "defaultMessage": "Sm"
    },
    "modelsThemeHeaderLogoSizesHLogoLg": {
      "id": "models.theme.header_logo_sizes.h_logo_lg",
      "defaultMessage": "Lg"
    },
    "modelsThemeMenuItemNameFontCasesItemCaseNone": {
      "id": "models.theme.menu_item_name_font_cases.item_case_none",
      "defaultMessage": "Normal"
    },
    "modelsThemeMenuItemNameFontCasesItemCaseCapitalize": {
      "id": "models.theme.menu_item_name_font_cases.item_case_capitalize",
      "defaultMessage": "Titleize"
    },
    "modelsThemeMenuItemNameFontCasesItemCaseLowercase": {
      "id": "models.theme.menu_item_name_font_cases.item_case_lowercase",
      "defaultMessage": "Lowercase"
    },
    "modelsThemeMenuItemNameFontCasesItemCaseUppercase": {
      "id": "models.theme.menu_item_name_font_cases.item_case_uppercase",
      "defaultMessage": "Uppercase"
    },
    "modelsThemeDishLayoutsLegacyDishLayout": {
      "id": "models.theme.dish_layouts.legacy_dish_layout",
      "defaultMessage": "Legacy"
    },
    "modelsThemeDishLayoutsNextDishLayout": {
      "id": "models.theme.dish_layouts.next_dish_layout",
      "defaultMessage": "Next"
    },
    "modelsThemeDishLayoutsModernDishLayout": {
      "id": "models.theme.dish_layouts.modern_dish_layout",
      "defaultMessage": "Modern"
    },
    "modelsThemeDishDetailTabStacked": {
      "id": "models.theme.dish_detail_tab.stacked",
      "defaultMessage": "Stacked"
    },
    "modelsThemeDishDetailTabLeft": {
      "id": "models.theme.dish_detail_tab.left",
      "defaultMessage": "Left"
    },
    "modelsThemeDishDetailTabEmpty": {
      "id": "models.theme.dish_detail_tab.empty",
      "defaultMessage": "None"
    },
    "modelsThemeDefaultDishDetailTabDetailsTab": {
      "id": "models.theme.default_dish_detail_tab.details_tab",
      "defaultMessage": "Details"
    },
    "modelsThemeDefaultDishDetailTabReservationsTab": {
      "id": "models.theme.default_dish_detail_tab.review_tab",
      "defaultMessage": "Review"
    },
    "modelsThemeDefaultDishDetailTabExtraTab": {
      "id": "models.theme.default_dish_detail_tab.extra_tab",
      "defaultMessage": "Extra"
    },
    "modelsThemeDishDetailTabReservations": {
      "id": "models.theme.dish_detail_tab.reservations",
      "defaultMessage": "Reservations"
    },
    "modelsThemeDishDetailTabCustom": {
      "id": "models.theme.dish_detail_tab.custom",
      "defaultMessage": "Custom"
    },
    "modelsThemeDishDetailButtonVip": {
      "id": "models.theme.dish_detail_button.vip_btn",
      "defaultMessage": "VIP"
    },
    "modelsThemeDishDetailButtonOrder": {
      "id": "models.theme.dish_detail_button.order_btn",
      "defaultMessage": "Order"
    },
    "modelsThemeDishDetailButtonReserve": {
      "id": "models.theme.dish_detail_button.reservations_btn",
      "defaultMessage": "Reservations"
    },
    "modelsThemeDishDetailButtonWaitlist": {
      "id": "models.theme.dish_detail_button.waitlist_btn",
      "defaultMessage": "Waitlist"
    },
    "modelsThemeMenuRowHeightsDynamicRowHeight": {
      "id": "models.theme.menu_row_heights.dynamic_row_height",
      "defaultMessage": "Dynamic Height"
    },
    "modelsThemeMenuRowHeightsFixedRowHeight": {
      "id": "models.theme.menu_row_heights.fixed_row_height",
      "defaultMessage": "Fixed Height"
    },
    "modelsThemeMenuSubsectionDisplay": {
      "id": "models.theme.menu_subsection_display",
      "defaultMessage": "Subsection Display"
    },
    "modelsThemeMenuSubsectionDisplayHorizontal": {
      "id": "models.theme.menu_subsection_display.horizontal",
      "defaultMessage": "Horizontal"
    },
    "modelsThemeMenuSubsectionDisplayVertical": {
      "id": "models.theme.menu_subsection_display.vertical",
      "defaultMessage": "Vertical"
    },
    "modelsThemeHeaderBackgroundEffectsFxhbgWhite": {
      "id": "models.theme.header_background_effects.fxhbg_white",
      "defaultMessage": "White"
    },
    "modelsThemeHeaderBackgroundEffectsFxhbgBlack": {
      "id": "models.theme.header_background_effects.fxhbg_black",
      "defaultMessage": "Black"
    },
    "modelsThemeDefaultBackgroundEffectsBgCover": {
      "id": "models.theme.default_background_effects.bg_cover",
      "defaultMessage": "Cover"
    },
    "modelsThemeDefaultBackgroundEffectsBgRepeat": {
      "id": "models.theme.default_background_effects.bg_repeat",
      "defaultMessage": "Tile"
    },
    "modelsThemeDefaultBackgroundEffectsBgRepeatY": {
      "id": "models.theme.default_background_effects.bg_repeat_y",
      "defaultMessage": "Tile Vertically"
    },
    "modelsThemeDefaultBackgroundEffectsBgStatic": {
      "id": "models.theme.default_background_effects.bg_static",
      "defaultMessage": "Center"
    },
    "modelsThemeHeaderFontWeightsNormal": {
      "id": "models.theme.header_font_weights.normal",
      "defaultMessage": "Normal"
    },
    "modelsThemeHeaderFontWeightsBold": {
      "id": "models.theme.header_font_weights.bold",
      "defaultMessage": "Bold"
    },
    "modelsThemeHeaderFontWeightsLighter": {
      "id": "models.theme.header_font_weights.lighter",
      "defaultMessage": "Lighter"
    },
    "modelsThemeMenuItemNameFontWeightsItemWeightNormal": {
      "id": "models.theme.menu_item_name_font_weights.item_weight_normal",
      "defaultMessage": "Normal"
    },
    "modelsThemeMenuItemNameFontWeightsItemWeightBold": {
      "id": "models.theme.menu_item_name_font_weights.item_weight_bold",
      "defaultMessage": "Bold"
    },
    "modelsThemeMenuItemNameFontWeightsItemWeightLighter": {
      "id": "models.theme.menu_item_name_font_weights.item_weight_lighter",
      "defaultMessage": "Lighter"
    },
    "modelsThemeMenuItemFontAlignmentsItemFontLeft": {
      "id": "models.theme.menu_item_font_alignments.item_font_left",
      "defaultMessage": "Left"
    },
    "modelsThemeMenuItemFontAlignmentsItemFontCenter": {
      "id": "models.theme.menu_item_font_alignments.item_font_center",
      "defaultMessage": "Center"
    },
    "modelsThemeDishTagKeyPositionsDtkBottom": {
      "id": "models.theme.dish_tag_key_positions.dtk_bottom",
      "defaultMessage": "Bottom"
    },
    "modelsThemeDishTagKeyPositionsDtkTop": {
      "id": "models.theme.dish_tag_key_positions.dtk_top",
      "defaultMessage": "Top"
    },
    "modelsThemeDishTagKeyPositionsDtkHidden": {
      "id": "models.theme.dish_tag_key_positions.dtk_hidden",
      "defaultMessage": "Hidden"
    },
    "modelsThemeMenuIconEffectsMenuIconFxNone": {
      "id": "models.theme.menu_icon_effects.menu_icon_fx_none",
      "defaultMessage": "None"
    },
    "modelsThemeMenuIconEffectsMenuIconFxBlack": {
      "id": "models.theme.menu_icon_effects.menu_icon_fx_black",
      "defaultMessage": "Black"
    },
    "modelsThemeMenuIconEffectsMenuIconFxWhite": {
      "id": "models.theme.menu_icon_effects.menu_icon_fx_white",
      "defaultMessage": "White"
    },
    "modelsThemeDefaultTransitionTypesTtDefault": {
      "id": "models.theme.default_transition_types.tt_default",
      "defaultMessage": "Default"
    },
    "modelsThemeDefaultTransitionTypesTtNone": {
      "id": "models.theme.default_transition_types.tt_none",
      "defaultMessage": "None"
    },
    "modelsThemeDefaultTransitionTypesTtFade": {
      "id": "models.theme.default_transition_types.tt_fade",
      "defaultMessage": "Fade"
    },
    "modelsThemeDefaultTransitionTypesTtGrow": {
      "id": "models.theme.default_transition_types.tt_grow",
      "defaultMessage": "Grow"
    },
    "modelsThemeDefaultTransitionTypesTtSlide": {
      "id": "models.theme.default_transition_types.tt_slide",
      "defaultMessage": "Slide"
    },
    "modelsThemePageSubHeaderFontSizesShfsSmall": {
      "id": "models.theme.page_sub_header_font_sizes.shfs_small",
      "defaultMessage": "Small"
    },
    "modelsThemePageSubHeaderFontSizesShfsMedium": {
      "id": "models.theme.page_sub_header_font_sizes.shfs_medium",
      "defaultMessage": "Medium"
    },
    "modelsThemePageSubHeaderFontSizesShfsLarge": {
      "id": "models.theme.page_sub_header_font_sizes.shfs_large",
      "defaultMessage": "Large"
    },
    "modelsThemeFooterLogoEffectsFxfNone": {
      "id": "models.theme.footer_logo_effects.fxf_none",
      "defaultMessage": "None"
    },
    "modelsThemeFooterLogoEffectsFxfWhite": {
      "id": "models.theme.footer_logo_effects.fxf_white",
      "defaultMessage": "Lighten"
    },
    "modelsThemeFooterLogoEffectsFxfBlack": {
      "id": "models.theme.footer_logo_effects.fxf_black",
      "defaultMessage": "Darken"
    },
    "modelsThemeHeaderLogoAlignmentsLNavR": {
      "id": "models.theme.header_logo_alignments.l_nav_r",
      "defaultMessage": "Left with Nav Right"
    },
    "modelsThemeHeaderLogoAlignmentsCNavB": {
      "id": "models.theme.header_logo_alignments.c_nav_b",
      "defaultMessage": "Center with Nav on Sides"
    },
    "modelsThemeMenuFlowSettingsRow": {
      "id": "models.theme.menu_flow_settings.row",
      "defaultMessage": "Row"
    },
    "modelsThemeMenuFlowSettingsColumn": {
      "id": "models.theme.menu_flow_settings.column",
      "defaultMessage": "Column"
    },
    "modelsThemeHeaderLogoEffectsFxhNone": {
      "id": "models.theme.header_logo_effects.fxh_none",
      "defaultMessage": "None"
    },
    "modelsThemeHeaderLogoEffectsFxhWhite": {
      "id": "models.theme.header_logo_effects.fxh_white",
      "defaultMessage": "Lighten"
    },
    "modelsThemeHeaderLogoEffectsFxhBlack": {
      "id": "models.theme.header_logo_effects.fxh_black",
      "defaultMessage": "Darken"
    },
    "modelsThemePageSubHeaderCasesPshUppercase": {
      "id": "models.theme.page_sub_header_cases.psh_uppercase",
      "defaultMessage": "Uppercase"
    },
    "modelsThemePageSubHeaderCasesPshLowercase": {
      "id": "models.theme.page_sub_header_cases.psh_lowercase",
      "defaultMessage": "Lowercase"
    },
    "modelsThemePageSubHeaderCasesPshCapitalize": {
      "id": "models.theme.page_sub_header_cases.psh_capitalize",
      "defaultMessage": "Titleize"
    },
    "modelsThemePageSubHeaderCasesPshNone": {
      "id": "models.theme.page_sub_header_cases.psh_none",
      "defaultMessage": "Normal"
    },
    "modelsThemeMenuTabsLayoutsCardMenuTabsLayout": {
      "id": "models.theme.menu_tabs_layouts.card_menu_tabs_layout",
      "defaultMessage": "Card"
    },
    "modelsThemeMenuTabsLayoutsLegacyMenuTabsLayout": {
      "id": "models.theme.menu_tabs_layouts.legacy_menu_tabs_layout",
      "defaultMessage": "Legacy"
    },
    "modelsThemeMenuTabsLayoutsMaterialMenuTabsLayout": {
      "id": "models.theme.menu_tabs_layouts.material_menu_tabs_layout",
      "defaultMessage": "Material"
    },
    "modelsThemeMenuTabsLayoutsSidebarMenuTabsLayout": {
      "id": "models.theme.menu_tabs_layouts.sidebar_menu_tabs_layout",
      "defaultMessage": "Sidebar"
    },
    "modelsThemePageHeaderFontSizesHfsSmall": {
      "id": "models.theme.page_header_font_sizes.hfs_small",
      "defaultMessage": "Small"
    },
    "modelsThemePageHeaderFontSizesHfsMedium": {
      "id": "models.theme.page_header_font_sizes.hfs_medium",
      "defaultMessage": "Medium"
    },
    "modelsThemePageHeaderFontSizesHfsLarge": {
      "id": "models.theme.page_header_font_sizes.hfs_large",
      "defaultMessage": "Large"
    },
    "modelsThemePopmenuLogoEffectsPmLogoBlack": {
      "id": "models.theme.popmenu_logo_effects.pm_logo_black",
      "defaultMessage": "Black"
    },
    "modelsThemePopmenuLogoEffectsPmLogoWhite": {
      "id": "models.theme.popmenu_logo_effects.pm_logo_white",
      "defaultMessage": "White"
    },
    "modelsLighthouseReportSortMinAccessibilityScoreDesktop": {
      "id": "models.lighthouse_report.sort.min_accessibility_score_desktop",
      "defaultMessage": "Desktop Accessibility"
    },
    "modelsLighthouseReportSortMinBestPracticesScoreDesktop": {
      "id": "models.lighthouse_report.sort.min_best_practices_score_desktop",
      "defaultMessage": "Desktop Best Practices"
    },
    "modelsLighthouseReportSortMinPerformanceScoreDesktop": {
      "id": "models.lighthouse_report.sort.min_performance_score_desktop",
      "defaultMessage": "Desktop Performance"
    },
    "modelsLighthouseReportSortMinSeoScoreDesktop": {
      "id": "models.lighthouse_report.sort.min_seo_score_desktop",
      "defaultMessage": "Desktop SEO"
    },
    "modelsLighthouseReportSortMinAccessibilityScoreMobile": {
      "id": "models.lighthouse_report.sort.min_accessibility_score_mobile",
      "defaultMessage": "Mobile Accessibility"
    },
    "modelsLighthouseReportSortMinBestPracticesScoreMobile": {
      "id": "models.lighthouse_report.sort.min_best_practices_score_mobile",
      "defaultMessage": "Mobile Best Practices"
    },
    "modelsLighthouseReportSortMinPerformanceScoreMobile": {
      "id": "models.lighthouse_report.sort.min_performance_score_mobile",
      "defaultMessage": "Mobile Performance"
    },
    "modelsLighthouseReportSortMinSeoScoreMobile": {
      "id": "models.lighthouse_report.sort.min_seo_score_mobile",
      "defaultMessage": "Mobile SEO"
    },
    "modelsRootTaskTypesBookmarkPageTask": {
      "id": "models.root_task.types.bookmark_page_task",
      "defaultMessage": "Bookmark"
    },
    "modelsRootTaskTypesConnectSocialMediaTask": {
      "id": "models.root_task.types.connect_social_media_task",
      "defaultMessage": "Connect Social Media"
    },
    "modelsRootTaskTypesCustomTask": {
      "id": "models.root_task.types.custom_task",
      "defaultMessage": "Custom Task"
    },
    "modelsRootTaskTypesDomainExpirationTask": {
      "id": "models.root_task.types.domain_expiration_task",
      "defaultMessage": "Domain Expiration"
    },
    "modelsRootTaskTypesNewGoogleReviewTask": {
      "id": "models.root_task.types.new_google_review_task",
      "defaultMessage": "New Google Review"
    },
    "modelsRootTaskTypesNewReviewTask": {
      "id": "models.root_task.types.new_review_task",
      "defaultMessage": "New Review"
    },
    "modelsRootTaskTypesSmartMessageTask": {
      "id": "models.root_task.types.smart_message_task",
      "defaultMessage": "Enable Smart Message"
    },
    "modelsRootTaskExpirationExpired": {
      "id": "models.root_task.expiration.expired",
      "defaultMessage": "Expired"
    },
    "modelsRootTaskExpirationNever": {
      "id": "models.root_task.expiration.never",
      "defaultMessage": "Never"
    },
    "modelsBillingCouponDiscountTypesAmountDiscountType": {
      "id": "models.billing_coupon.discount_types.amount_discount_type",
      "defaultMessage": "Amount ($)"
    },
    "modelsBillingCouponDiscountTypesPercentDiscountType": {
      "id": "models.billing_coupon.discount_types.percent_discount_type",
      "defaultMessage": "Percent (%%)"
    },
    "modelsBillingCouponDurationTypesForeverDurationType": {
      "id": "models.billing_coupon.duration_types.forever_duration_type",
      "defaultMessage": "Lifetime"
    },
    "modelsBillingCouponDurationTypesOnceDurationType": {
      "id": "models.billing_coupon.duration_types.once_duration_type",
      "defaultMessage": "One-time"
    },
    "modelsBillingCouponDurationTypesRepeatingDurationType": {
      "id": "models.billing_coupon.duration_types.repeating_duration_type",
      "defaultMessage": "Monthly"
    },
    "modelsBillingPlanIntervalTypesMonthInterval": {
      "id": "models.billing_plan.interval_types.month_interval",
      "defaultMessage": "Monthly"
    },
    "modelsBillingPlanIntervalTypesQuarterInterval": {
      "id": "models.billing_plan.interval_types.quarter_interval",
      "defaultMessage": "Quarterly"
    },
    "modelsBillingPlanIntervalTypesSemiInterval": {
      "id": "models.billing_plan.interval_types.semi_interval",
      "defaultMessage": "Semi-Annually"
    },
    "modelsBillingPlanIntervalTypesYearInterval": {
      "id": "models.billing_plan.interval_types.year_interval",
      "defaultMessage": "Annually"
    },
    "modelsBillingPlanStripeProductUrl": {
      "id": "models.billing_plan.stripe_product_url",
      "defaultMessage": "Stripe URL"
    },
    "modelsRootGroup::MemberStatusesStatusClient": {
      "id": "models.root_group/member.statuses.status_client",
      "defaultMessage": "Live"
    },
    "modelsRootGroup::MemberStatusesStatusClientStaging": {
      "id": "models.root_group/member.statuses.status_client_staging",
      "defaultMessage": "Staging"
    },
    "modelsRootGroup::MemberStatusesStatusClientSuspended": {
      "id": "models.root_group/member.statuses.status_client_suspended",
      "defaultMessage": "Suspended"
    },
    "modelsRootGroup::MemberStatusesStatusDisabled": {
      "id": "models.root_group/member.statuses.status_disabled",
      "defaultMessage": "Disabled"
    },
    "modelsRootGroup::MemberStatusesStatusTest": {
      "id": "models.root_group/member.statuses.status_test",
      "defaultMessage": "n/a"
    },
    "modelsUserUserRolesConsumer": {
      "id": "models.user.user_roles.consumer",
      "defaultMessage": "Consumer"
    },
    "modelsUserUserRolesRootSales": {
      "id": "models.user.user_roles.root_sales",
      "defaultMessage": "Root (Sales)"
    },
    "modelsUserUserRolesRootBoost": {
      "id": "models.user.user_roles.root_boost",
      "defaultMessage": "Root (Boost)"
    },
    "modelsUserUserRolesRootStaging": {
      "id": "models.user.user_roles.root_staging",
      "defaultMessage": "Root (Staging)"
    },
    "modelsUserUserRolesRootSupport": {
      "id": "models.user.user_roles.root_support",
      "defaultMessage": "Root (Support)"
    },
    "modelsUserUserRolesRootSystem": {
      "id": "models.user.user_roles.root_system",
      "defaultMessage": "Root (System)"
    },
    "modelsRootNoteNoteTypesDefaultNoteType": {
      "id": "models.root_note.note_types.default_note_type",
      "defaultMessage": "General"
    },
    "modelsRootNoteNoteTypesDesignNoteType": {
      "id": "models.root_note.note_types.design_note_type",
      "defaultMessage": "Design"
    },
    "modelsRootNoteNoteTypesMarketingNoteType": {
      "id": "models.root_note.note_types.marketing_note_type",
      "defaultMessage": "Marketing"
    },
    "modelsRootNoteNoteTypesSystemNoteType": {
      "id": "models.root_note.note_types.system_note_type",
      "defaultMessage": "System"
    },
    "modelsRestaurantSelectedProductHasPhotographyFalse": {
      "id": "models.restaurant.selected_product.has_photography.false",
      "defaultMessage": "Not included"
    },
    "modelsRestaurantSelectedProductHasPhotographyTrue": {
      "id": "models.restaurant.selected_product.has_photography.true",
      "defaultMessage": "Included"
    },
    "modelsRestaurantSelectedProductIsBilledUpFrontFalse": {
      "id": "models.restaurant.selected_product.is_billed_up_front.false",
      "defaultMessage": "Monthly"
    },
    "modelsRestaurantSelectedProductIsBilledUpFrontTrue": {
      "id": "models.restaurant.selected_product.is_billed_up_front.true",
      "defaultMessage": "Prepaid"
    },
    "modelsRestaurantSelectedProductProductTypeConcierge": {
      "id": "models.restaurant.selected_product.product_type.boost",
      "defaultMessage": "Boost"
    },
    "modelsRestaurantSelectedProductProductTypePro": {
      "id": "models.restaurant.selected_product.product_type.pro",
      "defaultMessage": "Pro"
    },
    "modelsRestaurantServiceType": {
      "id": "models.restaurant.service_type",
      "defaultMessage": "Service"
    },
    "modelsRestaurantServiceTypesFullServiceType": {
      "id": "models.restaurant.service_types.full_service_type",
      "defaultMessage": "Full-service"
    },
    "modelsRestaurantServiceTypesQuickServiceType": {
      "id": "models.restaurant.service_types.quick_service_type",
      "defaultMessage": "Quick Service"
    },
    "modelsSocialAccountSocialTypesFacebookType": {
      "id": "models.social_account.social_types.facebook_type",
      "defaultMessage": "Facebook Page"
    },
    "modelsSocialAccountSocialTypesGoogleAdminType": {
      "id": "models.social_account.social_types.google_admin_type",
      "defaultMessage": "Google Admin Account"
    },
    "modelsSocialAccountSocialTypesGoogleType": {
      "id": "models.social_account.social_types.google_type",
      "defaultMessage": "Google Account"
    },
    "modelsSocialAccountSocialTypesInstagramType": {
      "id": "models.social_account.social_types.instagram_type",
      "defaultMessage": "Instagram Account"
    },
    "modelsSocialAccountSocialTypesTwitterType": {
      "id": "models.social_account.social_types.twitter_type",
      "defaultMessage": "Twitter Account"
    },
    "modelsRestaurant::Member::LocationMemberLevelsEmployee": {
      "id": "models.restaurant/member/location.member_levels.employee",
      "defaultMessage": "No Access"
    },
    "modelsRestaurant::Member::LocationMemberLevelsManager": {
      "id": "models.restaurant/member/location.member_levels.manager",
      "defaultMessage": "Full Access"
    },
    "modelsRestaurant::MemberInviteMemberLevelsEmployee": {
      "id": "models.restaurant/member_invite.member_levels.employee",
      "defaultMessage": "Limited Access"
    },
    "modelsRestaurant::MemberInviteMemberLevelsOwner": {
      "id": "models.restaurant/member_invite.member_levels.owner",
      "defaultMessage": "Full Access"
    },
    "modelsRestaurant::MemberMessagingLocationCanManageMessagesFalse": {
      "id": "models.restaurant/member.messaging_location.can_manage_messages.false",
      "defaultMessage": "No Access"
    },
    "modelsRestaurant::MemberMessagingLocationCanManageMessagesTrue": {
      "id": "models.restaurant/member.messaging_location.can_manage_messages.true",
      "defaultMessage": "Full Access"
    },
    "modelsRestaurant::MemberReceiveFormNotificationLocationFalse": {
      "id": "models.restaurant/member.receive_form_notification_location.false",
      "defaultMessage": "No Access"
    },
    "modelsRestaurant::MemberReceiveFormNotificationLocationTrue": {
      "id": "models.restaurant/member.receive_form_notification_location.true",
      "defaultMessage": "Full Access"
    },
    "modelsRestaurant::MemberReivewLocationCanManagewReviewsFalse": {
      "id": "models.restaurant/member.review_location.can_manage_reviews.false",
      "defaultMessage": "No Access"
    },
    "modelsRestaurant::MemberReivewLocationCanManagewReviewsTrue": {
      "id": "models.restaurant/member.review_location.can_manage_reviews.true",
      "defaultMessage": "Full Access"
    },
    "modelsRestaurant::MemberWaitlistingLocationCanManagewWaitlistsFalse": {
      "id": "models.restaurant/member.waitlisting_location.can_manage_waitlists.false",
      "defaultMessage": "No Access"
    },
    "modelsRestaurant::MemberWaitlistingLocationCanManagewWaitlistsTrue": {
      "id": "models.restaurant/member.waitlisting_location.can_manage_waitlists.true",
      "defaultMessage": "Full Access"
    },
    "modelsRestaurant::MemberAnsweringLocationCanManagewAnsweringFalse": {
      "id": "models.restaurant/member.answering_location.can_manage_answering.false",
      "defaultMessage": "No Access"
    },
    "modelsRestaurant::MemberAnsweringLocationCanManagewAnsweringTrue": {
      "id": "models.restaurant/member.answering_location.can_manage_answering.true",
      "defaultMessage": "Full Access"
    },
    "modelsRestaurant::MemberReservationLocationCanManageReservationsFalse": {
      "id": "models.restaurant/member.reservation_location.can_manage_reservations.false",
      "defaultMessage": "No Access"
    },
    "modelsRestaurant::MemberReservationLocationCanManageReservationsTrue": {
      "id": "models.restaurant/member.reservation_location.can_manage_reservations.true",
      "defaultMessage": "Full Access"
    },
    "modelsRestaurant::MemberMemberLevelsEmployee": {
      "id": "models.restaurant/member.member_levels.employee",
      "defaultMessage": "Limited Access"
    },
    "modelsRestaurant::MemberMemberLevelsOwner": {
      "id": "models.restaurant/member.member_levels.owner",
      "defaultMessage": "Full Access"
    },
    "modelsRestaurant::MemberTasksNotificationFrequenciesTnfDaily": {
      "id": "models.restaurant/member.tasks_notification_frequencies.tnf_daily",
      "defaultMessage": "Daily"
    },
    "modelsRestaurant::MemberTasksNotificationFrequenciesTnfWeekly": {
      "id": "models.restaurant/member.tasks_notification_frequencies.tnf_weekly",
      "defaultMessage": "Weekly"
    },
    "modelsRestaurant::MemberTasksNotificationFrequenciesTnfNone": {
      "id": "models.restaurant/member.tasks_notification_frequencies.tnf_none",
      "defaultMessage": "None"
    },
    "modelsRestaurant::MemberInviteStatusBouncedAt": {
      "id": "models.restaurant/member_invite.status.bounced_at",
      "defaultMessage": "Bounced at"
    },
    "modelsRestaurant::MemberInviteStatusClickedAt": {
      "id": "models.restaurant/member_invite.status.clicked_at",
      "defaultMessage": "Clicked at"
    },
    "modelsRestaurant::MemberInviteStatusDeliveredAt": {
      "id": "models.restaurant/member_invite.status.delivered_at",
      "defaultMessage": "Delivered at"
    },
    "modelsRestaurant::MemberInviteStatusOpenedAt": {
      "id": "models.restaurant/member_invite.status.opened_at",
      "defaultMessage": "Opened at"
    },
    "modelsRestaurant::MemberInviteStatusUnsubscribedAt": {
      "id": "models.restaurant/member_invite.status.unsubscribed_at",
      "defaultMessage": "Unsubscribed at"
    },
    "modelsFollowerUserLastVisitAt": {
      "id": "models.follower.user.last_visit_at",
      "defaultMessage": "Last Seen"
    },
    "modelsFollowerLocationName": {
      "id": "models.follower.location.name",
      "defaultMessage": "Location"
    },
    "modelsFollowerUserBirthDay": {
      "id": "models.follower.user.birth_day",
      "defaultMessage": "Birthday"
    },
    "modelsRestaurantBillingCustomerStripeId": {
      "id": "models.restaurant.billing_customer.stripe_id",
      "defaultMessage": "Stripe ID"
    },
    "modelsRestaurantBillingCustomerPaymentType": {
      "id": "models.restaurant.billing_customer.payment_type",
      "defaultMessage": "Payment Method"
    },
    "modelsRestaurantBillingCustomerDelinquent": {
      "id": "models.restaurant.billing_customer.delinquent",
      "defaultMessage": "Delinquent"
    },
    "modelsRestaurantBillingSubscriptionUpcomingInvoiceTotal": {
      "id": "models.restaurant.billing_subscription.upcoming_invoice_total",
      "defaultMessage": "Upcoming Invoice Total"
    },
    "modelsRestaurantBillingCustomerUnpaidInvoiceBalance": {
      "id": "models.restaurant.billing_customer.unpaid_invoice_balance",
      "defaultMessage": "Unpaid Invoice Balance"
    },
    "modelsRestaurantBillingCustomerFirstInvoiceDate": {
      "id": "models.restaurant.billing_customer.first_invoice_date",
      "defaultMessage": "First Invoice Date"
    },
    "modelsRestaurantLastBillingInvoiceDate": {
      "id": "models.restaurant.last_billing_invoice.date",
      "defaultMessage": "Latest Invoice Date"
    },
    "modelsRestaurantLastBillingInvoiceTotal": {
      "id": "models.restaurant.last_billing_invoice.total",
      "defaultMessage": "Latest Invoice Total"
    },
    "modelsRestaurantLastBillingInvoiceInvoiceStatus": {
      "id": "models.restaurant.last_billing_invoice.invoice_status",
      "defaultMessage": "Latest Invoice Status"
    },
    "modelsRestaurantBillingSubscriptionCurrentPeriodEnd": {
      "id": "models.restaurant.billing_subscription.current_period_end",
      "defaultMessage": "End of Period"
    },
    "modelsRestaurantMemberUserFullDisplayName": {
      "id": "models.restaurant/member.user.full_display_name",
      "defaultMessage": "Name"
    },
    "modelsRestaurantMemberUserLastVisitAt": {
      "id": "models.restaurant/member.user.last_visit_at",
      "defaultMessage": "Last Seen"
    },
    "modelsCustomPage::SectionDisplayLocationStateHeading": {
      "id": "models.custom_page/section.display_location_state_heading",
      "defaultMessage": "Location Headers"
    },
    "modelsCustomPage::SectionDisplayLocationStateHeadingYes": {
      "id": "models.custom_page/section.display_location_state_heading.true",
      "defaultMessage": "Show Location Headers"
    },
    "modelsCustomPage::SectionDisplayLocationStateHeadingNo": {
      "id": "models.custom_page/section.display_location_state_heading.false",
      "defaultMessage": "Hide Location Headers"
    },
    "modelsCustomPage::SectionShowDirectionButtonTrue": {
      "id": "models.custom_page/section.show_directions_button.true",
      "defaultMessage": "Show Get Directions Button"
    },
    "modelsCustomPage::SectionShowDirectionButtonFalse": {
      "id": "models.custom_page/section.show_directions_button.false",
      "defaultMessage": "Hide Get Directions Button"
    },
    "modelsCustomPage::SectionShowLocationCustomButtonsTrue": {
      "id": "models.custom_page/section.show_location_custom_buttons.true",
      "defaultMessage": "Show Location Buttons"
    },
    "modelsCustomPage::SectionShowLocationCustomButtonsFalse": {
      "id": "models.custom_page/section.show_location_custom_buttons.false",
      "defaultMessage": "Hide Location Buttons"
    },
    "modelsOrderingEventId": {
      "id": "models.ordering_event.id",
      "defaultMessage": ""
    },
    "modelsCalendarEventId": {
      "id": "models.calendar_event.id",
      "defaultMessage": ""
    },
    "customDateRangeTitle": {
      "id": "models.custom_date_range.title",
      "defaultMessage": "Custom Time"
    },
    "customDateRangeStartDate": {
      "id": "models.custom_date_range.start_date",
      "defaultMessage": "Start Date"
    },
    "customDateRangeEndDate": {
      "id": "models.custom_date_range.end_date",
      "defaultMessage": "End Date"
    },
    "modelsToastRestaurantId": {
      "id": "models.toast_restaurant.id",
      "defaultMessage": "ID"
    },
    "modelsToastRestaurantCreatedAt": {
      "id": "models.toast_restaurant.created_at",
      "defaultMessage": "Authorized"
    },
    "modelsToastRestaurantName": {
      "id": "models.toast_restaurant.name",
      "defaultMessage": "Name"
    },
    "modelsToastRestaurantConnectedAt": {
      "id": "models.toast_restaurant.connected_at",
      "defaultMessage": "Connected At"
    },
    "modelsToastRestaurantLastSyncAt": {
      "id": "models.toast_restaurant.last_sync_at",
      "defaultMessage": "Last Full Sync At"
    },
    "modelsToastRestaurantWarningSyncing": {
      "id": "models.toast_restaurant.warning_syncing",
      "defaultMessage": "Toast restaurant Sync is in progress. Please wait..."
    },
    "modelsToastRestaurantEdit": {
      "id": "models.toast_restaurant.edit",
      "defaultMessage": "Attach to a Popmenu restaurant"
    },
    "modelsToastRestaurantRestaurant": {
      "id": "models.toast_restaurant.restaurant",
      "defaultMessage": "Popmenu Restaurant"
    },
    "modelsBxgyApplicableDishesTitle": {
      "id": "models.bxgy.applicable_dishes.title",
      "defaultMessage": "Applicable Dishes"
    },
    "modelsBxgyRedeemableDishesTitle": {
      "id": "models.bxgy.redeemable_dishes.title",
      "defaultMessage": "Redeemable Dishes"
    },
    "modelsBxgyEnterDishPlaceholder": {
      "id": "models.bxgy.enter_dish.placeholder",
      "defaultMessage": "Enter a dish name..."
    },
    "modelsBxgyMinimumDishAmount": {
      "id": "models.bxgy.minumim_dish_amount",
      "defaultMessage": "1"
    },
    "modelsToastRestaurantSelf": {
      "id": "models.toast_restaurant.self",
      "defaultMessage": "Toast restaurant"
    },
    "modelsCloverAccountSelf": {
      "id": "models.clover_account.self",
      "defaultMessage": "Clover Merchant"
    },
    "modelsCloverAccountName": {
      "id": "models.clover_account.name",
      "defaultMessage": "Merchant Name"
    },
    "modelsCloverAccountAddress": {
      "id": "models.clover_account.address",
      "defaultMessage": "Address"
    },
    "modelsCloverAccountCreatedAt": {
      "id": "models.clover_account.created_at",
      "defaultMessage": "Integrated At"
    },
    "modelsAdvancedFormFormName": {
      "id": "models.advanced_form.form_name",
      "defaultMessage": "Form Name"
    },
    "modelsAdvancedFormLocationName": {
      "id": "models.advanced_form.location.name",
      "defaultMessage": "Location"
    },
    "modelsAdvancedFormPages": {
      "id": "models.advanced_form.pages",
      "defaultMessage": "Pages"
    },
    "modelsAdvancedFormSelf": {
      "id": "models.advanced_form.self",
      "defaultMessage": "Advanced Form"
    },
    "modelsAdvancedForm::EntrySelf": {
      "id": "models.advanced_form/entry.self",
      "defaultMessage": "Advanced Form Response"
    },
    "modelsAdvancedForm::EntryCreatedAt": {
      "id": "models.advanced_form/entry.created_at",
      "defaultMessage": "Date"
    },
    "modelsAdvancedForm::EntryAdvancedFormName": {
      "id": "models.advanced_form/entry.advanced_form.form_name",
      "defaultMessage": "Form Name"
    },
  }
);
export default defaultMessages;
