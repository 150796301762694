import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Typography } from '@popmenu/common-ui';

import Grid from '../../../shared/Grid';

const TermsNav = () => (
  <Grid container spacing={1}>
    <Grid item md={3} xs={12}>
      <Typography align="center">
        <Link to="/terms">
          <FormattedMessage id="nav.client_terms" defaultMessage="Client Terms of Service" />
        </Link>
      </Typography>
    </Grid>
    <Grid item md={3} xs={12}>
      <Typography align="center">
        <Link to="/privacy">
          <FormattedMessage id="nav.privacy" defaultMessage="Privacy Policy" />
        </Link>
      </Typography>
    </Grid>
    <Grid item md={3} xs={12}>
      <Typography align="center">
        <Link to="/dmca-policy">
          <FormattedMessage id="nav.dmca_policy" defaultMessage="DMCA Policy" />
        </Link>
      </Typography>
    </Grid>
    <Grid item md={3} xs={12}>
      <Typography align="center">
        <Link to="/accessibility">
          <FormattedMessage id="nav.client_accessibility" defaultMessage="Website Accessibility" />
        </Link>
      </Typography>
    </Grid>
  </Grid>
);

export default TermsNav;
