
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "followDestroyConfirm": {
      "id": "follow.destroy_confirm",
      "defaultMessage": "Are you sure you want to stop following {name}?"
    },
    "followPromptActionSignUp": {
      "id": "follow.prompt.action_sign_up",
      "defaultMessage": "Sign Up"
    },
    "followPromptActionJoinForFree": {
      "id": "follow.prompt.action_join_for_free",
      "defaultMessage": "Join for free"
    },
    "followNew": {
      "id": "follow.new",
      "defaultMessage": "Follow"
    },
    "followCreated": {
      "id": "follow.created",
      "defaultMessage": "You started following {name}!"
    },
    "followCreatedError": {
      "id": "follow.created_error",
      "defaultMessage": "There was an error following the restaurant."
    },
    "followDestroy": {
      "id": "follow.destroy",
      "defaultMessage": "Unfollow"
    },
    "followDestroyed": {
      "id": "follow.destroyed",
      "defaultMessage": "You stopped following {name}."
    },
    "followDestroyedError": {
      "id": "follow.destroyed_error",
      "defaultMessage": "There was an error unfollowing the restaurant."
    },
    "followSecretPrompt": {
      "id": "follow.secret_prompt",
      "defaultMessage": "Become a VIP to view our secret menu!"
    },
    "followJoinButton": {
      "id": "follow.join_button",
      "defaultMessage": "Join for free"
    },
    "followJoinChoice": {
      "id": "follow.join_choice",
      "defaultMessage": "or"
    },
    "followThanksForJoining": {
      "id": "follow.thanks_for_joining",
      "defaultMessage": "'Thanks for joining!'"
    },
    "followJoinCommunicationQuestions": {
      "id": "follow.join_communication_questions",
      "defaultMessage": "A few more questions so we can better communicate to you."
    },
    "followJoinPassword": {
      "id": "follow.join_password",
      "defaultMessage": "Create a password to manage your favorite items, offers, and more."
    },
  }
);
export default defaultMessages;

