// the section id is for the subscription, the menu item id to track the destroy status
export type DestroyingMenuItem = { menuItemId: number, sectionId: number };

export type MenuManagementState = {
  destroyingMenuItems: DestroyingMenuItem[],
  isScrolling: boolean,
  updatingModifierGroupIds: number[],
  dishIdsToRefetch: number[],
}

const initialState: MenuManagementState = {
  destroyingMenuItems: [],
  dishIdsToRefetch: [],
  isScrolling: false,
  updatingModifierGroupIds: [],
};

const insertNewIds = (ids: number[], id: number): number[] => {
  if (ids.includes(id)) {
    return [...ids];
  } else {
    return [...ids, id];
  }
};

export type MenuManagementAction = { type: 'menuManagement.scrollStart' }
  | { type: 'menuManagement.scrollEnd' }
  | { type: 'menuManagement.addUpdatingModifierGroupId', context: { modifierGroupId: number } }
  | { type: 'menuManagement.removeUpdatingModifierGroupId', context: { modifierGroupId: number } }
  | { type: 'menuManagement.addDishIdToRefetch', context: { dishId: number } }
  | { type: 'menuManagement.removeDishIdToRefetch', context: { dishId: number } }
  | { type: 'menuManagement.addDestroyingMenuItem', context: { menuItem: DestroyingMenuItem } }
  | { type: 'menuManagement.removeDestroyingMenuItem', context: { menuItemId: number } };

export const menuManagementReducer = (state = initialState, action: MenuManagementAction) => {
  switch (action.type) {
    case 'menuManagement.scrollStart':
      return { ...state, isScrolling: true };
    case 'menuManagement.scrollEnd':
      return { ...state, isScrolling: false };
    case 'menuManagement.addUpdatingModifierGroupId':
      return { ...state, updatingModifierGroupIds: insertNewIds(state.updatingModifierGroupIds, action.context.modifierGroupId) };
    case 'menuManagement.removeUpdatingModifierGroupId':
      return { ...state, updatingModifierGroupIds: state.updatingModifierGroupIds.filter(id => id !== action.context.modifierGroupId) };
    case 'menuManagement.addDishIdToRefetch':
      return { ...state, dishIdsToRefetch: insertNewIds(state.dishIdsToRefetch, action.context.dishId) };
    case 'menuManagement.removeDishIdToRefetch':
      return { ...state, dishIdsToRefetch: state.dishIdsToRefetch.filter(id => id !== action.context.dishId) };
    case 'menuManagement.addDestroyingMenuItem':
      return { ...state, destroyingMenuItems: [...state.destroyingMenuItems, action.context.menuItem] };
    case 'menuManagement.removeDestroyingMenuItem':
      return { ...state, destroyingMenuItems: state.destroyingMenuItems.filter(({ menuItemId }) => menuItemId !== action.context.menuItemId) };
    default:
      return { ...state };
  }
};
