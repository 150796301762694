import React from 'react';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { FormattedMessage } from 'react-intl';
import { Button, Typography } from '@popmenu/common-ui';

import { Link } from 'react-router-dom';
import { withIntl } from '../../utils/withIntl';
import { notBlankValidator, passwordValidator, samePasswordValidator } from '../../utils/forms';
import createUserMutation from '../../libs/gql/mutations/users/createUserMutation.gql';
import { classNames, withStyles } from '../../utils/withStyles';
import signInStyles from '../../assets/jss/shared/signInStyles';

import BasicForm, { SubmitGroup, TextFieldGroup } from '../shared/forms/BasicForm';
import SocialControls from '../../shared/sessions/SocialControls';
import Grid from '../../shared/Grid';

const SignUpForm = ({ afterSignUp, classes, email, t, onUserExists, readOnlyEmail, showAllFields, style, variant }) => {
  const searchParams = new URLSearchParams(document.location.search);
  const isOauth = searchParams.get('oauth') === '1';

  return (
    <BasicForm
      defaultValues={{ email }}
      labelPosition="top"
      mutate={{
        mutation: createUserMutation,
        onCompleted: () => {
          if (typeof afterSignUp === 'function') {
            afterSignUp();
          }
        },
        onError: (error) => {
          if (error.message === 'sign_up.email_taken') {
            onUserExists();
          }
        },
        toVariables: ({ facebookCode, googleCode, ...variables }) => ({
          createAccount: isOauth,
          facebookCode,
          googleCode,
          userInput: {
            ...variables,
            name: undefined,
          },
        }),
      }}
    >
      {({ values, submitManual }) => {
        const submitDisabled = isOauth ? (!values.email || !values.password || !values.passwordConfirmation) : (!values.email || !values.password);
        return (
          <React.Fragment>
            {isOauth && (
              <React.Fragment>
                <Grid container spacing={2}>
                  <Grid xs={6} item>
                    <TextFieldGroup
                      field="firstName"
                      placeholder={t('users.first_name')}
                      style={style}
                      validate={notBlankValidator}
                      variant={variant}
                    />
                  </Grid>
                  <Grid xs={6} item>
                    <TextFieldGroup
                      field="lastName"
                      placeholder={t('users.last_name')}
                      style={style}
                      validate={notBlankValidator}
                      variant={variant}
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <TextFieldGroup
                      required
                      field="email"
                      style={style}
                      placeholder={t('users.email')}
                      type="email"
                      validate={notBlankValidator}
                      variant={variant}
                      disabled={readOnlyEmail}
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <TextFieldGroup
                      required
                      field="phone"
                      style={style}
                      placeholder={t('users.phone')}
                      type="phone"
                      validate={notBlankValidator}
                      variant={variant}
                    />
                  </Grid>
                  <Grid xs={6} item>
                    <TextFieldGroup
                      required
                      field="password"
                      label="Password"
                      placeholder={t('users.password')}
                      type="password"
                      validate={passwordValidator}
                      variant={variant}
                    />
                  </Grid>
                  <Grid xs={6} item>
                    <TextFieldGroup
                      required
                      field="passwordConfirmation"
                      label="Confirm Password"
                      placeholder={t('users.password')}
                      type="password"
                      validate={samePasswordValidator}
                      variant={variant}
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <Typography variant="subtitle1">
                      {t('models.user.password_helper_text')}
                    </Typography>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
            {
              !isOauth && (
                <Grid container spacing={2}>
                  <Grid xs={12} item>
                    <TextFieldGroup
                      required
                      field="email"
                      style={style}
                      placeholder={t('users.email')}
                      type="email"
                      validate={notBlankValidator}
                      variant={variant}
                      disabled={readOnlyEmail}
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <TextFieldGroup
                      required
                      field="password"
                      helperText={t('models.user.password_helper_text')}
                      label="Password"
                      placeholder={t('users.password')}
                      type="password"
                      validate={passwordValidator}
                      variant={variant}
                      data-tour-id="sign-up-form-password"
                    />
                  </Grid>
                </Grid>
              )
            }
            {showAllFields && (
              <React.Fragment>
                <TextFieldGroup
                  field="firstName"
                  placeholder={t('users.first_name')}
                  style={style}
                  validate={notBlankValidator}
                  variant={variant}
                />
                <TextFieldGroup
                  field="lastName"
                  placeholder={t('users.last_name')}
                  style={style}
                  validate={notBlankValidator}
                  variant={variant}
                />
                <TextFieldGroup
                  field="phone"
                  placeholder={t('users.phone')}
                  style={style}
                  type="tel"
                  validate={notBlankValidator}
                  variant={variant}
                />
              </React.Fragment>
            )}
            <Typography
              align="center"
              className={classNames(classes.disclaimer)}
              variant="body2"
            >
              <FormattedMessage
                id="admin.sign_up_form.disclaimer"
                defaultMessage="By clicking the 'Sign Up' button, you agree to Popmenu&apos;s {termsLink} and {privacyLink}."
                values={{
                  privacyLink: (
                    <Button
                      className={classes.termsButton}
                      component={Link}
                      size="small"
                      target="_blank"
                      to="/privacy"
                      variant="text"
                    >
                      <FormattedMessage id="admin.sign_up_form.privacy_policy" defaultMessage="Privacy Policy" />
                    </Button>
                  ),
                  termsLink: (
                    <Button
                      className={classes.termsButton}
                      component={Link}
                      size="small"
                      target="_blank"
                      to="/terms"
                      variant="text"
                    >
                      <FormattedMessage id="admin.sign_up_form.terms" defaultMessage="Terms" />
                    </Button>
                  ),
                }}
              />
            </Typography>
            <SubmitGroup
              color="secondary"
              disabled={submitDisabled}
              data-cy="sign_up_submit"
              icon={false}
              justify="center"
              size="lg"
              title={t('sessions.sign_up')}
            />

            {isOauth ? (
              <div className={classes.socialSeparatorContainer}>
                <div className={classes.socialSeparator} aria-hidden="true">
                  <div className={classes.socialSeparatorLine} />
                </div>
                <Typography align="center" className={classes.socialSeparatorText}>
                  <FormattedMessage id="or" defaultMessage="or" />
                </Typography>
              </div>
            ) :
              (
                <React.Fragment>
                  <Typography align="center" className={classes.socialSeparatorOld}>
                    <FormattedMessage id="or" defaultMessage="or" />
                  </Typography>
                  <SocialControls submit={(_e, variables) => submitManual(variables)} />
                </React.Fragment>
              )}
          </React.Fragment>
        );
      }}
    </BasicForm>
  );
};

SignUpForm.defaultProps = {
  afterSignUp: null,
  email: null,
  onUserExists: null,
  readOnlyEmail: false,
  showAllFields: true,
  style: null,
  variant: 'standard',
};

SignUpForm.propTypes = {
  afterSignUp: PropTypes.func,
  classes: PropTypes.object.isRequired,
  email: PropTypes.string,
  onUserExists: PropTypes.func,
  readOnlyEmail: PropTypes.bool,
  showAllFields: PropTypes.bool,
  style: PropTypes.object,
  t: PropTypes.func.isRequired,
  variant: PropTypes.string,
};

export default compose(
  withIntl,
  withStyles(signInStyles),
)(SignUpForm);
