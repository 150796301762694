import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from '@shakacode/recompose';
import { Button } from '@popmenu/admin-ui';
import { Typography } from '@popmenu/common-ui';

import { currentUserShape, withCurrentUser } from '../../shared/CurrentSessionProvider';
import { withStyles } from '../../utils/withStyles';
import genericStyles from '../../assets/jss/admin/genericStyles';
import restaurantMemberInviteQuery from '../../libs/gql/queries/member_invites/restaurantMemberInviteQuery.gql';
import acceptRestaurantMemberInviteMutation from '../../libs/gql/mutations/member_invites/acceptRestaurantMemberInviteMutation.gql';

import ForgotModal from '../../shared/ForgotModal/ForgotModal';
import Loading from '../../shared/Loading';
import Mutation from '../../shared/Mutation';
import Query from '../../shared/Query';
import SignInForm from '../../shared/sessions/SignInForm';
import SignOutButton from '../shared/SignOutButton';
import SignUpForm from './SignUpForm';

const AcceptInviteContainer = props => (
  <React.Fragment>
    <div>
      <Query
        query={restaurantMemberInviteQuery}
        variables={{
          token: props.match.params.token,
        }}
      >
        {({ data, loading }) => {
          if (loading) {
            return <Loading />;
          }
          if (!data || !data.restaurantMemberInvite) {
            return (
              <Typography align="center" gutterBottom>
                <FormattedMessage
                  id="restaurants.member_invites.expired"
                  defaultMessage="Sorry, the invite has expired or has already been accepted."
                />
              </Typography>
            );
          }
          return (
            <React.Fragment>
              <Mutation
                mutation={acceptRestaurantMemberInviteMutation}
                onCompleted={() => {
                  props.afterSignIn();
                  props.history.push('/');
                }}
              >
                {(acceptRestaurantMemberInvite, { loading: submitting }) => {
                  if (props.currentUser) {
                    return (
                      <Button
                        fullWidth
                        disabled={submitting}
                        variant="secondary"
                        size="large"
                        onClick={() => {
                          if (submitting) {
                            return;
                          }
                          acceptRestaurantMemberInvite({ variables: { token: props.match.params.token } });
                        }}
                      >
                        <FormattedMessage id="restaurants.member_invites.accept" defaultMessage="Accept Invite" />
                      </Button>
                    );
                  }
                  if (data.restaurantMemberInvite.emailExists) {
                    return (
                      <React.Fragment>
                        <Typography align="center" gutterBottom>
                          <strong>
                            <FormattedMessage id="restaurants.member_invites.have_account" defaultMessage={`You already have a Popmenu account for ${data.restaurantMemberInvite.email}`} />
                          </strong>
                          <br />
                          <br />
                          <FormattedMessage id="restaurants.member_invites.signin" defaultMessage='Please sign in to accept your invite or choose "Forgot Password?" to reset your account.' />
                          <br />
                          <br />
                        </Typography>
                        <SignInForm
                          afterSignIn={props.afterSignIn}
                          email={data.restaurantMemberInvite.email}
                          onlyForm
                          openForgotModal={props.openForgotModal}
                        />
                      </React.Fragment>
                    );
                  }
                  return (
                    <React.Fragment>
                      <Typography align="center" gutterBottom>
                        <strong>
                          <FormattedMessage id="restaurants.member_invites.account" defaultMessage="Create a Popmenu account with email and password, or choose to sign in via Facebook or Google." />
                        </strong>
                        <br />
                        <br />
                      </Typography>
                      <SignUpForm
                        afterSignUp={props.afterSignIn}
                        email={data.restaurantMemberInvite.email}
                        readOnlyEmail
                      />
                    </React.Fragment>
                  );
                }}
              </Mutation>
            </React.Fragment>
          );
        }}
      </Query>
      <div className={props.classes.panelLinks}>
        <SignOutButton isAdmin />
      </div>
    </div>
    <ForgotModal isAdmin />
  </React.Fragment>

);

AcceptInviteContainer.defaultProps = {
  currentUser: null,
};

AcceptInviteContainer.propTypes = {
  afterSignIn: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  currentUser: currentUserShape,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }).isRequired,
  openForgotModal: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  withCurrentUser,
  withStyles(genericStyles),
)(AcceptInviteContainer);
