export type ReplyModalState = {
  open: boolean;
  reviewId: number | undefined;
  reviewType: string | undefined | null;
  showSocial: boolean;
}

interface CloseEvent {
  type: 'closeReply';
}

interface ReplyToReviewEvent {
  type: 'replyToReview';
  reviewId: number;
  reviewType: string | undefined | null;
}

interface ShowSocial {
  type: 'showSocial';
}

interface hideSocial {
  type: 'hideSocial';
}

export type ReplyModalEvent =
  | CloseEvent
  | ReplyToReviewEvent
  | ShowSocial
  | hideSocial;

export const replyModalInitialState: ReplyModalState = {
  open: false,
  reviewId: undefined,
  reviewType: undefined,
  showSocial: false,
};

export const replyModalReducer = (
  prevState: ReplyModalState = replyModalInitialState,
  event: ReplyModalEvent,
): ReplyModalState => {
  switch (event.type) {
    case 'closeReply':
      return replyModalInitialState;
    case 'replyToReview':
      return { ...prevState, open: true, reviewId: event.reviewId, reviewType: event.reviewType };
    case 'showSocial':
      return { ...prevState, showSocial: true };
    case 'hideSocial':
      return { ...prevState, showSocial: false };
    default:
      return prevState;
  }
};
